/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Navbar from 'component/Navbar';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import FinalStep from './FinalStep';
import { API } from 'utils/drugSubstance';
import { useCreateNotCompleteSubstance } from 'utils/hooks/substance.hook';
import { useGetPlants } from 'utils/hooks/plant.hook';
import UnsavedChangeDialog from 'component/UnsavedChangeDialog';

function NewSubstance() {
    const theme = useTheme();
    const [redirectionPath, setRedirectionPath] = useState<string | null>(null);
    const [valuesChanged, setValuesChanged] = useState(false);
    const [step, setStep] = useState(1);
    const { plants } = useGetPlants();
    const { mutate: createNotComplete } = useCreateNotCompleteSubstance();
    const methods = useForm<API>({
        mode: 'onChange',
    });
    const { watch } = methods;

    const handleStep = (type: "decrement" | "increment") => {
        setStep(step => type === "decrement" ? step - 1 : step + 1);
    }

    const handleSave = () => { 
        createNotComplete(watch());
    }
    
    return (
        <>
            <UnsavedChangeDialog
                redirectionPath={redirectionPath}
                setRedirectionPath={setRedirectionPath}
                valuesChanged={valuesChanged}
                setValuesChanged={setValuesChanged}
                saveAction={handleSave}
                watch={watch}
            />
            <div
                css={{
                    flexGrow: 1,
                    backgroundColor: theme.white,
                    color: theme.black,
                    display: 'flex',
                }}
            >
                <Navbar customRedirection={setRedirectionPath} />
                <FormProvider {...methods}>
                    <form
                        css={{
                            flexGrow: 1,
                            overflowY: 'auto'
                        }}
                    >
                    {step <= 1 && <Step1 handleSaveBeforeLeave={setRedirectionPath} handleStep={handleStep} />}
                    {step === 2 && <Step2 handleStep={handleStep} />}
                    {step === 3 && <Step3 setValuesChanged={setValuesChanged} plants={plants} handleStep={handleStep} />}
                    {step === 4 && <FinalStep setValuesChanged={setValuesChanged} />}
                    </form>
                </FormProvider>
            </div>
        </>
    );
}

export default NewSubstance;