/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import CurrentStepVisualiser from '../CurrentStepVisualiser';
import Header from 'component/StepHeader'
import Button from 'component/Button';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import Select from 'component/Select';
import PlantForm from 'pages/plants/PlantForm';
import { Plant, setPlant } from 'utils/plant';
import { createYearReportOption } from 'utils/enums';

function StepPlant({ handlePrevious, handleNext, handleSave, currentIndex, currentSubstance, substances, plants }: {
    handlePrevious: () => void;
    handleNext: () => void;
    handleSave: (data: Plant, plantId: number) => void;
    currentIndex: number;
    currentSubstance: any;
    substances: any;
    plants: any;
}) {
    const yearReportOption = createYearReportOption();
    const theme = useTheme();
    const [plantsOptions, setPlantsOptions] = useState<any>([]);
    const [plantId, setPlantId] = useState<number | null>(null);
    const methods = useForm<Plant>({
        mode: 'onChange',
        defaultValues: {
            companyName: '',
            reportingYear: null,
            countryId: null,
            city: '',
            capexUnit: 'Million €',
            capexValue: '',
            coolingWaterValue: '',
            coolingWaterUnit: 'm3',
            comments: '',
            employees: '',
            energyConsumptionCoalUnit: 'MWh',
            energyConsumptionCoalValue: '',
            energyConsumptionElectricityUnit: 'MWh',
            energyConsumptionElectricityValue: '',
            energyConsumptionFuelOilUnit: 'Litres',
            energyConsumptionFuelOilValue: '',
            energyConsumptionGasolineUnit: 'Litres',
            energyConsumptionGasolineValue: '',
            energyConsumptionHeatUnit: 'MJ',
            energyConsumptionHeatValue: '',
            energyConsumptionLiquidNitrogenUnit: 'Tons',
            energyConsumptionLiquidNitrogenValue: '',
            energyConsumptionNaturalGasUnit: 'MWh',
            energyConsumptionNaturalGasValue: '',
            energyConsumptionSteamUnit: 'Tons',
            energyConsumptionSteamValue: '',
            energyProductionUnit: 'MWh',
            energyProductionValue: '',
            productionFPValue: '',
            productionIntFPValue: '',
            scope1Unit: 'TonsCO2eq',
            scope1Value: '',
            scope2Unit: 'TonsCO2eq',
            scope2Value: '',
            refrigerantGases: [{ value: '', type: null }],
            volatileOrganicCompounds: [{ name: '', quantity: '' }],
        }
    });

    const { trigger, watch, setValue, reset, control } = methods;
    
    const handleSelectOption2Plant = (id: number | null) => {
        setPlantId(id);
        if (id) {
            const plantData = _.find(plants, { 'id': id });
            if (plantData) setPlant(plantData, setValue);
        } else {
            reset();
        }
    }

    const handleSubmit = async () => {
        const isStepValid = await trigger();
        if (isStepValid) {
            if (plantId) {
                handleSave(watch(), plantId);
                handleNext();
            }
        }
    }

    const handlePreviousAction = () => {
        if (plantId) {
            handleSave(watch(), plantId);
        }
        handlePrevious();
    }

    useEffect(() => {
        const parentSubstance = _.find(substances, (o) => o.productBy.id === currentSubstance.usedInReactionId);
        if (!parentSubstance)
            return console.error('Unknown substance');
        const plantsUsedInSubstanceIds = parentSubstance.productBy.carbonReactionProductionSites.map((el: any) => el.plantId);
        const plantsFiltered = _.filter(plants, (plant) => _.includes(plantsUsedInSubstanceIds, plant.id));
        const options = plantsFiltered.map((plant: any) => ({ value: plant.id, label: `${plant.companyName} - ${plant.country.name} - ${plant.city}` }))
        setPlantsOptions(options);
        if (parentSubstance.linkedWithPlant) {
            setPlantId(parentSubstance.linkedWithPlant);
            const plantData = _.find(plants, { 'id': parentSubstance.linkedWithPlant });
            if (plantData) setPlant(plantData, setValue);
        }
    }, [currentSubstance, plants, substances, setValue]);

    return (
        <div
            css={{
                backgroundColor: theme.white,
                padding: '50px 56px',
                color: theme.black,
                display: 'grid',
                gridTemplateColumns: 'auto 268px',
                columnGap: '30px',
            }}
        >
            <div>
                <Header
                    title="Carbon footprint"
                    stepName="Step 5"
                    stepTitle="Manufacturing plant information"
                    backName="Previous step"
                    backAction={() => handlePreviousAction()}
                />
                <p css={{
                        marginTop: '32px',
                        marginBottom: '24px',
                        color: theme.black,
                        fontSize: '16px',
                        lineHeight: '18px',
                        fontWeight: 500,
                    }}
                >{`Synthetic route: reaction ${currentIndex + 1}/${substances.length - 1}`}</p>
                <FormProvider {...methods}>
                <form>
                    <div css={{ display: 'flex', marginBottom: '16px' }}>
                        <Select
                            onChange={(data: any) => handleSelectOption2Plant(data ? data.value : null)}
                            options={plantsOptions}
                            label="Select a manufacturing plant from the synthetic route tree"
                            placeholder="Select a plant"
                            getOptionLabel={(option: any) => option.label}
                            value={plantsOptions.find((el: any) => el.value === plantId)}
                            isClearable={true}
                            css={{
                                maxWidth: '450px',
                            }}
                        />
                    </div> 
                    
                    <div css={{ display: 'flex', marginBottom: '16px' }}>
                        <Controller
                            control={control}
                            name="reportingYear"
                            defaultValue={null}
                            rules={{
                                required: `Year of report can't be empty`
                            }}
                            render={({
                                field: { onChange, value, ref },
                                fieldState: { error },
                            }) => (
                                <Select
                                    onChange={(data: any) => onChange(data ? data.value : null)}
                                    options={yearReportOption}
                                    inputRef={ref}
                                    label="Year of reporting*"
                                    isDisabled={!plantId}
                                    getOptionLabel={(option: any) => option.label}
                                    value={value && yearReportOption.find((el: any) => el.value === value)}
                                    error={error?.message}
                                    css={{
                                        maxWidth: '450px',
                                    }}
                                />
                            )}
                        />
                    </div>
                    <PlantForm isDisabled={!plantId} />
                </form>
                </FormProvider>
            </div>
            <div>
                <CurrentStepVisualiser step={8} />
                <Button
                    variant="dark"
                    disabled={!plantId}
                    onClick={() => handleSubmit()}
                    css={{
                        fontSize: '14px',
                        marginBottom: '16px',
                    }}
                >{`Validate data for reaction ${currentIndex + 1}/${substances.length - 1}`}</Button>
                <Button
                    variant="light"
                    onClick={() => handleNext()}
                    css={{
                        fontSize: '14px',
                    }}
                >{`Unknown data for reaction ${currentIndex + 1}/${substances.length - 1}`}</Button>
            </div>
        </div>
    );
}

export default StepPlant;