/** @jsxImportSource @emotion/react */
import _ from 'lodash';
import { useState, useEffect } from 'react';
import ReactD3Tree from 'react-d3-tree';
import NodeTree from './NodeTree';
import { findPath } from 'utils/tree';
import uniqid from 'uniqid';
import { TreeType } from 'utils/drugSubstance';

export type DialogState = {
    type: 'close' | 'update' | 'create' | 'updateRoot';
    nodePath: string | null;
    nodeData: any;
}

const checkPlantExist = (plants: any, plantData: any) => {
    const plantExists = _.find(plants, {
        'companyName': plantData.companyName,
        'city': plantData.city,
        'countryId': plantData.countryId
    });
    return plantExists ?? null;
}

const createPlantsAndManufacturers = (manufacturers: any, plants: any) => {
    const manufacturersArray: any = [];
    const currentPlants = [...plants];

    manufacturers.forEach((manufacturer: any) => {
        const { percentage, ...plantData } = manufacturer;
        const plant = checkPlantExist(currentPlants, plantData);
        if (plant) {
            manufacturersArray.push({ percentage, plantId: plant.id });
        }
        else {
            const id = uniqid('plant_');
            const newPlant = { ...plantData, id };
            currentPlants.push(newPlant);
            manufacturersArray.push({ percentage, plantId: id });
        }
    });

    return [manufacturersArray, currentPlants];
}

function Tree({ apiTree, plants, updateTree, FormDialog, type }: {
    apiTree: any;
    plants: any;
    updateTree: (tree: any) => void;
    FormDialog: any;
    type: TreeType;
}) {
    const [dialogState, setDialogState] = useState<DialogState>({
        type: 'close',
        nodePath: null,
        nodeData: null,
    });
    const [tree, setTree] = useState<any>(null);
    const nodeSize = { x: (type === 'BIOLOGIC' || type === 'NATURAL') ? 450 : 300, y: 150 };
    const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: (type === 'BIOLOGIC' || type === 'NATURAL') ? -225 : -150, y: -10 };

    const openDialog = (nodeData: any, type: "update" | "create" | "updateRoot") => {
        if (type === 'update') {
            const pathToNode = findPath(tree, nodeData.id);
            setDialogState({
                type: 'update',
                nodePath: pathToNode,
                nodeData,
            });
        } else if (type === 'create') {
            const pathToNode = findPath(tree, nodeData.id);
            setDialogState({
                type: 'create',
                nodePath: pathToNode,
                nodeData: null,
            });
        } else if (type === 'updateRoot') {
            setDialogState({
                type: 'updateRoot',
                nodePath: "",
                nodeData,
            });
        }
    }

    const closeDialog = () => {
        setDialogState({
            type: 'close',
            nodePath: null,
            nodeData: null,
        });
    }

    const updateRootNode = (data: any) => {
        const [manufacturers, plants] = createPlantsAndManufacturers(data.manufacturers, [...tree.plants]);
        setTree({ ...tree, manufacturers, plants });
        updateTree({ ...tree, manufacturers, plants });
        closeDialog();
    }

    const updateNode = (data: any, nodePath: string) => {
        const [manufacturers, plants] = createPlantsAndManufacturers(data.manufacturers, [...tree.plants]);
        const currentNode = _.get(tree, nodePath);
        const updatedNode = { ...currentNode, ...data, manufacturers };
        const updatedTree = _.set(tree, `${nodePath}`, updatedNode);
        setTree({ ...updatedTree, plants });
        updateTree({ ...updatedTree, plants });
        closeDialog();
    }

    const createNode = (data: any, parentPath: string) => {
        const [manufacturers, plants] = createPlantsAndManufacturers(data.manufacturers, [...tree.plants]);
        if (parentPath === '') {
            const rootChildren = [...tree.children, { ...data, id: uniqid('substance_'), manufacturers, children: [] }];
            setTree({ ...tree, plants, children: rootChildren });
            updateTree({ ...tree, plants, children: rootChildren });
        } else {
            const currentNode = _.get(tree, parentPath);
            const newChildren = [...currentNode.children, { ...data, id: uniqid('substance_'), manufacturers, children: [] }];
            const updatedTree = _.set(tree, `${parentPath}.children`, newChildren);
            setTree({ ...updatedTree, plants });
            updateTree({ ...updatedTree, plants });
        }
        closeDialog();
    }

    const deleteNode = (nodeId: string, nodePath: string) => {
        if (!nodeId || nodePath === "") return;
        const splitedNodePath = nodePath.split('.');
        splitedNodePath.splice(splitedNodePath.length - 2, 2);
        const parentNodePath = splitedNodePath.join('.');
        if (parentNodePath === "") {
            const rootChildren = _.filter(tree.children, function(o) { return (o.id !== nodeId) });
            setTree({ ...tree, children: rootChildren });
            updateTree({ ...tree, children: rootChildren });
        } else {
            const parentNode = _.get(tree, parentNodePath);
            const parentNodeChildren = _.filter(parentNode.children, function(o) { return (o.id !== nodeId) });
            const updatedTree = _.set(tree, `${parentNodePath}.children`, parentNodeChildren);
            setTree({ ...updatedTree });
            updateTree({ ...updatedTree });
        }
        closeDialog();
    }

    useEffect(() => {
        setTree({ ...apiTree});
        // open dialog if the rootNode does not have manufacturer(s)
        if (apiTree.manufacturers.length === 0) {
            setDialogState({
                type: 'updateRoot',
                nodePath: "",
                nodeData: apiTree
            });
        }
    }, [apiTree]);
    
    useEffect(() => {
        setTree((tree: any) => ({ ...tree, plants: [...plants, ...tree.plants] }));
    }, [plants, apiTree]);

    if (!tree) return null;

    return (
        <>
            {dialogState.type !== 'close' &&
                <FormDialog  
                    type={type}
                    dialogState={dialogState}
                    plants={_.uniqBy(tree.plants, 'id')}
                    closeDialog={closeDialog}
                    createNode={createNode}
                    deleteNode={deleteNode}
                    updateNode={updateNode}
                    updateRootNode={updateRootNode}
                />
            }
            <ReactD3Tree
                data={tree}
                translate={{ x: 375, y: 100 }}
                nodeSize={nodeSize}
                pathClassFunc={() => 'nodes-link'}
                pathFunc={'step'}
                orientation="vertical"
                renderCustomNodeElement={(rd3tProps) => <NodeTree
                    { ...rd3tProps}
                    foreignObjectProps={foreignObjectProps}
                    openDialog={openDialog}
                    type={type}
                />}
            />
        </>
    );
}

export default Tree;