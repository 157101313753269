/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { useGetSubstancesCompleted } from 'utils/hooks/substance.hook';
import { useGetOriginsCompleted } from 'utils/hooks/origin.hook';
import { useGetCarbonsCompleted } from 'utils/hooks/carbon.hook';

function StatusPaidButton({
    paid = false
}: {
    paid: boolean
}) {
    const theme = useTheme();

    return (
        <div
            css={{ 
                position: 'relative',
            }}
        >
            <span
                css={{
                    padding: '8px 16px',
                    borderRadius: '8px',
                    textAlign: 'center',
                    backgroundColor: paid ? theme.secondary : "#FF881B",
                    color: theme.white,
                    fontWeight: 500,
                    fontSize: '12px',
                    lineHeight: '15px',
                }}
            >
                {paid ? "Paid" : "Not Paid"}
            </span>
        </div>
    )
}

function Invoices() {
    const theme = useTheme();
    const { origins } = useGetOriginsCompleted();
    const { substances } = useGetSubstancesCompleted();
    const { carbons } = useGetCarbonsCompleted();
    const tableEmpty = origins.length + substances.length + carbons.length;

    return (
        <div
            css={{
                padding: '24px 40px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <h3 css={{
                fontWeight: 600,
                fontSize: '24px',
                lineHeight: '29px',
                color: theme.black,
                marginTop: '0px',
                marginBottom: '48px',
            }}>Invoices</h3>

            {tableEmpty === 0 ? 
                <p css={{
                    fontSize: '18px',
                    lineHeight: '29px',
                    color: theme.black,
                    marginTop: '0px',
                    marginBottom: '48px',   
                }}>You don't have completed projects</p>
            :
            <table
                css={{
                    textAlign: 'center',
                    borderCollapse: 'collapse',
                    'tr, th, td': {
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '17px',
                    },
                    'tr th, tr td': {
                        border: `1px solid ${theme.primary}`,
                        height: '50px',
                        color: theme.black,
                    },
                    'tr th': {
                        backgroundColor: theme.primary,
                        color: theme.white,
                    },
                    'tr td a': {
                        color: theme.black,
                        fontWeight: 600,
                        textDecoration: 'underline',
                    },
                    '.name': {
                        minWidth: '150px',
                    },
                    '.type': {
                        minWidth: '150px',
                    },
                    '.status, .document, .access': {
                        width: '120px',
                    },
                }}
            >
                <thead>
                    <tr>
                        <th className="name">Name of product</th>
                        <th className="type">Type</th>
                        <th className="status">Status</th>
                        <th className="document">Document</th>
                    </tr>
                </thead>
                <tbody>
                    {origins.map((origin: any) => (
                        <tr key={`origin_${origin.id}`}>
                            <td>{origin.drugProductName}</td>
                            <td>Labeling drug product</td>
                            <td><StatusPaidButton paid={origin.invoice.status === 'PAID'} /></td>
                            <td>
                                {origin.invoiceUrl ?
                                    <a href={origin.invoiceUrl} rel="noreferrer" target="_blank">invoice</a> :
                                '-'}
                            </td>
                        </tr>
                    ))}
                   {substances.map((substance: any) => (
                        <tr key={`substance_${substance.id}`}>
                            <td>{substance.inn}</td>
                            <td>Labeling drug substance</td>
                            <td><StatusPaidButton paid={substance.invoice.status === 'PAID'} /></td>
                            <td>
                                {substance.invoiceUrl ?
                                    <a href={substance.invoiceUrl} rel="noreferrer" target="_blank">invoice</a> :
                                '-'}
                            </td>
                        </tr>
                    ))}
                    {carbons.map((carbon: any) => (
                        <tr key={`carbon_${carbon.id}`}>
                            <td>{carbon.name}</td>
                            <td>Carbon Footprint</td>
                            <td><StatusPaidButton paid={carbon.invoice.status === 'PAID'} /></td>
                            <td>
                                {carbon.invoiceUrl ?
                                    <a href={carbon.invoiceUrl} rel="noreferrer" target="_blank">invoice</a> :
                                '-'}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>}
        </div>
    );
}

export default Invoices;