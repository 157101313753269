/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import { ReactComponent as NotCheckedIcon } from 'assets/svg/NotChecked.svg';
import { ReactComponent as CheckedIcon } from 'assets/svg/Checked.svg';

function CurrentStepVisualiser({ step }: {
    step: number;
}) {
    const theme = useTheme();

    return (
        <div
            css={{
                width: '100%',
                height: '395px',
                marginTop: '220px',
                marginBottom: '32px',
                boxShadow: '0px 0px 10px 0px #040C1E14',
                borderRadius: '2px',
                padding: '42px 0px 40px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <div 
                css={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    color: theme.black,
                    marginBottom: '50px',
                    '.step__number': {
                        fontSize: '10px',
                        lineHeight: '12px',
                        textAlign: 'center',
                        marginTop: '14px',
                        marginBottom: '8px',
                    },
                    '.step__name': {
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '17px',
                        marginTop: '0px',
                        marginBottom: '0px',
                    },
                    '.step__checked': {
                        display: step > 1 ? 'block': 'none'
                    },
                    '.step__unchecked': {
                        display: step === 1 ? 'block': 'none'
                    }
                }}
            >
                <CheckedIcon className="step__checked" />
                <NotCheckedIcon className="step__unchecked" />
                <p className="step__number">Step 1</p>
                <p className="step__name">Identify your drug product</p>
            </div>

            <div 
                css={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    color: theme.black,
                    marginBottom: '50px',
                    '.step__number': {
                        fontSize: '10px',
                        lineHeight: '12px',
                        textAlign: 'center',
                        marginTop: '14px',
                        marginBottom: '8px',
                    },
                    '.step__name': {
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '17px',
                        marginTop: '0px',
                        marginBottom: '0px',
                    },
                    '.step__checked': {
                        display: step > 2 ? 'block': 'none'
                    },
                    '.step__unchecked': {
                        display: step <= 2 ? 'block': 'none',
                        'path': {
                            fill: step === 2 ? theme.black : theme.grey,
                        }
                    }
                }}
            >
                <CheckedIcon className="step__checked" />
                <NotCheckedIcon className="step__unchecked" />
                <p className="step__number">Step 2</p>
                <p className="step__name">Identify your drug substance</p>
            </div>

            <div 
                css={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    color: theme.black,
                    '.step__number': {
                        fontSize: '10px',
                        lineHeight: '12px',
                        textAlign: 'center',
                        marginTop: '14px',
                        marginBottom: '8px',
                    },
                    '.step__name': {
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '17px',
                        marginTop: '0px',
                        marginBottom: '0px',
                    },
                    '.step__unchecked': {
                        display: 'block',
                        'path': {
                            fill: step > 3 ? theme.black : theme.grey,
                        }
                    }
                }}
            >
                <NotCheckedIcon className="step__unchecked" />
                <p className="step__number">Step 3</p>
                <p className="step__name">Build your synthetic route</p>
            </div>
        </div>
    );
}

export default CurrentStepVisualiser;