/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import Input from "component/Input";
import { useForm } from "react-hook-form";
import Button from "component/Button";
import { ReactComponent as ArrowBackIcon } from "assets/svg/ArrowBack.svg";
import { emailRegex } from "utils/regex";
import { useCreateSubUser } from "utils/hooks/auth.hook";
import { useEffect, useState } from "react";
import Navbar from "component/Navbar";
import { useHistory } from "react-router-dom";
import { useCheckIsSubUser } from "utils/hooks/user.hook";

export type SetupNewUserFormData = {
  name: string;
  function: string;
  email: string;
};

function SetupNewUser() {
  const theme = useTheme();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const { isSubUser, isSuccess: isSuccessCheckSubUser } = useCheckIsSubUser();
  const {
    mutate: createSubUser,
    isLoading,
    isSuccess,
  } = useCreateSubUser(setErrorMessage);

  useEffect(() => {
    if (isSuccessCheckSubUser && isSubUser) history.push("/");
  }, [isSuccessCheckSubUser, isSubUser, history]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SetupNewUserFormData>({
    mode: "onChange",
  });

  const handleRedirection = () => {
    history.push("/my_account");
  };

  const onSubmit = (data: SetupNewUserFormData) => {
    createSubUser(data);
  };

  useEffect(() => {
    if (isSuccess) reset();
  }, [isSuccess, reset]);

  return (
    <div
      css={{
        width: "100%",
        minHeight: "100vh",
        backgroundColor: theme.white,
        color: theme.black,
        display: "flex",
      }}
    >
      <Navbar />
      <div
        css={{
          flexGrow: 1,
          padding: "50px 56px",
        }}
      >
        <button
          type="button"
          css={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            color: theme.black,
            fontSize: "14px",
            lineHeight: "17px",
            border: "none",
            background: "transparent",
            padding: "0px",
            svg: {
              marginRight: "14px",
            },
          }}
          onClick={handleRedirection}
        >
          <ArrowBackIcon />
          Back to My account
        </button>

        <form
          onSubmit={handleSubmit(onSubmit)}
          css={{ maxWidth: "700px", marginTop: "30px" }}
        >
          <p
            css={{
              marginBottom: "30px",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "20px",
            }}
          >
            If you wish to add a new user to this account, please fill the form
            herebelow and send the invitation. The new user will receive an
            e-mail to create a password and, as soon as his/her personnal
            account will be validated, he/she will have access to all projects
            of this account, and will be able to add data to existing projects
            (or change data already entered) and create new projects.
          </p>

          <Input
            label="Name*"
            placeholder="Name SURNAME"
            name="name"
            register={register}
            registerOption={{
              required: `Name can't be empty`,
            }}
            error={errors.name && errors.name.message}
            css={{
              marginBottom: "16px",
            }}
          />
          <Input
            label="Function*"
            placeholder={`R&D Scientist`}
            name="function"
            register={register}
            registerOption={{
              required: `Function can't be empty`,
            }}
            error={errors.function && errors.function.message}
            css={{
              marginBottom: "16px",
            }}
          />
          <Input
            label="Email*"
            placeholder="Email"
            name="email"
            register={register}
            registerOption={{
              required: `Email can't be empty`,
              pattern: {
                value: emailRegex,
                message: "Incorrect email",
              },
            }}
            error={errors.email && errors.email.message}
            css={{
              marginBottom: "16px",
            }}
          />

          {errorMessage && (
            <p
              css={{
                color: theme.error,
                fontSize: "14px",
                marginBottom: "-30px",
              }}
            >
              {errorMessage}
            </p>
          )}

          {isSuccess && (
            <p
              css={{
                color: theme.success,
                fontWeight: 500,
                fontSize: "18px",
                marginBottom: "-30px",
              }}
            >
              User created successfully
            </p>
          )}

          <Button
            type="submit"
            variant="dark"
            disabled={isLoading}
            css={{
              marginTop: "48px",
              marginBottom: "12px",
            }}
          >
            Send invitation
          </Button>
        </form>
      </div>
    </div>
  );
}

export default SetupNewUser;
