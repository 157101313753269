/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import { ReactComponent as ArrowBackIcon } from 'assets/svg/ArrowBack.svg';

function StepHeader({ title, stepName, stepTitle, backAction, backName = "Back Home" }: {
    title?: string;
    stepName?: string;
    stepTitle?: string;
    backAction: any;
    backName: string
}) {
    const theme = useTheme();

    return (
        <div id="header">
            <button
                type="button"
                css={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    color: theme.black,
                    fontSize: '14px',
                    lineHeight: '17px',
                    border: 'none',
                    background: 'transparent',
                    padding: '0px',
                    'svg': {
                        marginRight: '14px'
                    }
                }}
                onClick={backAction}
            >
                <ArrowBackIcon />
                {backName}
            </button>

            <h2 
                css={{
                    fontSize: '24px', 
                    lineHeight: '28px', 
                    fontWeight: 600, 
                    marginTop: '26px', 
                    marginBottom: '24px'
                }}
            >
                {title}
            </h2>

            {stepName &&
                <p
                    css={{
                        color: theme.black, 
                        fontSize: '14px',
                        lineHeight: '18px',
                        marginTop: '0px',
                        marginBottom: '8px'
                    }}
                >{stepName}
                </p>
            }

            {stepTitle &&
                <h4
                    css={{
                        color: theme.black,
                        fontSize: '18px',
                        lineHeight: '18px',
                        fontWeight: 600,
                        marginTop: '0px',
                        marginBottom: '8px'
                    }}
                >{stepTitle}</h4>
            }
        </div>
    );
}

export default StepHeader;