/** @jsxImportSource @emotion/react */
import { Fragment, useEffect } from 'react';
import { useTheme } from '@emotion/react';
import Input from 'component/Input';
import PercentageInput from 'component/PercentageInput';
import Select from 'component/Select';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Country, useCountries } from 'utils/hooks/countries.hook';
import { ReactComponent as AddIcon } from 'assets/svg/Add.svg';
import { ReactComponent as TrashIcon } from 'assets/svg/Trash.svg';
import CloseIcon from '@material-ui/icons/Close';
import { DialogState } from './';
import { get } from 'lodash';
import _ from 'lodash';
import { useBiologicTransformationType, useNaturalTransformationType } from 'utils/hooks/transformationType.hook';

type Manufacturer = {
    companyName: string;
    city: string;
    percentage: string | null;
    countryId: number | null;
}

type DialogFormData = {
    transformationType: number | null;
    otherStep: string;
    manufacturers: Manufacturer[];
}

type SubstanceDialogProps = {
    type: string;
    dialogState: DialogState;
    plants: any;
    closeDialog: () => void;
    createNode: (data: any, parentPath: string) => void;
    deleteNode: (nodeId: string, nodePath: string) => void;
    updateNode: (data: any, parentPath: string) => void;
    updateRootNode: (data: any) => void;
}

function BiologicSubstanceDialog({
    type,
    dialogState,
    plants,
    closeDialog,
    createNode,
    deleteNode,
    updateNode,
    updateRootNode,
}: SubstanceDialogProps) {
    const theme = useTheme();
    const { countries } = useCountries();
    const { naturalTypes } = useNaturalTransformationType();
    const { biologicTypes } = useBiologicTransformationType();
    const { register, control, trigger, setValue, getValues, watch, setError, formState: { errors } } = useForm<DialogFormData>({
        mode: 'onChange',
        defaultValues: {
            transformationType: dialogState?.nodeData?.transformationType || '',
            otherStep: dialogState?.nodeData?.otherStep || '',
            manufacturers: dialogState?.nodeData?.manufacturers.length > 0 ? dialogState?.nodeData?.manufacturers :
            [{
                companyName: '',
                city: '',
                percentage: null,
                countryId: null,
            }]
        },
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "manufacturers",
    });

    const appendField = () => {
        if (fields.length < 3) append({
            companyName: '',
            city: '',
            percentage: null,
            countryId: null,
        });
    }

    const removeField = (index: number) => {
        if (fields.length > 1) remove(index);
    }

    const handleSubmit = async () => {
        const isStepValid = await trigger();
        if (isStepValid) {
            const manufacturers = getValues('manufacturers');
            const percentageTotal = _.reduce(manufacturers, (sum: number, n: Manufacturer) => {
                const parsedPercentage = n.percentage ? parseInt(n.percentage) : NaN;
                if (isNaN(parsedPercentage)) return 0;
                else return sum + parsedPercentage;
            }, 0);
            if (percentageTotal !== 100) {
                setError('manufacturers.0.countryId', { type: 'manual', message: 'The total percentage has to be equal to 100' })
            } else {
                if (dialogState.type === 'updateRoot')
                    updateRootNode(watch());
                else if (dialogState.type === 'create')
                    createNode(watch(), dialogState.nodePath ? dialogState.nodePath.substring(1) : '');
                else if (dialogState.type === 'update')
                    updateNode(watch(), dialogState.nodePath ? dialogState.nodePath.substring(1) : '');
                else
                    closeDialog();
            }
        }
    };

    const handleDeleteNode = () => {
        deleteNode(dialogState.nodeData.id, dialogState.nodePath ? dialogState.nodePath.substring(1) : '');
    }

    const handlePlantSelect = (plantId: null | string, index: number) => {
        if (plantId) {
            const plant = _.find(plants, { 'id': plantId });
            setValue(`manufacturers.${index}.countryId`, plant.countryId);
            setValue(`manufacturers.${index}.companyName`, plant.companyName);
            setValue(`manufacturers.${index}.city`, plant.city);
        } else {
            setValue(`manufacturers.${index}.countryId`, null);
            setValue(`manufacturers.${index}.companyName`, '');
            setValue(`manufacturers.${index}.city`, '');
        }
    }

    useEffect(() => {
        if (dialogState.nodeData) {
            for (let i = 0; i < dialogState.nodeData.manufacturers.length; i++) {
                const { percentage, plantId } = dialogState.nodeData.manufacturers[i];
                const plant = _.find(plants, { 'id': plantId });
                if (plant) setValue(`manufacturers.${i}`, { ...plant, percentage });
            }
        }
    }, [dialogState, plants, setValue]);

    const plantsOption = plants.map((plant: any) => {
        if (countries) {
            const { name: countryName } = _.find(countries, { 'id': plant.countryId });
            return ({ name: `${plant.companyName} - ${countryName} - ${plant.city}`, id: plant.id });
        }
        return null;
    });

    return (
        <div
            id="dialog_overlay"
            css={{
                display: 'flex',
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                left: 360,
                backgroundColor: 'rgba(4, 12, 30, 0.2)',
                zIndex: 4,
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div
                id="dialog_form"
                css={{
                    backgroundColor: theme.white,
                    width: '400px',
                    display: 'flex',
                    flexDirection: 'column',
                    border: `2px solid ${theme.primary}`,
                    maxHeight: '90%',
                    overflowY: 'auto',
                }}
            >
                <div
                    css={{
                        width: '100%',
                        padding: '20px 20px 26px 20px',
                    }}
                >
                    {(dialogState.type !== 'updateRoot') &&
                    <>
                    <div
                        css={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            marginBottom: '12px',
                            'button': {
                                cursor: 'pointer',
                                border: 'none',
                                background: 'transparent',
                            }
                        }}
                    >
                        <p css={{
                            color: theme.black,
                            fontWeight: 600,
                            fontSize: '14px',
                            lineHeight: '17px',
                            marginTop: '0px',
                            marginBottom: '0px',
                        }}>Add a manufacturing step</p>

                        {dialogState.type === 'update' ?
                            <button onClick={handleDeleteNode}>
                                <TrashIcon />
                            </button> :
                            <button onClick={closeDialog}>
                                <CloseIcon />
                            </button>
                        }

                    </div>

                    <div css={{ display: 'flex', marginBottom: '16px' }}>
                        <Controller
                            control={control}
                            name="transformationType"
                            rules={{ required: `Transformation type can't be empty` }}
                            render={({
                                field: { onChange, value, ref },
                                fieldState: { error },
                            }) => (
                                <Select
                                    onChange={(data: any) => {
                                        // remove otherStep on change transformationType
                                        setValue('otherStep', '');
                                        onChange(data.id);
                                    }}
                                    options={type === 'NATURAL' ? naturalTypes : biologicTypes}
                                    inputRef={ref}
                                    label="Type of step*"
                                    placeholder="Select a type"
                                    getOptionLabel={(option: any) => option.name}
                                    value={type === 'NATURAL' ? naturalTypes.find((el: any) => el.id === value) : biologicTypes.find((el: any) => el.id === value)}
                                    error={error?.message}
                                    css={{
                                        maxWidth: '400px',
                                    }}
                                />
                            )}
                        />
                    </div>
                    {(watch('transformationType') === -1) &&
                    <div css={{ display: 'flex', marginBottom: '12px' }}>
                        <Input
                            label="Other step*"
                            placeholder="..."
                            register={register}
                            registerOption={{ required: `Other step can't be empty` }}
                            name="otherStep"
                            error={errors.otherStep && errors.otherStep.message}
                            css={{
                                maxWidth: '400px',
                            }}
                        />
                    </div>
                    }
                    </>
                    }

                    {fields.map((field, index) => (
                        <Fragment key={index}>
                        <div css={{
                            display: 'flex',
                            alignItems: 'baseline',
                            justifyContent: 'space-between',
                            'button': {
                                cursor: 'pointer',
                                border: 'none',
                                background: 'transparent',
                                'svg': {
                                    width: '18px',
                                    height: '18px',
                                }
                            },  
                        }}>
                            <p css={{
                                color: theme.black,
                                fontWeight: 600,
                                fontSize: '14px',
                                lineHeight: '17px',
                                marginTop: '0px',
                                marginBottom: '12px',
                            }}>Manufacturer #{index + 1}</p>

                            {index !== 0 &&
                                <button onClick={() => removeField(index)}>
                                    <TrashIcon />
                                </button>
                            }
                        </div>

                        {plantsOption && plantsOption.length > 0 &&
                            <div css={{ display: 'flex', marginBottom: '16px' }}>
                                <Select
                                    onChange={(data: any) => handlePlantSelect(data ? data.id : null, index)}
                                    options={plantsOption}
                                    label="Select an existing manufacturing plant"
                                    placeholder="Select a plant"
                                    getOptionLabel={(option: any) => option.name}
                                    isClearable={true}
                                    css={{
                                        maxWidth: '400px',
                                    }}
                                />
                            </div>
                        }

                        <div css={{ display: 'flex', marginBottom: '12px' }}>
                            <Input
                                label="Company name*"
                                placeholder="Company name"
                                defaultValue={field.companyName}
                                register={register}
                                registerOption={{ required: `Company name can't be empty` }}
                                name={`manufacturers.${index}.companyName`}
                                error={get(errors, `manufacturers.${index}.companyName`)}
                                css={{
                                    maxWidth: '400px',
                                }}
                            />
                        </div>
                        <div key={field.id} css={{ display: 'flex', marginBottom: '16px' }}>
                            <Controller
                                control={control}
                                name={`manufacturers.${index}.countryId`}
                                defaultValue={field.countryId}
                                rules={{ required: `Production country can't be empty` }}
                                render={({
                                    field: { onChange, value, ref },
                                    fieldState: { error },
                                }) => (
                                    <Select
                                        onChange={(data: any) => onChange(data.id)}
                                        options={countries}
                                        inputRef={ref}
                                        label="Production site*"
                                        placeholder="Select a country"
                                        getOptionLabel={(option: any) => option.name}
                                        value={countries && countries.find((el: Country) => el.id === value)}
                                        error={error?.message || get(errors, `manufacturers.${index}.percentage`)}
                                        css={{
                                            maxWidth: '304px',
                                        }}
                                    />
                                )}
                            />
                            <PercentageInput
                                css={{
                                    marginTop: '25px',
                                    marginLeft: '16px',
                                    marginRight: '16px',
                                }}
                                name={`manufacturers.${index}.percentage`}
                                placeholder="...."
                                register={register}
                                registerOption={{
                                    required: `Percentage can't be empty`,
                                    validate: {
                                        isNumber: (value: string) => !isNaN(parseInt(value)) || `Percentage must be a number`,
                                        isBiggerThan100: (value: string) => (parseInt(value) <= 100) || `Percentage can't be above 100`,
                                        isLowerThan100: (value: string) => (parseInt(value) > 0) || `Percentage can't be 0 or under`,
                                    }
                                }}
                            />
                        </div>
                        <div css={{ display: 'flex', marginBottom: '18px' }}>
                            <Input
                                label="City*"
                                placeholder="City name"
                                register={register}
                                registerOption={{ required: `City can't be empty` }}
                                name={`manufacturers.${index}.city`}
                                error={get(errors, `manufacturers.${index}.city`)}
                                css={{
                                    maxWidth: '400px',
                                }}
                            />
                        </div> 
                        </Fragment>
                    ))}

                    <button
                        css={{
                            cursor: 'pointer',
                            margin: '0px',
                            background: 'transparent',
                            border: 'none',
                            display: 'flex',
                            alignItems: 'center',
                            padding: 0,
                            color: theme.black,
                            fontWeight: 500,
                            fontSize: '14px',
                            lineHeight: '17px',
                            'svg': {
                                marginRight: '12px',
                            }
                        }}
                        type="button"
                        onClick={appendField}
                    >
                        <AddIcon />
                        Add a manufacturer
                    </button>
                </div>
                
                <button
                    type="button"
                    onClick={handleSubmit}
                    css={{
                        cursor: 'pointer',
                        padding: '12px',
                        backgroundColor: theme.primary,
                        color: theme.white,
                        height: '44px',
                        border: 'none',
                        borderTop: `2px solid ${theme.primary}`,
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: '20px',
                    }}
                >Validate</button>
            </div>
        </div>
    );
}

export default BiologicSubstanceDialog;
