// https://newbedev.com/javascript-recursive-search-in-json-object
function searchObject(object: any, matchCallback: any, currentPath?: string, result?: any, searched?: any): any {
    currentPath = currentPath || '';
    result = result || [];
    searched = searched || [];
    if (searched.indexOf(object) !== -1 && object === Object(object)) {
        return;
    }
    searched.push(object);
    if (matchCallback(object)) {
        result.push({path: currentPath, value: object});
    }
    try {
        if (object === Object(object)) {
            for (var property in object) {
                if (property.indexOf("$") !== 0) {
                    searchObject(object[property], matchCallback, currentPath + "." + property, result, searched);
                }
            }
        }
    }
    catch (e) {
        console.error(object);
        throw e;
    }
    return result;
}

export function findPath(tree: any, id: string): string {
    const res = searchObject(tree, (value: any) => (value !== null && value !== undefined && value.id === id));
    return (res && res[0] && res[0].path);
}