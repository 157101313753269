import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { client } from "utils/client";

export function useGetCarbons() {
  const { data, isLoading, isError, isSuccess } = useQuery("carbons", () =>
    client(`carbon`)
  );
  return {
    carbons: data ?? [],
    isLoading,
    isError,
    isSuccess,
  };
}

export function useGetCarbonsCompleted() {
  const { data, isLoading, isError, isSuccess } = useQuery(
    "carbons_completed",
    () => client(`carbon/completed`)
  );
  return {
    carbons: data ?? [],
    isLoading,
    isError,
    isSuccess,
  };
}

export function useGetCarbon(id: string) {
  const { data, isLoading, isError, isSuccess } = useQuery(["carbon", id], () =>
    id ? client(`carbon/${id}`) : null
  );
  return {
    carbon: data ?? null,
    isLoading,
    isError,
    isSuccess,
  };
}

export function useCreateOrValidateCarbon(id: string) {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation(
    (state: any) =>
      id
        ? client(`carbon/validate/${id}`, state)
        : client(`carbon/create`, state),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("carbons");
        if (id) queryClient.invalidateQueries(["carbon", id]);
      },
    }
  );
  return { mutate, ...rest };
}

export function useCreateOrValidateCarbonWithoutProductionData(id: string) {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { mutate, ...rest } = useMutation(
    (state: any) =>
      id
        ? client(`carbon/validate_without_production/${id}`, state)
        : client(`carbon/create_without_production`, state),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("carbons");
        if (id) queryClient.invalidateQueries(["carbon", id]);
        history.push("/my_footprints");
      },
    }
  );
  return { mutate, ...rest };
}

export function useCreateNotCompleteCarbon() {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation(
    (state: any) => client(`carbon/draft/create`, state),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("carbons");
      },
    }
  );
  return { mutate, ...rest };
}

export function useUpdateCarbon(id: string) {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation(
    (state: any) => client(`carbon/update/${id}`, state),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("carbons");
      },
    }
  );
  return { mutate, ...rest };
}

export function useGetCarbonSecondPart(id: string) {
  const { data, isLoading, isError, isSuccess } = useQuery(["carbon", id], () =>
    id ? client(`carbon/second_part/${id}`) : null
  );
  return {
    carbon: data ?? null,
    isLoading,
    isError,
    isSuccess,
  };
}

export function useValidateSecondPartCarbon(id: string) {
  const { mutate, ...rest } = useMutation((state: any) =>
    client(`carbon/second_part/validate/${id}`, state)
  );
  return { mutate, ...rest };
}

export function useUpdateCarbonSecondPart(id: string) {
  const { mutate, ...rest } = useMutation((state: any) =>
    client(`carbon/second_part/update/${id}`, state)
  );
  return { mutate, ...rest };
}

export function useDeleteCarbon() {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation(
    (id: number) =>
      client(
        `carbon/${id}`,
        {},
        {
          method: "DELETE",
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("carbons");
      },
    }
  );
  return { mutate, ...rest };
}

export function useUploadFile(setValue: any, setError: any) {
  return useMutation(
    (formData: any) =>
      client(`carbon/upload`, formData, {
        "Content-Type": "multipart/form-data",
        method: "POST",
      }),
    {
      onSuccess: (data) => {
        setValue(data.name, data.file.Key);
      },
      onError: (err: any, variables: any) => {
        const fieldName = variables.get("name");
        if (err.response.data.message === "size_more_1_mo") {
          setError(fieldName, {
            type: "manual",
            message: "File size must be less than 1mo",
          });
        } else
          setError(fieldName, {
            type: "manual",
            message: "Error while uploading file",
          });
      },
    }
  );
}
