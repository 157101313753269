import _ from "lodash";
import { UseFormSetValue } from "react-hook-form";
import { API } from "./drugSubstance";

export type ProductionCountry = {
  countryId: number | null;
  percentage: string | null;
};

export interface DrugOrigin {
  drugProductName: string;
  drugProductCode: string;
  drugProductCodeHs: string;
  commercializationCountry: number | null;
  productionCountriesDrugFormulation: ProductionCountry[];
  productionCountriesPrimaryPackaging: ProductionCountry[];
  apiCount: number;
  api: API[];
}

export function setFormData(data: any, setValue: UseFormSetValue<DrugOrigin>) {
  console.log(data);

  const productionCountriesDrugFormulation = _.filter(
    data.productionCountries,
    { type: "DRUG_FORMULATION" }
  );
  const productionCountriesPrimaryPackaging = _.filter(
    data.productionCountries,
    { type: "PACKAGING" }
  );

  setValue("drugProductCode", data.drugProductCode || "");
  setValue("drugProductCodeHs", data.drugProductCodeHs || "");
  setValue("drugProductName", data.drugProductName || "");
  setValue("commercializationCountry", data.commercializationCountry || null);
  setValue(
    "productionCountriesDrugFormulation",
    productionCountriesDrugFormulation.length === 0
      ? [{ countryId: null, percentage: null }]
      : productionCountriesDrugFormulation
  );
  setValue(
    "productionCountriesPrimaryPackaging",
    productionCountriesPrimaryPackaging.length === 0
      ? [{ countryId: null, percentage: null }]
      : productionCountriesPrimaryPackaging
  );
  setValue("apiCount", data.apiCount || 1);
  setValue("api", data.api || []);
}
