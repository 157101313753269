/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import CurrentStepVisualiser from '../CurrentStepVisualiser';
import Button from 'component/Button';
import Header from 'component/StepHeader'
import { Checkbox } from 'component/Checkbox';
import { CarbonFootprint } from 'utils/carbonFootprint';
import { useFormContext } from 'react-hook-form';

function Step3({ handleStep }: {
    handleStep: (type: "decrement" | "increment") => void;
}) {
    const theme = useTheme();
    const { register } = useFormContext<CarbonFootprint>();

    return (
        <div
            css={{
                backgroundColor: theme.white,
                padding: '50px 56px',
                color: theme.black,
                display: 'grid',
                gridTemplateColumns: 'auto 268px',
                columnGap: '30px',
            }}
        >
            <div>
                <Header
                    title="Carbon footprint"
                    stepName="Before step 3"
                    stepTitle="Explanation of the methodology"
                    backName="Previous step"
                    backAction={() => handleStep('decrement')}
                />

                <p
                    css={{
                        marginTop: '40px',
                        maxWidth: '636px',
                        color: theme.black,
                        fontSize: '14px',
                        lineHeight: '20px',
                        fontWeight: 400,
                        'span': {
                            fontWeight: 600,
                        },
                        'a': {
                            color: theme.black,
                            fontWeight: 600,
                            textDecoration: 'underline'
                        }
                    }}
                >
                    The next step is to build the synthetic route of the studied Product, starting from this Product and adding step by step all precursors (or intermediates) that are needed to manufacture this Product from the simpliest chemicals (<a target="_blank" rel="noreferrer" href="https://ecovamed-front-document.s3.eu-west-3.amazonaws.com/commodities">commodities</a>). You have to mention all steps you know, then validate the synthetic route.<br/><br/>

                    Reagents and solvents do not need to be mentioned, but only precursors whose atoms are present in the Product or which are used as protecting groups.<br/><br/>

                    Please find an exemple on the way to build such a <a target="_blank" rel="noreferrer" href="https://ecovamed-front-document.s3.eu-west-3.amazonaws.com/synthetic_route_tree_carbon">synthetic route for Ibuprofen</a>.<br/><br/>


                    For each precursor/step, you will have to enter data such as the name of the manufacturer, the production site country and the share supplied from this production site if the product is made from several plants. The share corresponds to the percentage of the volume of the last calendar year.<br/><br/>

                    Ecovamed will cross-check all data and may request additional information for some precursor/step, or add additional precursors/steps to complete the synthetic route.<br/><br/>

                    The price of the carbon footprint evaluation will be communicated after this first step (pricing is explained in your Account information, <a target="_blank" href="/my_account/pricing">click here</a>) 
                </p>

                <Checkbox
                    title={<p css={{
                        color: theme.black,
                        'a': {
                            color: theme.black,
                            fontWeight: 600,
                            textDecoration: 'underline'
                        }
                    }}>Tick the box if a written report compliant with the GHG Protocol standard is needed (<a target="_blank" rel="noreferrer" href="https://ecovamed-front-document.s3.eu-west-3.amazonaws.com/ghg_example_report">example of report</a>)</p>}
                    register={register}
                    name="reportDataWrittenReport"
                    css={{
                        marginTop: '48px',
                    }}
                />
            </div>
            <div>
                <CurrentStepVisualiser step={3} />
                <Button
                    variant="dark"
                    disabled={false}
                    onClick={() => handleStep('increment')}
                >Got it</Button>
            </div>
        </div>
    );
}

export default Step3;