/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { useState, useEffect } from "react";
import Logo from "assets/Logo.png";
import BackgroundImage from "assets/background.jpg";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { useRegister } from "utils/hooks/auth.hook";

export type RegisterFormData = {
  companyName: string;
  contactName: string;
  phone: string;
  email: string;
  password: string;
  companylegalName: string;
  billingAddress: string;
  accountingPhone: string;
  accountingEmail: string;
  accountType: "standard" | "premium" | null;
};

function Register() {
  const theme = useTheme();
  const history = useHistory();
  const [, setErrorMessage] = useState("");
  const [step, setStep] = useState(1);
  const { mutate, error } = useRegister(setErrorMessage, setStep);
  const methods = useForm<RegisterFormData>({ mode: "onChange" });
  const onSubmit = (data: RegisterFormData) => {
    mutate(data);
  };

  useEffect(() => {
    if (
      error &&
      error.response &&
      error.response.data.message === "Email taken"
    ) {
      methods.setError("email", { type: "manual", message: "Email taken" });
      setStep(1);
    }
  }, [error, methods]);

  const handleStep = (type: "decrement" | "increment") =>
    setStep((step) => (type === "decrement" ? step - 1 : step + 1));

  return (
    <div
      css={{
        width: "100%",
        height: "100vh",
        backgroundColor: theme.white,
        color: theme.black,
        display: "flex",
      }}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          css={{
            width: "504px",
            padding: "80px",
            maxHeight: "100vh",
            overflowY: "auto",
          }}
        >
          <img
            src={Logo}
            alt="Logo"
            onClick={() => {
              history.push("/login");
            }}
            css={{
              cursor: "pointer",
            }}
          />

          {step === 1 && <Step1 handleStep={handleStep} />}
          {step === 2 && <Step2 handleStep={handleStep} />}
          {step === 3 && <Step3 handleStep={handleStep} />}
          {step === 4 && <Step4 />}
        </form>
      </FormProvider>
      <div
        css={{
          flexGrow: 1,
          backgroundSize: "cover",
          backgroundRepeat: "none",
          backgroundImage: `url(${BackgroundImage})`,
        }}
      />
    </div>
  );
}

export default Register;
