import { CarbonFootprint } from "utils/carbonFootprint";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { DrugOrigin } from "utils/drugOrigin";
import { API } from "utils/drugSubstance";
import { client } from "../client";
import { useHistory } from "react-router-dom";

export function useCheckAdmin() {
  const { isLoading, isError, isSuccess } = useQuery(
    "admin/check",
    () => client(`admin/check`),
    {
      retry: 2,
    }
  );
  return {
    isLoading,
    isError,
    isSuccess,
  };
}

export function useAdminGetClients() {
  const { data, isLoading, isError, isSuccess } = useQuery(
    "admin/clients",
    () => client(`admin/clients`)
  );
  return {
    clients: data ?? [],
    isLoading,
    isError,
    isSuccess,
  };
}

/*
    ORIGIN
*/

export function useAdminGetOrigins() {
  const { data, isLoading, isError, isSuccess } = useQuery(
    "admin/origins",
    () => client(`admin/origins`)
  );
  return {
    origins: data ?? [],
    isLoading,
    isError,
    isSuccess,
  };
}

export function useAdminGetOrigin(id: string) {
  const { data, isLoading, isError, isSuccess } = useQuery(
    [`admin/origin`, id],
    () => client(`admin/origin/${id}`)
  );
  return {
    origin: data ?? null,
    isLoading,
    isError,
    isSuccess,
  };
}

type ValidateStatusOrigin = {
  status: "CHECK" | "COMPLETED";
};
export type ValidateDrugOrigin = DrugOrigin & ValidateStatusOrigin;

export function useAdminValidateOrigin(id: string) {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation(
    (state: ValidateDrugOrigin) => client(`admin/origin/validate/${id}`, state),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("admin/origins");
      },
    }
  );
  return { mutate, ...rest };
}

export function useAdminUpdateOrigin(id: string) {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation(
    (state: DrugOrigin) => client(`admin/origin/update/${id}`, state),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("admin/origins");
      },
    }
  );
  return { mutate, ...rest };
}

export function useAdminDeleteOrigin() {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation(
    (id: number) =>
      client(
        `admin/origin/${id}`,
        {},
        {
          method: "DELETE",
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("admin/origins");
      },
    }
  );
  return { mutate, ...rest };
}

/*
    CARBON FOOTPRINT
*/

export function useAdminGetCarbons() {
  const { data, isLoading, isError, isSuccess } = useQuery(
    "admin/carbons",
    () => client(`admin/carbons`)
  );
  return {
    carbons: data ?? [],
    isLoading,
    isError,
    isSuccess,
  };
}

export function useAdminGetCarbonSecondPart(id: string) {
  const { data, isLoading, isError, isSuccess } = useQuery(
    [`admin/carbon`, id],
    () => client(`admin/carbon/second_part/${id}`)
  );
  return {
    carbon: data ?? null,
    isLoading,
    isError,
    isSuccess,
  };
}

export function useAdminGetCarbon(id: string) {
  const { data, isLoading, isError, isSuccess } = useQuery(
    [`admin/carbon`, id],
    () => client(`admin/carbon/${id}`)
  );
  return {
    carbon: data ?? null,
    isLoading,
    isError,
    isSuccess,
  };
}

export function useAdminValidateCarbon(id: string) {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation(
    (state: any) => client(`admin/carbon/validate/${id}`, state),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("admin/carbons");
      },
    }
  );
  return { mutate, ...rest };
}

export function useAdminValidateCarbonWithoutProductionData(id: string) {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { mutate, ...rest } = useMutation(
    (state: any) =>
      client(`admin/carbon/validate_without_production/${id}`, state),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("admin/carbons");
        history.push("/admin");
      },
    }
  );
  return { mutate, ...rest };
}

export function useAdminValidateCarbonSecondPart(id: string) {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation(
    (state: any) => client(`admin/carbon/second_part/validate/${id}`, state),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("admin/carbons");
      },
    }
  );
  return { mutate, ...rest };
}

export function useAdminUpdateCarbon(id: string) {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation(
    (state: any) => client(`admin/carbon/update/${id}`, state),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("admin/carbons");
      },
    }
  );
  return { mutate, ...rest };
}

export function useAdminUpdateCarbonSecondPart(id: string) {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation(
    (state: CarbonFootprint) =>
      client(`admin/carbon/second_part/update/${id}`, state),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("admin/carbons");
      },
    }
  );
  return { mutate, ...rest };
}

export function useAdminDeleteCarbon() {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation(
    (id: number) =>
      client(
        `admin/carbon/${id}`,
        {},
        {
          method: "DELETE",
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("admin/carbons");
      },
    }
  );
  return { mutate, ...rest };
}

/*
    SUBSTANCE
*/

export function useAdminGetSubstances() {
  const { data, isLoading, isError, isSuccess } = useQuery(
    "admin/substances",
    () => client(`admin/substances`)
  );
  return {
    substances: data ?? [],
    isLoading,
    isError,
    isSuccess,
  };
}

export function useAdminGetSubstance(id: string) {
  const { data, isLoading, isError, isSuccess } = useQuery(
    [`admin/substance`, id],
    () => client(`admin/substance/${id}`)
  );
  return {
    substance: data ?? null,
    isLoading,
    isError,
    isSuccess,
  };
}

type ValidateStatusSubstance = {
  status: "CHECK" | "COMPLETED";
};
export type ValidateDrugSubstance = API & ValidateStatusSubstance;

export function useAdminValidateSubstance(id: string) {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation(
    (state: ValidateDrugSubstance) =>
      client(`admin/substance/validate/${id}`, state),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("admin/substances");
      },
    }
  );
  return { mutate, ...rest };
}

export function useAdminUpdateSubstance(id: string) {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation(
    (state: API) => client(`admin/substance/update/${id}`, state),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("admin/substances");
      },
    }
  );
  return { mutate, ...rest };
}

export function useAdminDeleteSubstance() {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation(
    (id: number) =>
      client(
        `admin/substance/${id}`,
        {},
        {
          method: "DELETE",
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("admin/substances");
      },
    }
  );
  return { mutate, ...rest };
}
