/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import Button from "component/Button";
import { useHistory } from "react-router-dom";

function Step4() {
  const theme = useTheme();
  const history = useHistory();

  const handleRedirect = () => {
    history.push("/login");
  };

  return (
    <>
      <h1
        css={{
          fontSize: "24px",
          lineHeight: "28px",
          fontWeight: 600,
          marginTop: "40px",
          marginBottom: "24px",
        }}
      >
        Thank you!
        <br />
        Your account is created
      </h1>

      <p
        css={{
          marginTop: "0px",
          marginBottom: "108px",
          fontWeight: "normal",
          fontSize: "18px",
          lineHeight: "24px",
          color: theme.darkGrey,
        }}
      >
        You are going to receive an e-mail with a link to validate your
        subscription. Check your spam if you haven't received it in the next few
        hours. Then, Ecovamed will contact you in the next couple of days to
        start your first project!
      </p>

      <Button onClick={handleRedirect} variant="dark">
        Go to home
      </Button>
    </>
  );
}

export default Step4;
