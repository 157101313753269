import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { logout } from 'utils/token';

function Logout() {
    useEffect(() => {
        logout();
    }, []);

    return (
        <Redirect to="/login" />
    );
}

export default Logout;