/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import Navbar from 'component/Navbar';
import StatusButton from 'component/StatusButton';
import DeleteDialog from 'component/DeleteDialog';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as ArrowBackIcon } from 'assets/svg/ArrowBack.svg';
import { useDeleteOrigin, useGetOrigins } from 'utils/hooks/origin.hook';
import { useDeleteSubstance, useGetSubstances } from 'utils/hooks/substance.hook';
import { useDeleteCarbon, useGetCarbons } from 'utils/hooks/carbon.hook';
import { ReactComponent as TrashIcon } from 'assets/svg/Trash.svg';
import { useState } from 'react';

function MyProjects() {
    const theme = useTheme();
    const history = useHistory();
    const [deleteAction, setDeleteAction] = useState<null | (() => void)>(null);
    const { origins } = useGetOrigins();
    const { substances } = useGetSubstances();
    const { carbons } = useGetCarbons();
    const { mutate: deleteOrigin } = useDeleteOrigin();
    const { mutate: deleteSubstance } = useDeleteSubstance();
    const { mutate: deleteCarbon } = useDeleteCarbon();
    const tableEmpty = origins.length + substances.length + carbons.length;

    const handleRedirection = () => {
        history.push('/home');
    }

    const handleDeleteOrigin = (originId: number) => {
        setDeleteAction(() => () => deleteOrigin(originId));
    }

    const handleDeleteSubstance = (substanceId: number) => {
        setDeleteAction(() => () => deleteSubstance(substanceId));
    }

    const handleDeleteCarbon = (carbonId: number) => {
        setDeleteAction(() => () => deleteCarbon(carbonId));
    }

    const close = () => setDeleteAction(null);

    return (
        <>
        <DeleteDialog 
            close={close}
            isOpen={deleteAction ? true : false}
            action={deleteAction}
        />
        <div
            css={{
                width: '100%',
                minHeight: '100vh',
                backgroundColor: theme.white,
                color: theme.black,
                display: 'flex',
            }}
        >
            <Navbar />
            <div
                css={{
                    flexGrow: 1,
                    backgroundColor: theme.white,
                    padding: '50px 56px',
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight: '100vh',
                    overflowY: 'auto',
                    maxWidth: '1100px',
                }}
            >
                <button
                    type="button"
                    css={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        color: theme.black,
                        fontSize: '14px',
                        lineHeight: '17px',
                        border: 'none',
                        background: 'transparent',
                        padding: '0px',
                        'svg': {
                            marginRight: '14px'
                        }
                    }}
                    onClick={handleRedirection}
                >
                    <ArrowBackIcon />
                    Back Home
                </button>

                <h2 css={{
                        fontSize: '24px', 
                        lineHeight: '28px', 
                        fontWeight: 600, 
                        marginTop: '26px', 
                        marginBottom: '16px'
                    }}
                >My Projects</h2>

                <p css={{
                        color: theme.black, 
                        fontSize: '14px',
                        lineHeight: '18px',
                        marginTop: '0px',
                        marginBottom: '40px'
                    }}
                >Here you will find a list of all your projects</p>

                {tableEmpty > 0 &&
                <table
                    css={{
                        textAlign: 'center',
                        borderCollapse: 'collapse',
                        'tr, th, td': {
                            fontWeight: 500,
                            fontSize: '14px',
                            lineHeight: '17px',
                        },
                        'tr th, tr td': {
                            border: `1px solid ${theme.primary}`,
                            height: '50px',
                            color: theme.black,
                        },
                        'tr th': {
                            backgroundColor: theme.primary,
                            color: theme.white,
                        },
                        'tr td a': {
                            color: theme.black,
                            fontWeight: 600,
                            textDecoration: 'underline',
                        },
                        '.name': {
                            minWidth: '150px',
                        },
                        '.type': {
                            minWidth: '150px',
                        },
                        '.status, .document, .access': {
                            width: '120px',
                        },
                    }}
                >
                    <thead>
                        <tr>
                            <th className="name">Name of product</th>
                            <th className="type">Type</th>
                            <th className="status">Status</th>
                            <th className="document">Document</th>
                            <th className="access">Access</th>
                        </tr>
                    </thead>
                    <tbody>
                        {origins.map((origin: any) => (
                            <tr key={`origin_${origin.id}`}>
                                <td>{origin.drugProductName}</td>
                                <td>Labeling drug product</td>
                                <td><StatusButton status={origin.status} /></td>
                                <td>{origin.reportUrl ?
                                    <a href={origin.reportUrl} rel="noreferrer" target="_blank">report</a> :
                                    '-'}
                                </td>
                                <td>
                                    {(origin.status === 'DRAFT' || origin.status === 'CHECK') ?
                                        <Link to={`/drug_origin/${origin.id}`}>edit</Link> :
                                        '-'
                                    }
                                </td>
                                {origin.status === 'DRAFT' &&
                                    <td 
                                        onClick={() => handleDeleteOrigin(origin.id)}
                                        css={{
                                            cursor: 'pointer',
                                            border: 'none !important',
                                            padding: '0px 4px',
                                        }}
                                    ><TrashIcon /></td>
                                }
                            </tr>
                        ))}
                        {substances.map((substance: any) => (
                            <tr key={`substance_${substance.id}`}>
                                <td>{substance.inn}</td>
                                <td>Labeling drug substance</td>
                                <td><StatusButton status={substance.status} /></td>
                                <td>{substance.reportUrl ?
                                    <a href={substance.reportUrl} rel="noreferrer" target="_blank">report</a> :
                                    '-'}
                                </td>
                                <td>
                                    {(substance.status === 'DRAFT' || substance.status === 'CHECK') ?
                                        <Link to={`/drug_substance/${substance.id}`}>edit</Link> :
                                        '-'
                                    }
                                </td>
                                {substance.status === 'DRAFT' &&
                                    <td 
                                        onClick={() => handleDeleteSubstance(substance.id)}
                                        css={{
                                            cursor: 'pointer',
                                            border: 'none !important',
                                            padding: '0px 4px',
                                        }}
                                    ><TrashIcon /></td>
                                }
                            </tr>
                        ))}
                        {carbons.map((carbon: any) => (
                            <tr key={`carbon_${carbon.id}`}>
                                <td>{carbon.name}</td>
                                <td>Carbon Footprint</td>
                                <td><StatusButton status={carbon.status} /></td>
                                <td>{carbon.reportUrl ?
                                    <a href={carbon.reportUrl} rel="noreferrer" target="_blank">report</a> :
                                    '-'}
                                </td>
                                <td>
                                    {(carbon.status === 'DRAFT' || carbon.status === 'CHECK') ?
                                        <Link to={`/carbon_footprint/first_part/${carbon.id}`}>edit</Link> :
                                            (carbon.status === 'DRAFT2' || carbon.status === 'CHECK2') ?
                                            <Link to={`/carbon_footprint/second_part/${carbon.id}`}>edit</Link> :
                                            '-'
                                    }
                                </td>
                                {carbon.status === 'DRAFT' &&
                                    <td 
                                        onClick={() => handleDeleteCarbon(carbon.id)}
                                        css={{
                                            cursor: 'pointer',
                                            border: 'none !important',
                                            padding: '0px 4px',
                                        }}
                                    ><TrashIcon /></td>
                                }
                            </tr>
                        ))}
                    </tbody>
                </table>
                }
            </div>
        </div>
        </>
    );
}

export default MyProjects;