/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import { cx, css } from '@emotion/css'
import { ReactComponent as NotCheckedIcon } from 'assets/svg/NotChecked.svg';
import { ReactComponent as CheckedIcon } from 'assets/svg/Checked.svg';

function CurrentStepVisualiser({ step }: {
    step: number;
}) {
    const theme = useTheme();
    const stepCss = css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: theme.black,
        textAlign: 'center',
        marginBottom: '34px',
        '&:last-of-type': {
            marginBottom: '0px',
        },
        '.step__number': {
            fontSize: '10px',
            lineHeight: '12px',
            marginTop: '14px',
            marginBottom: '8px',
        },
        '.step__name': {
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '17px',
            marginTop: '0px',
            marginBottom: '0px',
        },
    });

    return (
        <div
            css={{
                width: '100%',
                marginTop: '120px',
                marginBottom: '32px',
                boxShadow: '0px 0px 10px 0px #040C1E14',
                borderRadius: '2px',
                padding: '0px',
                paddingTop: '34px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <div 
                className={cx(
                    stepCss,
                    css({
                        '.step__unchecked': {
                            display: step === 1 ? 'block' : 'none',
                        },
                        '.step__checked': {
                            display: step > 1 ? 'block' : 'none',
                        },
                        '.step__name': {
                            display: step > 5 ? 'none' : 'block',
                        }
                    })
                )}
            >
                <CheckedIcon className="step__checked" />
                <NotCheckedIcon className="step__unchecked" />
                <p className="step__number">Step 1</p>
                <p className="step__name">Identify your product</p>
            </div>

            <div 
                className={cx(
                    stepCss,
                    css({
                        '.step__unchecked': {
                            display: step <= 2 ? 'block' : 'none',
                            'path': {
                                fill: step === 2 ? theme.black : theme.grey,
                            }
                        },
                        '.step__checked': {
                            display: step > 2 ? 'block' : 'none',
                        },
                        '.step__name': {
                            display: step > 5 ? 'none' : 'block',
                        }
                    })
                )}
            >
                <CheckedIcon className="step__checked" />
                <NotCheckedIcon className="step__unchecked" />
                <p className="step__number">Step 2</p>
                <p className="step__name">Further information</p>
            </div>

            <div 
                className={cx(
                    stepCss,
                    css({
                        '.step__unchecked': {
                            display: step <= 4 ? 'block' : 'none',
                            'path': {
                                fill: step === 4 ? theme.black : theme.grey,
                            }
                        },
                        '.step__checked': {
                            display: step > 4 ? 'block' : 'none',
                        },
                        marginBottom: step > 5 ? '16px': '34px',
                        '.step__name': {
                            display: step > 5 ? 'none' : 'block',
                        }
                    })
                )}
            >
                <CheckedIcon className="step__checked" />
                <NotCheckedIcon className="step__unchecked" />
                <p className="step__number">Step 3</p>
                <p className="step__name">Build your synthetic route</p>
            </div>

            <div 
                className={cx(
                    stepCss,
                    css({
                        backgroundColor: step <= 5 ? theme.lightGrey : 'transparent',
                        marginBottom: '0px',
                        paddingTop: '18px',
                        paddingBottom: '34px',
                        '.step__unchecked': {
                            display: step <= 7 ? 'block' : 'none',
                            'path': {
                                fill: (step === 6 || step === 7) ? theme.black : theme.grey,
                            }
                        },
                        '.step__checked': {
                            display: step > 7 ? 'block' : 'none',
                        },
                    })
                )}
            >
                <CheckedIcon className="step__checked" />
                <NotCheckedIcon className="step__unchecked" />
                <p className="step__number">Step 4</p>
                <p className="step__name">Mass balance and process information</p>
            </div>

            <div 
                className={cx(
                    stepCss,
                    css({
                        backgroundColor: step <= 5 ? theme.lightGrey : 'transparent',
                        marginBottom: '0px',
                        paddingBottom: '30px',
                        '.step__unchecked': {
                            display: 'block',
                            'path': {
                                fill: step === 8 ? theme.black : theme.grey,
                            }
                        },
                    })
                )}
            >
                <NotCheckedIcon className="step__unchecked" />
                <p className="step__number">Step 5</p>
                <p className="step__name">Manufacturing plant information</p>
            </div>
        </div>
    );
}

export default CurrentStepVisualiser;