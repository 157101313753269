/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { UseFormWatch } from 'react-hook-form';

function UnsavedChangeDialog({
    redirectionPath,
    setRedirectionPath,
    valuesChanged,
    setValuesChanged,
    saveAction,
    watch,
}: {
    redirectionPath: string | null;
    setRedirectionPath: React.Dispatch<React.SetStateAction<string | null>>;
    valuesChanged: boolean;
    setValuesChanged: React.Dispatch<React.SetStateAction<boolean>>;
    saveAction: any;
    watch?: UseFormWatch<any>;
}) {
    const theme = useTheme();
    const history = useHistory();
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setRedirectionPath(null);
        setOpen(false);
    }

    const handleDontSave = () => {
        history.push(redirectionPath ? redirectionPath : '');
    }

    const handleSave = () => { 
        saveAction();
        history.push(redirectionPath ? redirectionPath : '');
    }

    const memoizedCallback = useCallback(
        (ev: any) => {
            if (valuesChanged) {
                setRedirectionPath('/home');
                ev.preventDefault();
                return ev.returnValue = 'Are you sure you want to close?';
            }
        },
        [valuesChanged, setRedirectionPath],
    );

    useEffect(() => {
        if (watch && typeof watch === 'function') {
            let subscription: any = null;
            if (valuesChanged === false)
                subscription = watch((value, { name, type }) => {
                    if (type === 'change') setValuesChanged(true);
                });
            return () => subscription && subscription.unsubscribe();
        }
      }, [watch, valuesChanged, setValuesChanged]);

    useEffect(() => {
        window.addEventListener("beforeunload", memoizedCallback);
        return () => window.removeEventListener('beforeunload', memoizedCallback)
    }, [memoizedCallback]);

    useEffect(() => {
        if (redirectionPath) {
            if (!valuesChanged)
                history.push(redirectionPath);
            else if (valuesChanged)
                setOpen(true);
        }
    }, [history, redirectionPath, valuesChanged]);

    return (
        <Dialog
            open={open ? true : false}
            onClose={handleClose}
            scroll="body"
        >
            <DialogTitle>{`You have unsaved data`}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Do you want to save the current project as draft ?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button
                    type="button"
                    css={{
                        cursor: 'pointer',
                        color: theme.primary,
                        border: 'none',
                        background: 'transparent',
                        fontWeight: 500,
                        fontSize: '16px'
                    }} 
                    onClick={handleDontSave}
                >
                    Don't save
                </button>
                <button
                    type="button"
                    css={{
                        cursor: 'pointer',
                        color: theme.primary,
                        border: 'none',
                        background: 'transparent',
                        fontWeight: 500,
                        fontSize: '16px'
                    }} 
                    onClick={handleSave}
                >
                    Save
                </button>
            </DialogActions>
        </Dialog>
    );
}

export default UnsavedChangeDialog;