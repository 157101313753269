import { useMutation, useQueryClient } from "react-query";
import { client } from "../client";
import { useHistory } from "react-router-dom";
import { Dispatch, SetStateAction } from "react";
import { setToken } from "../token";
import { RegisterFormData } from "pages/register";
import { LoginFormData } from "pages/Login";
import { FormResetPasswordData } from "pages/SetPassword";
import { ConfirmUserFormData } from "pages/ConfirmUser";
import { ChangePasswordFormData } from "pages/myAccount/administrator/ChangePassword";
import { SetupNewUserFormData } from "pages/myAccount/administrator/SetupNewUser";
import { CreatePasswordFormData } from "pages/CreatePassword";

export function useRegister(
  setErrorMessage: Dispatch<SetStateAction<string>>,
  setStep: React.Dispatch<React.SetStateAction<number>>
) {
  const { mutate, ...rest } = useMutation(
    (state: RegisterFormData) => client("auth/register", state),
    {
      onSuccess: (data) => {
        setStep(4);
      },
      onError: (error: any) => {},
    }
  );
  return { mutate, ...rest };
}

export function useLogin(
  setErrorMessage: Dispatch<SetStateAction<string>>,
  setChallengeSession: Dispatch<SetStateAction<string | null>>
) {
  const history = useHistory();
  const { mutate, isLoading, isIdle, isError } = useMutation(
    (state: LoginFormData) => client("auth/login", state),
    {
      onSuccess: (data) => {
        const { AuthenticationResult, ChallengeName, Session } = data;
        console.log(data);
        if (ChallengeName) {
          setChallengeSession(Session);
        } else {
          setToken(
            AuthenticationResult.AccessToken,
            AuthenticationResult.RefreshToken
          );
          history.push(`/`);
        }
      },
      onError: (error: any) => {
        if (!error.response) return setErrorMessage("Internal Server Error");
        if (
          error.response &&
          error.response.data &&
          error.response.data.message === "Incorrect username or password."
        )
          return setErrorMessage("Incorrect email or password");
        else if (
          error.response &&
          error.response.data &&
          error.response.data.message === "User is not confirmed."
        )
          return setErrorMessage(
            "Please confirm your account, click on the email we send to your email"
          );

        return setErrorMessage(
          error.response?.data?.message ?? "Unexpected Error"
        );
      },
    }
  );
  return { mutate, isLoading, isIdle, isError };
}

export function useForgotPassword(
  setEmailPlaceholder: Dispatch<SetStateAction<string>>
) {
  const { mutate, ...rest } = useMutation(
    (email: string) => client("auth/forgot-password", { email }),
    {
      onSuccess: (data) => {
        setEmailPlaceholder(data.CodeDeliveryDetails.Destination);
      },
      onError: (error: any) => {
        console.error(error);
      },
    }
  );
  return { mutate, ...rest };
}

export function useResetPassword(
  setErrorMessage: Dispatch<SetStateAction<string>>
) {
  const { mutate, ...rest } = useMutation(
    (data: FormResetPasswordData) => client("auth/reset-password", data),
    {
      onError: (error: any) => {
        if (!error.response) return setErrorMessage("Internal Server Error");
        if (
          error.response &&
          error.response.data &&
          error.response.data.message ===
            "Invalid code provided, please request a code again."
        )
          return setErrorMessage(
            "Invalid code provided, please request a code again."
          );
      },
    }
  );
  return { mutate, ...rest };
}

export function useConfirmUser() {
  const { mutate, ...rest } = useMutation(
    (data: ConfirmUserFormData) => client("auth/confirm-user", data),
    {
      onError: (error: any) => {
        console.error(error);
      },
    }
  );
  return { mutate, ...rest };
}

export function useUpdatePassword(
  setErrorMessage: Dispatch<SetStateAction<string>>
) {
  const { mutate, ...rest } = useMutation(
    (state: ChangePasswordFormData) => client(`auth/change_password`, state),
    {
      onError: (error: any) => {
        if (error.response?.data?.message === "Incorrect username or password.")
          return setErrorMessage("Incorrect password.");
        else if (error.response?.data?.message)
          return setErrorMessage(error.response?.data?.message);
      },
    }
  );
  return { mutate, ...rest };
}

// subuser part
export function useCreateSubUser(
  setErrorMessage: Dispatch<SetStateAction<string>>
) {
  const { mutate, ...rest } = useMutation(
    (state: SetupNewUserFormData) => client(`auth/create-sub-user`, state),
    {
      onError: (error: any) => {
        if (error.response?.data?.message === "Email taken")
          return setErrorMessage("Email already in use");
        return setErrorMessage(error.response?.data?.message);
      },
    }
  );
  return { mutate, ...rest };
}

export function useCreatePasswordSubUser(
  setErrorMessage: Dispatch<SetStateAction<string>>
) {
  const history = useHistory();
  return useMutation(
    (state: CreatePasswordFormData) =>
      client(`auth/create-password-sub-user`, state),
    {
      onError: (error: any) => {
        return setErrorMessage(error.response?.data?.message);
      },
      onSuccess: (data) => {
        const { AuthenticationResult } = data;
        setToken(
          AuthenticationResult.AccessToken,
          AuthenticationResult.RefreshToken
        );
        history.push(`/`);
      },
    }
  );
}

export function useDeleteSubUser() {
  const queryClient = useQueryClient();
  // setErrorMessage: Dispatch<SetStateAction<string>>
  return useMutation(
    (state: any) =>
      client(`auth/sub-user/${state.subUserId}`, state, {
        method: "DELETE",
      }),
    {
      onError: (error: any) => {},
      onSuccess: (data) => {
        queryClient.invalidateQueries("client");
      },
    }
  );
}
