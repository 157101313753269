/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import Input from 'component/Input';
import Button from 'component/Button';
import { Link } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { emailRegex, lowerCaseRegex, numberRegex, specialCharRegex, upperCaseRegex } from 'utils/regex';

type RegisterStep1FormData = {
    companyName: string;
    contactName: string;
    phone: string;
    email: string;
    password: string;
}

function Step1({ handleStep }: {
    handleStep: (type: "decrement" | "increment") => void;
}) {
    const theme = useTheme();
    const { register, trigger, formState: { errors, isValid } } = useFormContext<RegisterStep1FormData>();

    
    const handleNext = async () => {
        const isStepValid = await trigger();
        if (isStepValid) handleStep('increment');
    };

    return (
        <>
        <h1 css={{
            fontSize: '24px',
            lineHeight: '28px',
            fontWeight: 600,
            marginTop: '40px',
            marginBottom: '0px',
        }}
        >Create your account</h1>

        <h4 css={{
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: 'normal',
            color: theme.darkGrey,
            marginTop: '16px',
            marginBottom: '40px',
        }}>Step 1 - Your account information</h4>

        <Input
            label="Organization or company name"
            placeholder="Name"
            register={register}
            registerOption={{ required: `Company name can't be empty` }}
            name="companyName"
            error={errors.companyName && errors.companyName.message}
            css={{
                marginBottom: '16px'
            }}
        />

        <Input
            label="Contact name"
            placeholder="Name and Surname"
            register={register}
            registerOption={{ required: `Contact name can't be empty` }}
            name="contactName"
            error={errors.contactName && errors.contactName.message}
            css={{
                marginBottom: '16px'
            }}
        />

        <Input
            label="Phone number"
            placeholder="Phone number"
            register={register}
            registerOption={{ required: `Phone number can't be empty` }}
            name="phone"
            error={errors.phone && errors.phone.message}
            css={{
                marginBottom: '16px'
            }}
        />

        <Input
            label="Email"
            placeholder="Email"
            register={register}
            registerOption={{
                required: `Email can't be empty`,
                pattern: {
                    value: emailRegex,
                    message: 'Incorrect email',
                },
            }}
            name="email"
            error={errors.email && errors.email.message}
            css={{
                marginBottom: '16px'
            }}
        />

        <Input
            label="Create a password"
            placeholder="Password"
            register={register}
            registerOption={{
                required: `Password can't be empty`,
                minLength: {
                    value: 9,
                    message: `Password must have at least 9 characters`
                },
                maxLength: {
                    value: 50,
                    message: `Password can't exceed 50 characters`
                },
                validate: {
                    upperCase: (value: string) => upperCaseRegex.test(value) || `Password must have a upper case`,
                    lowerCase: (value: string) => lowerCaseRegex.test(value) || `Password must have a lower case`,
                    specialChar: (value: string) => specialCharRegex.test(value) || `Password must have a special character`,
                    number: (value: string) => numberRegex.test(value) || `Password must have a number`,
                }
            }}
            name="password"
            error={errors.password && errors.password.message}
            type="password"
            css={{
                marginBottom: '16px'
            }}
        />

        <p css={{
            marginTop: '-8px',
            marginBottom: '48px',
            color: theme.darkGrey,
            'fontWeight': 500,
            'fontSize': '12px',
            'lineHeight': '15px',
        }}>You need at least 9 signs including upper case, lower case, number and special character</p>
        
        <Button
            disabled={!isValid}
            onClick={handleNext}
            css={{ marginBottom: '40px' }}
        >Next</Button>

        <p 
            css={{
                textAlign: 'center',
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '17px',
                'a': {
                    textDecoration: 'underline',
                    color: theme.black,
                    fontWeight: 600,
                }
            }}
        >Already have an account ? <Link to="login">Log in</Link></p>
        </>
    )
}

export default Step1;