/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { DrugOrigin } from "utils/drugOrigin";
import { API } from "utils/drugSubstance";
import Button from "component/Button";
import CurrentStepVisualiser from "./CurrentStepVisualiser";
import Header from "component/StepHeader";
import Tree from "component/tree";
import ChemicalSubstanceDialog from "component/tree/ChemicalSubstanceDialog";
import BiologicSubstanceDialog from "component/tree/BiologicSubstanceDialog";
import { ReactComponent as ArrowSideIcon } from "assets/svg/ArrowSide.svg";

function Step4({
  handleStep,
  plants,
  setValuesChanged,
}: {
  handleStep: (type: "decrement" | "increment") => void;
  plants: any;
  setValuesChanged: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const theme = useTheme();
  const { setValue, getValues } = useFormContext<DrugOrigin>();
  const apiCount = getValues("apiCount");
  const [apiIndex, setApiIndex] = useState(0);
  const [api, setApi] = useState<API>();

  const handlePrevious = () => {
    if (apiIndex === 0) handleStep("decrement");
    else setApiIndex(apiIndex - 1);
  };

  const handleNext = () => {
    const api = getValues(`api.${apiIndex}`);
    if (!api.tree) return console.error(`Tree can't be empty`);

    if (apiIndex + 1 >= apiCount) handleStep("increment");
    else setApiIndex(apiIndex + 1);
  };

  const updateTree = (tree: any) => {
    setValuesChanged(true);
    setValue(`api.${apiIndex}.tree`, tree);
  };

  useEffect(() => {
    const api = getValues(`api.${apiIndex}`);
    if (api.tree) {
      // reset the tree if the type changed
      if (api.type !== api.tree.type) {
        const newTree = {
          name: api.inn,
          type: api.type,
          children: [],
          plants: [],
          manufacturers: [],
        };
        setApi({ ...api, tree: newTree });
      } else {
        const updatedTree = {
          ...api.tree,
          name: api.inn,
        };
        setApi({ ...api, tree: updatedTree });
      }
    } else {
      const newTree = {
        name: api.inn,
        type: api.type,
        children: [],
        plants: [],
        manufacturers: [],
      };
      setApi({ ...api, tree: newTree });
    }
  }, [apiIndex, getValues, setApi]);

  if (!api) return null;

  return (
    <div
      css={{
        backgroundColor: theme.white,
        padding: "50px 56px",
        color: theme.black,
        height: "100%",
        display: "grid",
        gridTemplateColumns: "auto 268px",
        gridTemplateRows: "146px auto",
        columnGap: "30px",
        ".nodes-link": {
          stroke: theme.secondary,
          strokeWidth: "2px",
        },
      }}
    >
      <Header
        title="Label of origin for a drug product"
        stepName="Step 3"
        stepTitle={
          api.type === "CHEMICAL"
            ? "Chemical API origin label"
            : api.type === "BIOLOGIC"
            ? "Biologic API origin label"
            : "Natural API origin label"
        }
        backName="Previous step"
        backAction={handlePrevious}
      />

      <div>
        <p
          css={{
            display: "flex",
            lineHeight: "17px",
            height: "17px",
            fontSize: "14px",
            color: theme.black,
            svg: {
              marginRight: "12px",
            },
          }}
        >
          <ArrowSideIcon />
          {api.type === "CHEMICAL"
            ? `Build your synthetic route tree for the drug substance #${
                apiIndex + 1
              }`
            : `Build your production scheme for the drug substance #${
                apiIndex + 1
              }`}
        </p>

        <Tree
          apiTree={api.tree}
          plants={plants}
          updateTree={updateTree}
          FormDialog={
            api.type === "CHEMICAL"
              ? ChemicalSubstanceDialog
              : BiologicSubstanceDialog
          }
          type={api.type}
        />
      </div>

      <div
        css={{
          gridColumn: "2 / 3",
          gridRow: "1 / 3",
        }}
      >
        <CurrentStepVisualiser step={4} />
        <Button
          variant="dark"
          onClick={handleNext}
          css={{
            fontSize: "14px",
          }}
        >
          Validate your synthetic route
        </Button>
      </div>
    </div>
  );
}

export default Step4;
