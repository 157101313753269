import { useQuery } from 'react-query';
import { client } from '../client';

function useNaturalTransformationType() {
    const { data: naturalTypes, isLoading, isError, isSuccess } = useQuery(
        'natural_transformation_type',
        () => client('transformationType/natural'),
    );
    return {
        naturalTypes,
        isLoading,
        isError,
        isSuccess,
    }
}

function useBiologicTransformationType() {
    const { data: biologicTypes, isLoading, isError, isSuccess } = useQuery(
        'biologic_transformation_type',
        () => client('transformationType/biologic'),
    );
    return {
        biologicTypes,
        isLoading,
        isError,
        isSuccess,
    }
}

export {
    useNaturalTransformationType,
    useBiologicTransformationType,
}