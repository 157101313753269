/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';

type TextAreaProps = {
    label: string;
    placeholder: string;
    name: string;
    className?: string;
    defaultValue?: string;
    disabled?: boolean;
    register: any;
    rows?: number;
    cols?: number;
    registerOption?: any;
    required?: boolean;
    error?: string;
}

export default function TextArea({
    label,
    placeholder,
    name,
    disabled = false,
    className,
    defaultValue,
    register,
    rows = 4,
    cols,
    registerOption = {},
    required = false,
    error = "",
    }: TextAreaProps
) {
    const theme = useTheme();
    
    return (
        <div
            css={{
                display: "flex",
                flexDirection: "column",
                width: '100%',  
            }}
            className={className}
        >
            <label
                css={{
                    fontSize: '14px',
                    lineHeight: '17px',
                    fontWeight: 500,
                    marginBottom: '8px',
                    color: disabled ? theme.grey : theme.black,
                }}
            >{label}</label>

            <div
                className="input__container"
                css={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <textarea
                    className="input__block"
                    css={{
                        border: '2px solid transparent',
                        borderRadius: '2px',
                        backgroundColor: theme.lightGrey,
                        padding: '16px',
                        fontSize: '16px',
                            width: '100%',
                        fontWeight: 400,
                        lineHeight: '20px',
                        color: theme.black,
                        outline: 'none',
                        '::placeholder': {
                            color: theme.grey,
                        },
                        '&:hover, &:focus': {
                            borderColor: theme.primary,
                        },
                    }}
                    {...register(name, { required, ...registerOption })}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    rows={rows}
                    cols={cols}
                />
            </div>
           
            {error &&
                <p css={{
                    marginTop: '4px',
                    marginBottom: '0px',
                    color: theme.error,
                    fontSize: '12px',
                    lineHeight: '15px',
                }}>{error}</p>
            }
        </div>
    );
}