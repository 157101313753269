/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import { ReactComponent as MinusIcon } from 'assets/svg/Minus.svg';
import { ReactComponent as PlusIcon } from 'assets/svg/Plus.svg';

export default function NumberInput({
    title,
    onChange,
    className,
    value,
    minValue = 0,
    maxValue = 100,
    disabled = false,
}: any) {
    const theme = useTheme();
    const handleClick = (type: "decrement" | "increment") => {
        if (type === "decrement") {
            if (value <= minValue) return;
            onChange(value - 1);
        }
        else {
            if (value >= maxValue) return;
            onChange(value + 1);
        }
    }

    return (
        <div
            className={className}
            css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                maxWidth: '400px',
                height: '52px',
                '.title': {
                    color: theme.black,
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '17px',
                },
                '.value__container': {
                    display: 'flex',
                    alignItems: 'center',
                    'button': {
                        cursor: 'pointer',
                        border: 'none',
                        background: 'transparent',
                        height: '50px',
                        width: '50px',
                        padding: '0px',
                        '&:disabled': {
                            cursor: 'not-allowed',
                            'svg': {
                                'circle, path': {
                                    stroke: theme.grey
                                },
                                height: '50px',
                                width: '50px',
                            },
                        },
                        'svg': {
                            'circle, path': {
                                stroke: theme.black
                            },
                            height: '50px',
                            width: '50px',
                        },
                    },
                    'p': {
                        color: theme.black,
                        width: '48px',
                        textAlign: 'center',
                        fontSize: '20px',
                        lineHeight: '24px',
                    }
                }
            }}
        >
            <p className="title">{title}</p>
            <div className="value__container">
                <button
                    disabled={disabled || value <= minValue ? true : false}
                    type="button" 
                    onClick={() => handleClick('decrement')}
                >
                    <MinusIcon />
                </button>

                <p>{value}</p>

                <button 
                    disabled={disabled}
                    type="button" 
                    onClick={() => handleClick('increment')}
                >
                    <PlusIcon />
                </button>
            </div>
        </div>
    );
}