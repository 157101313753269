/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import Logo from 'assets/Logo.png';
import { ReactComponent as HomeIcon } from 'assets/svg/Home.svg';
import { ReactComponent as LogoutIcon } from 'assets/svg/Logout.svg';

function CustomLink({ icon, title, to, matchingPaths, handleRedirection }: {
    icon: JSX.Element;
    title: string;
    to: string;
    matchingPaths: string[];
    handleRedirection: (path: string) => void;
}) {
    const currentPage = useRouteMatch(matchingPaths);
    const theme = useTheme();
    const defaultLinkCss = css({
        backgroundColor: theme.primary,
        color: theme.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        height: '48px',
        border: 'none',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
        padding: '0px 20px',
        cursor: 'pointer',
        marginBottom: '24px',
        'svg': {
            width: '28px',
            marginRight: '10px',
            'path': {
                stroke: theme.white,
            }
        }
    });


    if (currentPage)
        return (
            <button css={defaultLinkCss}>
                {icon}
                {title}
            </button>
        );
    return (
        <button
            onClick={() => handleRedirection(to)}
            css={[
                defaultLinkCss,
                {
                    backgroundColor: 'transparent',
                    color: theme.black,
                    fontWeight: 500,
                    '&:last-of-type': {
                        marginTop: 'auto',
                        paddingTop: '32px',
                        borderTop: '1px solid #F6F5F6'
                    },
                    '&:hover': {
                        color: theme.primary,
                        fontWeight: 600,
                        'svg': {
                            'path': {
                                stroke: theme.primary,
                            }
                        }
                    },
                    'svg': {
                        'path': {
                            stroke: theme.black,
                        }
                    }
                }
            ]}
        >
            {icon}
            {title}
        </button>
    );
}

function Navbar({
    customRedirection,
}: {
    customRedirection?: any
}) {
    const history = useHistory();
    const handleRedirection = (path: string) => {
        if (customRedirection)
            customRedirection(path);
        else
            history.push(path);
    }

    return (
        <nav
            css={{
                zIndex: 2,
                width: '360px',
                height: '100vh',
                padding: '80px 60px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxShadow: '0px 0px 10px rgba(4, 12, 30, 0.08)'
            }}
        >
            <img css={{ marginBottom: '68px' }} src={Logo} alt="Logo" />

            <CustomLink
                title="Admin Panel"
                icon={<HomeIcon />}
                to="/admin/panel"
                handleRedirection={handleRedirection}
                matchingPaths={["/admin/panel"]}
            />

            <CustomLink
                title="Log out"
                icon={<LogoutIcon />}
                to="/logout"
                handleRedirection={handleRedirection}
                matchingPaths={["/logout"]}
            />
        </nav>
    );
}

export default Navbar;