/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import Navbar from 'component/Navbar';
import Button from 'component/Button';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as ArrowBackIcon } from 'assets/svg/ArrowBack.svg';
import { ReactComponent as AddCircleIcon } from 'assets/svg/AddCircle.svg';
import { useGetPlants } from 'utils/hooks/plant.hook';

function MyPlants() {
    const theme = useTheme();
    const history = useHistory();
    const { plants } = useGetPlants();

    const handleRedirection = () => {
        history.push('/home');
    }

    const redirectToPlant = () => {
        history.push('/plant/new');
    }

    return (
        <div
            css={{
                width: '100%',
                minHeight: '100vh',
                backgroundColor: theme.white,
                color: theme.black,
                display: 'flex',
            }}
        >
            <Navbar />
            <div
                css={{
                    flexGrow: 1,
                    backgroundColor: theme.white,
                    padding: '50px 56px',
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight: '100vh',
                    overflowY: 'auto',
                    maxWidth: '1100px',
                }}
            >
                <button
                    type="button"
                    css={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        color: theme.black,
                        fontSize: '14px',
                        lineHeight: '17px',
                        border: 'none',
                        background: 'transparent',
                        padding: '0px',
                        'svg': {
                            marginRight: '14px'
                        }
                    }}
                    onClick={handleRedirection}
                >
                    <ArrowBackIcon />
                    Back Home
                </button>

                <h2 css={{
                        fontSize: '24px', 
                        lineHeight: '28px', 
                        fontWeight: 600, 
                        marginTop: '26px', 
                        marginBottom: '16px'
                    }}
                >My Plants</h2>

                <p css={{
                        color: theme.black, 
                        fontSize: '14px',
                        lineHeight: '18px',
                        marginTop: '0px',
                        marginBottom: '40px'
                    }}
                >Here you will find a list of all your saved manufacturing plants</p>

                {plants.length > 0 &&
                <table
                    css={{
                        textAlign: 'center',
                        borderCollapse: 'collapse',
                        'tr, th, td': {
                            fontWeight: 500,
                            fontSize: '14px',
                            lineHeight: '17px',
                        },
                        'tr th, tr td': {
                            border: `1px solid ${theme.primary}`,
                            height: '50px',
                            color: theme.black,
                        },
                        'tr th': {
                            backgroundColor: theme.primary,
                            color: theme.white,
                        },
                        'tr td a': {
                            color: theme.black,
                            fontWeight: 600,
                            textDecoration: 'underline',
                        },
                        '.small-col': {
                            width: '120px',
                        }
                    }}
                >
                    <thead>
                        <tr>
                            <th>Company name</th>
                            <th>Country</th>
                            <th className="small-col">City</th>
                            <th className="small-col">Data year</th>
                            <th>Update information</th>
                        </tr>
                    </thead>
                    <tbody>
                        {plants.map((plant: any) => (
                            <tr key={`origin_${plant.id}`}>
                                <td>{plant.companyName}</td>
                                <td>{plant.country.name}</td>
                                <td>{plant.city}</td>
                                <td>{plant.reportingYear}</td>
                                <td><Link to={`/plant/${plant.id}`}>edit</Link></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                }

                <Button
                    onClick={redirectToPlant}
                    variant="dark"
                    icon={<AddCircleIcon />}
                    css={{
                        margin: '0px auto',
                        marginTop: '100px',
                        maxWidth: '330px',
                    }}
                >New Plant</Button>
            </div>
        </div>
    );
}

export default MyPlants;