/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import { useHistory, useParams } from 'react-router-dom';
import Button from 'component/Button';
import { useValidateSecondPartCarbon } from 'utils/hooks/carbon.hook';
import { useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

function FinalStep({
    carbon,
    setValuesChanged,
}: {
    carbon: any;
    setValuesChanged: React.Dispatch<React.SetStateAction<boolean>>
}) {
    const theme = useTheme();
    const history = useHistory();
    const params = useParams<{ id: string }>();
    const { isLoading, isSuccess, isError, mutate: validate } = useValidateSecondPartCarbon(params.id);
    
    const redirectHome = () => {
        history.push('/');
    }

    useEffect(() => {
        setValuesChanged(false);
        validate(carbon);
    }, [setValuesChanged, carbon, validate]);

    return (
        <div
            css={{
                flexGrow: 1,
                backgroundColor: theme.white,
                padding: '50px 56px',
                color: theme.black,
                height: '100%',
            }}
        >
            <div 
                css={{
                    maxWidth: '636px',
                    margin: '0px auto',
                    marginTop: '200px',
                    textAlign: 'center',
                    color: theme.black,
                    'h4': {
                        fontWeight: 600,
                        fontSize: '18px',
                        lineHeight: '18px',
                    },
                    'p': {
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '20px',
                        margin: '40px 0px 48px',
                        'span': {
                            fontWeight: 600,
                        }
                    },
                    'button': {
                        width: '268px',
                        margin: '0px auto',
                    }
                }}
            >
                {isLoading &&
                    <div css={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                    }}>
                        <CircularProgress />
                    </div>
                }

                {isError &&
                    <p css={{
                        color: theme.error
                    }}>An error happened</p>
                }

                {isSuccess &&
                <>
                    <h4>Thank you!</h4>
                    <p>The carbon footprint of your product will be evaluated in the coming days after reviewing all your data. 
                    <br /><br />
                    We may have to ask additional questions before finalizing the footprint.</p>
                    <Button variant="dark" onClick={redirectHome}>Go back Home</Button>
                </>
                }
            </div>
        </div>
    );
}

export default FinalStep;