import { useMutation, useQuery } from "react-query";
import { client } from "../client";
import { GeneralInformationFormData } from "pages/myAccount/administrator/GeneralInformation";
import { InvoicingInformationFormData } from "pages/myAccount/administrator/InvoicingInformation";

export function useGetClientInfo() {
  const { data, isLoading, isError, isSuccess } = useQuery("client", () =>
    client("client")
  );
  return {
    client: data,
    isLoading,
    isError,
    isSuccess,
  };
}

export function useUpdateGeneralInformation() {
  const { mutate, ...rest } = useMutation((state: GeneralInformationFormData) =>
    client(`client/general_information`, state)
  );
  return { mutate, ...rest };
}

export function useUpdateInvoivingInformation() {
  const { mutate, ...rest } = useMutation(
    (state: InvoicingInformationFormData) =>
      client(`client/invoicing_information`, state)
  );
  return { mutate, ...rest };
}
