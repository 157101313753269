/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import Input from "component/Input";
import { useForm } from "react-hook-form";
import Button from "component/Button";
import _ from "lodash";
import { lowerCaseRegex, numberRegex, specialCharRegex, upperCaseRegex } from "utils/regex";
import { useUpdatePassword } from "utils/hooks/auth.hook";
import { useEffect, useState } from "react";

export type ChangePasswordFormData = {
    password: string;
    newPassword: string;
    confirmNewPassword: string;
}

function ChangePassword() {
    const theme = useTheme();
    const [errorMessage, setErrorMessage] = useState('');
    const { mutate: update, isSuccess } = useUpdatePassword(setErrorMessage);
    const { register, handleSubmit, reset, watch, formState: { dirtyFields, errors } } = useForm<ChangePasswordFormData>({
        mode: "onChange"
    });

    const newPassword = watch('newPassword');
    
    const onSubmit = (data: ChangePasswordFormData) => {
        update(data);
        reset(data);
    }

    useEffect(() => {
        if (isSuccess)
            setErrorMessage('');
    }, [isSuccess]);

    const formDisabled = (!_.isEmpty(dirtyFields) && _.isEmpty(errors));

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            css={{
                padding: '24px 40px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <h3 css={{
                fontWeight: 600,
                fontSize: '24px',
                lineHeight: '29px',
                color: theme.black,
                marginTop: '0px',
                marginBottom: '48px',
            }}>Change password</h3>

            <Input
                label="Password"
                type="password"
                placeholder=""
                name="password"
                register={register}
                css={{
                    marginBottom: '24px',
                    maxWidth: '344px',
                }}
            />

            <Input
                label="New Password"
                type="password"
                placeholder=""
                name="newPassword"
                register={register}
                registerOption={{
                    required: `Password can't be empty`,
                    minLength: {
                        value: 9,
                        message: `Password must have at least 9 characters`
                    },
                    maxLength: {
                        value: 50,
                        message: `Password can't exceed 50 characters`
                    },
                    validate: {
                        upperCase: (value: string) => upperCaseRegex.test(value) || `Password must have a upper case`,
                        lowerCase: (value: string) => lowerCaseRegex.test(value) || `Password must have a lower case`,
                        specialChar: (value: string) => specialCharRegex.test(value) || `Password must have a special character`,
                        number: (value: string) => numberRegex.test(value) || `Password must have a number`,
                    }
                }}
                error={errors.newPassword && errors.newPassword.message}
                css={{
                    marginBottom: '24px',
                    maxWidth: '344px',
                }}
            />

            <Input
                label="Confirm Password"
                type="password"
                placeholder=""
                name="confirmNewPassword"
                register={register}
                registerOption={{
                    required: `Confirm your password`,
                    validate: {
                        match: (value: string) => (newPassword === value) || `Password does not match`
                    }
                }}
                error={errors.confirmNewPassword && errors.confirmNewPassword.message}
                css={{
                    marginBottom: '24px',
                    maxWidth: '344px',
                }}
            />
            
            {errorMessage &&
                <p
                    css={{
                        color: theme.error,
                        fontSize: '14px',
                        marginTop: '-14px',
                        marginBottom: '14px',
                    }}
                >{errorMessage}</p>
            }

            {isSuccess &&
            <p
                css={{
                    color: theme.secondary,
                    fontWeight: 600,
                    fontSize: '14px',
                    marginTop: '-14px',
                    marginBottom: '14px',
                }}
            >Password successfully updated !</p>}

            <Button
                type="submit"
                disabled={!formDisabled}
                variant="dark"
                css={{
                    maxWidth: '344px',
                }}
            >Change Password</Button>
        </form>
    );
}

export default ChangePassword;