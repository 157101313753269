import { UseFormSetValue } from "react-hook-form";
import { Tree } from "utils/drugSubstance";

export function setFormData(data: any, setValue: UseFormSetValue<CarbonFootprint>) {
  setValue('cas', data.cas || '');
  setValue('name', data.name || '');
  setValue('reactionNumber', data.reactionNumber || 0);
  setValue('reactionNumberWithInformation', data.reactionNumberWithInformation || 0);
  setValue('reactionNumberWithoutInformation', data.reactionNumberWithoutInformation || 0);
  setValue('nameOfSupplier', data.nameOfSupplier || '');
  setValue('productionCountry', data.productionCountry || null);
  setValue('reportDataComments', data.reportDataComments || '');
  setValue('reportDataCompanyActivity', data.reportDataCompanyActivity || '');
  setValue('reportDataContactPersonEmail', data.reportDataContactPersonEmail || '');
  setValue('reportDataContactPersonFunction', data.reportDataContactPersonFunction || '');
  setValue('reportDataContactPersonName', data.reportDataContactPersonName || '');
  setValue('reportDataProcessAndVolume', data.reportDataProcessAndVolume || '');
  setValue('reportDataProductUse', data.reportDataProductUse || '');
  setValue('reportDataWrittenReport', data.reportDataWrittenReport);
  setValue('reportDataDownloadMolecularStructure', data.reportDataDownloadMolecularStructure);
  setValue('reportDataDownloadMolecularSyntheticRoute', data.reportDataDownloadMolecularSyntheticRoute);
  setValue('reportDataDownloadProcessFlowDiagram', data.reportDataDownloadProcessFlowDiagram);
  setValue('tree', data.tree || null);
}

export type CarbonFootprint = {
  // step 1
  name: string;
  cas: string;
  reactionNumber: number;
  reactionNumberWithInformation: number;
  reactionNumberWithoutInformation: number;
  nameOfSupplier: string;
  productionCountry: number | null;

  // step 2
  reportDataContactPersonName: string;
  reportDataContactPersonEmail: string;
  reportDataContactPersonFunction: string;
  reportDataProductUse: string;
  reportDataCompanyActivity: string;
  reportDataProcessAndVolume: string;
  reportDataComments: string;
  reportDataDownloadMolecularStructure: string | null;
  reportDataDownloadMolecularSyntheticRoute: string | null;
  reportDataDownloadProcessFlowDiagram: string | null;
  
  // step tree
  tree: Tree;

  reportDataWrittenReport: boolean;
};

export type RefrigerantGase = {
  value: number;
  name: string;
};