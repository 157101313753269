/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { CarbonFootprint } from 'utils/carbonFootprint';
import CurrentStepVisualiser from '../CurrentStepVisualiser';
import Header from 'component/StepHeader'
import Button from 'component/Button';
import Tree from 'component/tree';
import ChemicalSubstanceDialog from 'component/tree/ChemicalSubstanceDialog';

function Step4({ handleStep, plants, setValuesChanged }: {
    handleStep: (type: "decrement" | "increment") => void;
    plants: any;
    setValuesChanged: React.Dispatch<React.SetStateAction<boolean>>
}) {
    const theme = useTheme();
    const { setValue, getValues } = useFormContext<CarbonFootprint>();
    const [tree, setTree] = useState<any>(null);

    const handleNext = () => {
        const tree = getValues(`tree`);
        if (!tree)
            return console.error(`Tree can't be empty`);
        else
            handleStep('increment');
    };

    const updateTree = (tree: any) => {
        setValuesChanged(true);
        setValue('tree', tree);
    }

    useEffect(() => {
        const tree = getValues('tree');
        const name = getValues('name');
        const cas = getValues('cas');
        if (!tree) {
            const newTree = {
                name,
                type: 'CHEMICAL',
                cas: cas,
                children: [],
                plants: [],
                manufacturers: [],
            };
            setTree(newTree);
        } else {
            setTree({ ...tree, type: 'CHEMICAL', name });
        }
    }, [getValues, setTree]);

    if (!tree) return null;

    return (
        <div
            css={{
                backgroundColor: theme.white,
                padding: '50px 56px',
                color: theme.black,
                height: '100%',
                display: 'grid',
                gridTemplateColumns: 'auto 268px',  
                gridTemplateRows: '146px auto',
                columnGap: '30px',
                '.nodes-link': {
                    stroke: theme.secondary,
                    strokeWidth: '2px',
                }
            }}
        >
            <Header
                title="Carbon footprint"
                stepName="Step 3"
                stepTitle={"Build your synthethic route tree"}
                backName="Previous step"
                backAction={() => handleStep('decrement')}
            />

            <div>
                <Tree
                    apiTree={tree}
                    plants={plants}
                    updateTree={updateTree}
                    FormDialog={ChemicalSubstanceDialog}
                    type={tree.type}
                />
            </div>
        
            <div
                css={{
                    gridColumn: '2 / 3',
                    gridRow: '1 / 3',
                }}
            >
                <CurrentStepVisualiser step={4} />
                <Button
                    variant="dark"
                    onClick={handleNext}
                    css={{
                        fontSize: '14px',
                        marginBottom: '100px',
                    }}
                >Validate your synthetic route</Button>
            </div>
        </div>
    );
}

export default Step4;