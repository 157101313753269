/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import Navbar from "component/Navbar";
import { ReactComponent as ArrowBackIcon } from "assets/svg/ArrowBack.svg";
import { ReactComponent as ProfileFilledIcon } from "assets/svg/ProfileFilled.svg";
import { ReactComponent as AccountingDocumentIcon } from "assets/svg/AccountingDocument.svg";
import { ReactComponent as InformationPointIcon } from "assets/svg/InformationPoint.svg";
import { ReactComponent as LegalHammerIcon } from "assets/svg/LegalHammer.svg";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useCheckIsSubUser } from "utils/hooks/user.hook";
import { useEffect } from "react";

function MyAccount() {
  const theme = useTheme();
  const history = useHistory();
  const { isSubUser, isSuccess } = useCheckIsSubUser();
  useEffect(() => {
    if (isSuccess && isSubUser) history.push("/");
  }, [isSuccess, isSubUser, history]);

  const handleRedirection = () => {
    history.push("/home");
  };

  const handleOpenLink = (link: string) => {
    window.open(`https://ecovamed.com/#${link}`);
  };

  return (
    <div
      css={{
        width: "100%",
        minHeight: "100vh",
        backgroundColor: theme.white,
        color: theme.black,
        display: "flex",
      }}
    >
      <Navbar />
      <div
        css={{
          flexGrow: 1,
          padding: "50px 56px",
        }}
      >
        <button
          type="button"
          css={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            color: theme.black,
            fontSize: "14px",
            lineHeight: "17px",
            border: "none",
            background: "transparent",
            padding: "0px",
            svg: {
              marginRight: "14px",
            },
          }}
          onClick={handleRedirection}
        >
          <ArrowBackIcon />
          Back Home
        </button>

        <h2
          css={{
            fontSize: "24px",
            lineHeight: "28px",
            fontWeight: 600,
            marginTop: "26px",
            marginBottom: "16px",
          }}
        >
          My Account
        </h2>

        <div
          css={{
            marginTop: "40px",
            width: "100%",
            display: "grid",
            gridTemplateColumns: "440px 440px",
            gridTemplateRows: "144px 144px",
            rowGap: "40px",
            columnGap: "24px",
            "@media (max-width: 1380px)": {
              gridTemplateColumns: "440px",
              gridTemplateRows: "144px 144px 144px 144px",
            },
            ".card": {
              padding: "0px 65px 0px 54px",
              boxShadow: "0px 0px 10px rgba(4, 12, 30, 0.08)",
              display: "flex",
              alignItems: "center",
              ".icon__block": {
                marginRight: "88px",
                width: "110px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              },
              ".title": {
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "22px",
                color: theme.black,
                marginBottom: "0px",
                textAlign: "center",
                width: "100%",
              },
              ".list": {
                fontSize: "14px",
                cursor: "pointer",
                padding: "0px",
                li: {
                  lineHeight: "17px",
                  "a, p": {
                    color: theme.darkGrey,
                    textDecoration: "none",
                  },
                  "&:hover": {
                    color: theme.black,
                    fontWeight: 500,
                  },
                  "+ li": {
                    marginTop: "10px",
                  },
                },
              },
            },
          }}
        >
          <div className="card">
            <div className="icon__block">
              <ProfileFilledIcon />
              <h3 className="title">Account</h3>
            </div>
            <ul className="list">
              <li>
                <Link to="/my_account/admin">Administrator</Link>
              </li>
              <li>
                <Link to="/my_account/user_list">User list</Link>
              </li>
              <li>
                <Link to="/my_account/setup_new_user">Set up new user</Link>
              </li>
            </ul>
          </div>

          <div className="card">
            <div className="icon__block">
              <AccountingDocumentIcon />
              <h3 className="title">Invoicing information</h3>
            </div>
            <ul className="list">
              <li>
                <Link to="/my_account/subscription">Subscription</Link>
              </li>
              <li>
                <Link to="/my_account/pricing">Pricing</Link>
              </li>
              <li>
                <Link to="/my_account/invoices">Invoices</Link>
              </li>
            </ul>
          </div>

          <div className="card">
            <div className="icon__block">
              <InformationPointIcon
                css={{
                  width: "40px",
                  height: "40px",
                }}
              />
              <h3 className="title">About us</h3>
            </div>
            <ul className="list">
              <li>
                <p onClick={() => handleOpenLink("company")}>Company</p>
              </li>
              <li>
                <p onClick={() => handleOpenLink("services")}>Services</p>
              </li>
              <li>
                <p onClick={() => handleOpenLink("contactus")}>Contact us</p>
              </li>
            </ul>
          </div>

          <div className="card">
            <div className="icon__block">
              <LegalHammerIcon />
              <h3 className="title">Legal terms</h3>
            </div>
            <ul className="list">
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://ecovamed-front-document.s3.eu-west-3.amazonaws.com/terms_of_sales"
                >
                  Terms of Sales
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://ecovamed-front-document.s3.eu-west-3.amazonaws.com/privacy_policy"
                >
                  Privacy policy
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://ecovamed-front-document.s3.eu-west-3.amazonaws.com/terms_of_use"
                >
                  Terms of Use
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyAccount;
