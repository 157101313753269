/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Logo from 'assets/Logo.png';
import BackgroundImage from 'assets/background.jpg';
import { useConfirmUser } from 'utils/hooks/auth.hook';
import { useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';

export type ConfirmUserFormData = {
    email: string,
    code: string,
}

function ConfirmUser() {
    const theme = useTheme();
    const history = useHistory();
    const params = useParams<ConfirmUserFormData>();
    const { mutate, isSuccess, isLoading, isError } = useConfirmUser();
    
    useEffect(() => {
        const data = {
            email: decodeURIComponent(params.email),
            code: params.code,
        }
        mutate(data);
    }, [params, mutate]);

    if (isLoading) {
        return (
            <div css={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100vh',
            }}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <div
            css={{
                width: '100%',
                height: '100vh',
                backgroundColor: theme.white,
                color: theme.black,
                display: 'flex',
            }}
        >
            <div
                css={{
                    width: '504px',
                    padding: '80px',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto'
                }}
            >
                <img 
                    src={Logo} 
                    alt="Logo" 
                    onClick={() => {
                        history.push('/login')
                    }}
                    css={{
                        height: '66px',
                        width: '200px',
                        cursor: 'pointer',
                    }} 
                />

                {isError &&
                <p
                    css={{
                        color: theme.error,
                        fontSize: '14px',
                        marginBottom: '-30px',
                        marginTop: '200px'
                    }}
                >Invalid code provided, please request an email again.</p>
                }

                {isSuccess &&
                    <p 
                    css={{
                        marginTop: '200px',
                        fontWeight: 'normal',
                        fontSize: '14px',
                        lineHeight: '17px',
                        'a': {
                            textDecoration: 'underline',
                            color: theme.black,
                            fontWeight: 600,
                        }
                    }}
                    >Your account is successfully activated ! <Link to="/login">Log in</Link></p>
                }
                
            </div>
            <div
                css={{
                    flexGrow: 1,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'none',
                    backgroundImage: `url(${BackgroundImage})`,
                }}
            />
        </div>
    );
}

export default ConfirmUser;