/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import { useFormContext } from 'react-hook-form';
import { Redirect, useParams } from 'react-router-dom';
import Button from 'component/Button';
import { useAdminValidateOrigin, ValidateDrugOrigin } from 'utils/hooks/admin.hook';
import { DrugOrigin } from 'utils/drugOrigin';
import Header from 'component/StepHeader';
import CircularProgress from '@material-ui/core/CircularProgress';

function FinalStep({ handleStep }: {
    handleStep: (type: "decrement" | "increment") => void;
}) {
    const theme = useTheme();
    const params = useParams<{ id: string }>();
    const { watch } = useFormContext<DrugOrigin>();
    const { mutate: validateOrigin, isLoading, isIdle, isSuccess } = useAdminValidateOrigin(params.id);

    const handleValidateCheck = () => {
        const data: ValidateDrugOrigin = { ...watch(), status: 'CHECK' }
        validateOrigin(data);
    }

    const handleValidateCompleted = () => {
        const data: ValidateDrugOrigin = { ...watch(), status: 'COMPLETED' }
        validateOrigin(data);
    }

    if (isSuccess) {
        return <Redirect to="/admin" />;
    }

    if (isLoading) {
        return (
            <div css={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100vh',
            }}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <div
            css={{  
                backgroundColor: theme.white,
                padding: '50px 56px',
                color: theme.black,
                display: 'grid',
                gridTemplateColumns: 'auto 268px',
                columnGap: '30px',
            }}
        >
            <div>
                <Header
                    title="Label of origin for a drug product"
                    stepName=""
                    stepTitle="Validation Step"
                    backName="Previous step"
                    backAction={() => handleStep('decrement')}
                />
                <div css={{
                    marginTop: '80px'
                }}>
                    <p>If no modifications (or no important modification for the client) you can save this a completed</p>
                    {isIdle &&
                    <Button variant="dark" onClick={handleValidateCompleted}>Save this as Completed</Button>
                    }
                </div>
                <div css={{
                    marginTop: '40px'
                }}>
                    <p>If the client has to check the ecovamed's modification</p>
                    {isIdle &&
                    <Button variant="dark" onClick={handleValidateCheck}>Save as Check</Button>
                    }
                </div>
            </div>
        </div>
    );
}

export default FinalStep;