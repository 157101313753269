/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';

type ButtonProps = {
    children: string;
    className?: string;
    type?: "button" | "reset" | "submit";
    variant?: 'light' | 'dark';
    icon?: JSX.Element;
    disabled?: boolean;
    onClick?: any;
}

export default function Button({
    children,
    className,
    type = "button",
    variant = 'light',
    icon,
    disabled = false,
    onClick,
    } : ButtonProps) {
    const theme = useTheme();

    return (
        <button
            className={className}
            css={{
                userSelect: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                height: '54px',
                width: '100%',
                color: variant === 'light' ? theme.primary : theme.white,
                backgroundColor: variant === 'light' ? theme.white : theme.primary,
                border: '2px solid',
                borderColor: theme.primary,
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '22px',
                padding: '16px',
                borderRadius: '2px',
                '&:hover': {
                    backgroundColor: variant === 'light' ? theme.lightGrey : "#0E0040",
                    borderColor: "#0E0040",
                },
                '&:disabled': {
                    borderColor: theme.lightGrey,
                    backgroundColor: theme.lightGrey,
                    color: theme.grey,
                    cursor: 'not-allowed',
                },
                'svg': {
                    marginRight: '8px',
                }
            }}
            disabled={disabled}
            type={type}
            onClick={onClick ? onClick : null}
        >
            {icon}
            {children}
        </button>
    );
}