/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { API } from 'utils/drugSubstance';
import Button from 'component/Button';
import CurrentStepVisualiser from './CurrentStepVisualiser';
import Header from 'component/StepHeader'
import Tree from 'component/tree';
import ChemicalSubstanceDialog from 'component/tree/ChemicalSubstanceDialog';
import BiologicSubstanceDialog from 'component/tree/BiologicSubstanceDialog';
import { ReactComponent as ArrowSideIcon } from 'assets/svg/ArrowSide.svg';

function Step3({ handleStep, plants, setValuesChanged }: {
    handleStep: (type: "decrement" | "increment") => void;
    plants: any;
    setValuesChanged: React.Dispatch<React.SetStateAction<boolean>>
}) {
    const theme = useTheme();
    const { setValue, getValues } = useFormContext<API>();
    const [tree, setTree] = useState<any>(null);

    const handleNext = () => {
        const tree = getValues(`tree`);
        if (!tree)
            return console.error(`Tree can't be empty`);
        else
            handleStep('increment');
    };

    const updateTree = (tree: any) => {
        setValuesChanged(true);
        setValue('tree', tree);
    }

    useEffect(() => {
        const tree = getValues('tree');
        const inn = getValues('inn');
        const type = getValues('type');
        if (tree) {
            if (type !== tree.type) {
                const newTree = {
                    name: inn,
                    type: type,
                    children: [],
                    plants: [],
                    manufacturers: [],
                };
                setTree({ ...newTree });
            } else {
                const updatedTree = {
                    ...tree,
                    name: inn,
                };
                setTree({ ...updatedTree });
            }
        } else {
            const newTree = {
                name: inn,
                type: type,
                children: [],
                plants: [],
                manufacturers: [],
            };
            setTree(newTree);
        }
    }, [getValues, setTree]);

    if (!tree) return null;

    return (
        <div
            css={{
                backgroundColor: theme.white,
                padding: '50px 56px',
                color: theme.black,
                height: '100%',
                display: 'grid',
                gridTemplateColumns: 'auto 268px',  
                gridTemplateRows: '146px auto',
                columnGap: '30px',
                '.nodes-link': {
                    stroke: theme.secondary,
                    strokeWidth: '2px',
                }
            }}
        >
            <Header
                title="Label of origin for a drug substance"
                stepName="Step 3"
                stepTitle={tree.type === 'CHEMICAL' ? "Chemical API origin label" : tree.type === 'BIOLOGIC' ? "Biologic API origin label" : "Natural API origin label"}
                backName="Previous step"
                backAction={() => handleStep('decrement')}
            />

            <div>
                <p css={{
                    display: 'flex',
                    lineHeight: '17px',
                    height: '17px',
                    fontSize: '14px',
                    color: theme.black,
                    'svg': {
                        marginRight: '12px'
                    }
                    
                }}><ArrowSideIcon />{tree.type === 'CHEMICAL' ? `Build your synthetic route tree for the drug substance` : `Build your production scheme for the drug substance`}</p>

                <Tree
                    apiTree={tree}
                    plants={plants}
                    updateTree={updateTree}
                    FormDialog={tree.type === 'CHEMICAL' ? ChemicalSubstanceDialog : BiologicSubstanceDialog}
                    type={tree.type}
                />
            </div>
        
            <div
                css={{
                    gridColumn: '2 / 3',
                    gridRow: '1 / 3',
                }}
            >
                <CurrentStepVisualiser step={3} />
                <Button
                    variant="dark"
                    onClick={handleNext}
                    css={{
                        fontSize: '14px'
                    }}
                >Validate your synthetic route</Button>
            </div>
        </div>
    );
}

export default Step3;