import { UseFormSetValue } from "react-hook-form";

export type TreeNode = {
    [key: string]: any;
    children?: TreeNode[];
    manufacturers: Manufacturer[];
}

export type Plant = {
    id: string;
    companyName: string;
    city: string;
    countryId: string;
}

export type Manufacturer = {
    plantId: string | null;
    percentage: number;
}

export type TreeType = 'CHEMICAL' | 'BIOLOGIC' | 'NATURAL' | null;

export type Tree = {
    plants: Plant[];
    manufacturers: Manufacturer[];
    children: TreeNode[];
    [key: string]: any;
}

export type API = {
    inn: string;
    cas: string;
    codeHs: string;
    type: TreeType;
    tree?: Tree;
}

export function setFormData(data: any, setValue: UseFormSetValue<API>) {
    setValue('inn', data.inn || '');
    setValue('cas', data.cas || '');
    setValue('codeHs', data.codeHs || '');
    setValue('type', data.type || null);
    setValue('tree', data.tree || null);
}