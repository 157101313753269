/** @jsxImportSource @emotion/react */
import { CSSObject, useTheme } from "@emotion/react";
import ReactSelect from "react-select";
// import { ReactComponent as ArrowDown } from 'assets/svg/ArrowDown.svg';

const customStyles = {
  container: (provided: CSSObject) => ({
    ...provided,
  }),
  control: (provided: CSSObject, state: any) => ({
    ...provided,
    padding: "0px 8px 0px 16px",
    height: "52px",
    backgroundColor: "#F6F5F6",
    border: "2px solid transparent",
    borderRadius: "2px",
    transition: "none",
    "&:hover, &:focus": {
      borderColor: "#1A085C",
    },
  }),
  placeholder: (provided: CSSObject) => ({
    ...provided,
    color: "#908F90",
  }),
  singleValue: (provided: CSSObject) => ({
    ...provided,
    color: "#040C1E",
    fontSize: "16px",
    lineHeight: "20px",
  }),
  menu: (provided: CSSObject) => ({
    ...provided,
    marginTop: "6px",
    backgroundColor: "#F6F5F6",
    border: "none",
    borderRadius: "2px",
    boxShadow: "none",
    minWidth: "200px",
  }),
  menuList: (provided: CSSObject) => ({
    ...provided,
    marginTop: "6px",
    padding: "8px 16px",
    backgroundColor: "#F6F5F6",
  }),
  option: (provided: CSSObject) => ({
    ...provided,
    padding: "8px 0px",
    cursor: "pointer",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#040C1E",
    backgroundColor: "transparent",
    "&:hover": {
      color: "#1A085C",
      backgroundColor: "transparent",
      fontWeight: 600,
    },
  }),
  valueContainer: (provided: CSSObject) => ({
    ...provided,
    padding: "0px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

// const CustomIcon = () => <ArrowDown />

function Select({
  label,
  placeholder,
  value,
  className,
  onChange,
  error,
  options,
  getOptionLabel,
  inputRef,
  isClearable = false,
  isDisabled = false,
  labelStyle = {},
}: any) {
  const theme = useTheme();

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
      className={className}
    >
      <label
        css={{
          display: "block",
          fontSize: "14px",
          lineHeight: "17px",
          marginBottom: "8px",
          fontWeight: 500,
          color: isDisabled ? theme.grey : theme.black,
          ...labelStyle,
        }}
      >
        {label}
      </label>
      <ReactSelect
        onChange={onChange}
        options={options}
        // components={{ IndicatorsContainer: CustomIcon }}
        placeholder={placeholder}
        getOptionLabel={getOptionLabel}
        value={value}
        styles={customStyles}
        ref={inputRef}
        isClearable={isClearable}
        isDisabled={isDisabled}
        menuPlacement="auto"
      />
      {error && (
        <p
          css={{
            marginTop: "4px",
            marginBottom: "0px",
            color: theme.error,
            fontSize: "12px",
            lineHeight: "15px",
          }}
        >
          {error}
        </p>
      )}
    </div>
  );
}

export default Select;
