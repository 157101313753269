/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import Input from 'component/Input';
import { InformationPoint } from 'component/InformationPoint';
import Select from 'component/Select';
import CurrentStepVisualiser from './CurrentStepVisualiser';
import Button from 'component/Button';
import Header from 'component/StepHeader'
import { useFormContext, Controller } from 'react-hook-form';
import { API } from 'utils/drugSubstance';
import { intRegex } from 'utils/regex';
import { TypeOptions } from 'utils/enums';

function Step1({ handleStep, handleSaveBeforeLeave }: {
    handleStep: (type: "decrement" | "increment") => void;
    handleSaveBeforeLeave: (path: string) => void;
}) {
    const theme = useTheme();
    const { register, control, trigger, formState: { errors } } = useFormContext<API>();

    const handleNext = async () => {
        const isStepValid = await trigger();
        if (isStepValid) {
            handleStep('increment');
        }
    };

    return (
        <div
            css={{  
                backgroundColor: theme.white,
                padding: '50px 56px',
                color: theme.black,
                display: 'grid',
                gridTemplateColumns: 'auto 268px',
                columnGap: '30px',
            }}
        >
            <div>
                <Header
                    title="Label of origin for a drug substance"
                    stepName="Step 1"
                    stepTitle={`Identify your drug substance`}
                    backName="Back Home"
                    backAction={() => handleSaveBeforeLeave('/home')}
                />
                
                <p css={{ color: theme.darkGrey,
                    fontSize: '12px',
                    lineHeight: '18px',
                    marginTop: '0px',
                    marginBottom: '24px'
                }}>*required fields</p>

                <div css={{ display: 'flex', marginBottom: '16px' }}>
                    <Input
                        label="INN*"
                        placeholder="Ibuprofen"
                        register={register} 
                        defaultValue=""
                        registerOption={{ required: `INN can't be empty` }}
                        name="inn"
                        error={errors.inn?.message}
                        css={{
                            maxWidth: '400px',
                        }}
                    />
                    <InformationPoint
                        css={{
                            marginTop: '40px',
                            marginLeft: '16px',
                        }}
                        text={`International Nonproprietary Name`}
                    />
                </div>

                <div css={{ display: 'flex', marginBottom: '16px' }}>
                    <Input
                        label="CAS number*"
                        placeholder="15687-27-1"
                        register={register}
                        defaultValue=""
                        registerOption={{ required: `CAS number can't be empty` }}
                        name="cas"
                        error={errors.cas?.message}
                        css={{
                            maxWidth: '400px',
                        }}
                    />
                    <InformationPoint
                        css={{
                            marginTop: '40px',
                            marginLeft: '16px',
                        }}
                        text={`CAS Registry Number assigned by the Chemical Abstracts Service`}
                    />
                </div>

                <div css={{ display: 'flex', marginBottom: '16px' }}>
                    <Controller
                        control={control}
                        name="type"
                        defaultValue={null}
                        rules={{ required: `Type can't be empty` }}
                        render={({
                            field: { onChange, value, ref },
                            fieldState: { error },
                        }) => (
                            <Select
                                onChange={(data: any) => onChange(data.value)}
                                options={TypeOptions}
                                inputRef={ref}
                                label="Type*"
                                placeholder="Select a type"
                                getOptionLabel={(option: any) => option.label}
                                value={TypeOptions.find((el: any) => el.value === value)}
                                error={error?.message}
                                css={{
                                    maxWidth: '400px',
                                }}
                            />
                        )}
                    />
                    <InformationPoint
                        css={{
                            marginTop: '40px',
                            marginLeft: '16px',
                        }}
                        text={`Select among Chemical (synthetic product), Biologic or Natural substance (vegetal, animal or mineral)`}
                    />
                </div>

                <div css={{ display: 'flex', marginBottom: '16px' }}>
                    <Input
                        label="HS code*"
                        placeholder="29420012"
                        register={register}
                        defaultValue=""
                        name="codeHs"
                        error={errors.codeHs?.message}
                        registerOption={{
                            required: `HS code can't be empty`,
                            minLength: { value: 6, message: 'HS code must be at least 6 digits long' },
                            validate: {
                                number: (value: string) => intRegex.test(value) || `HS code is number only`,
                            }
                        }}
                        css={{
                            maxWidth: '400px',
                        }}
                    />
                    <InformationPoint
                        css={{
                            marginTop: '40px',
                            marginLeft: '16px',
                        }}
                        text={`At least the 6 first digits have to be mentioned`}
                    />
                </div>
            </div>
            
            <div>
                <CurrentStepVisualiser step={1} />
                <Button
                    variant="dark"
                    disabled={false}
                    onClick={handleNext}
                >Next step</Button>
            </div>
        </div>
    );
}

export default Step1;