/** @jsxImportSource @emotion/react */
import { useTheme, css } from "@emotion/react";
import Input from "component/Input";
import { InformationPoint } from "component/InformationPoint";
import Button from "component/Button";
import Header from "component/StepHeader";
import { useFormContext } from "react-hook-form";
import { CarbonFootprint } from "utils/carbonFootprint";
import CurrentStepVisualiser from "../CurrentStepVisualiser";
import { emailRegex } from "utils/regex";
import { ReactComponent as CrossIcon } from "assets/svg/Add.svg";
import TextArea from "component/TextArea";
import { useUploadFile } from "utils/hooks/carbon.hook";
import { CircularProgress } from "@material-ui/core";

type FileFields =
  | "reportDataDownloadMolecularStructure"
  | "reportDataDownloadMolecularSyntheticRoute"
  | "reportDataDownloadProcessFlowDiagram";

function Step2({
  handleStep,
}: {
  handleStep: (type: "decrement" | "increment") => void;
}) {
  const theme = useTheme();
  const {
    watch,
    setValue,
    setError,
    clearErrors,
    register,
    trigger,
    formState: { errors },
  } = useFormContext<CarbonFootprint>();
  const { mutate: uploadFileApi, isLoading: isLoadingFile } = useUploadFile(
    setValue,
    setError
  );

  const reportDataDownloadMolecularStructureUploaded = watch(
    "reportDataDownloadMolecularStructure"
  );

  const reportDataDownloadMolecularSyntheticRouteUploaded = watch(
    "reportDataDownloadMolecularSyntheticRoute"
  );
  const reportDataDownloadProcessFlowDiagramUploaded = watch(
    "reportDataDownloadProcessFlowDiagram"
  );

  const handleNext = async () => {
    const isStepValid = await trigger();
    if (!reportDataDownloadMolecularStructureUploaded)
      setError("reportDataDownloadMolecularStructure", {
        type: "manual",
        message: `Please provide Molecular Structure's file`,
      });
    if (isStepValid && reportDataDownloadMolecularStructureUploaded) {
      handleStep("increment");
    }
  };

  const deleteFile = (name: FileFields) => {
    setValue(name, null);
  };

  const uploadFile = (name: FileFields, ev: any) => {
    if (ev.target.files.length > 0) {
      const file = ev.target.files[0];
      if (file.size > 1200000)
        setError(name, {
          type: "manual",
          message: "File size must be less than 1mo",
        });
      else {
        clearErrors(name);
        const form = new FormData();
        form.append("file", ev.target.files[0]);
        form.append("name", name);
        uploadFileApi(form);
      }
    }
  };

  const uploadFileCss = css({
    display: "flex",
    alignItems: "center",
    marginBottom: "32px",
    label: {
      fontSize: "14px",
      lineHeight: "17px",
      color: theme.black,
      "&:last-of-type": {
        marginBottom: "0px",
      },
      input: {
        display: "none",
      },
      span: {
        marginLeft: "16px",
        fontSize: "14px",
        lineHeight: "17px",
        fontWeight: 600,
        cursor: "pointer",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    svg: {
      marginLeft: "8px",
      cursor: "pointer",
      transform: "rotate(45deg)",
      userSelect: "none",
    },
  });

  return (
    <div
      css={{
        position: "relative",
      }}
    >
      <div
        css={{
          backgroundColor: theme.white,
          padding: "50px 56px 74px",
          color: theme.black,
          display: "grid",
          gridTemplateColumns: "auto 268px",
          columnGap: "30px",
        }}
      >
        <div>
          <Header
            title="Carbon footprint"
            stepName="Step 2"
            stepTitle="Further information"
            backName="Previous step"
            backAction={() => handleStep("decrement")}
          />

          <p
            css={{
              color: theme.darkGrey,
              fontSize: "12px",
              lineHeight: "18px",
              marginTop: "0px",
              marginBottom: "16px",
            }}
          >
            *required fields
          </p>

          <h3
            css={{
              color: theme.black,
              fontSize: "16px",
              lineHeight: "20px",
              fontWeight: 500,
              marginTop: "0px",
              marginBottom: "16px",
            }}
          >
            Contact person for further information:
          </h3>

          <div css={{ display: "flex", marginBottom: "16px" }}>
            <Input
              label="Name*"
              placeholder="Name SURNAME"
              register={register}
              registerOption={{ required: `Contact name can't be empty` }}
              name="reportDataContactPersonName"
              error={
                errors.reportDataContactPersonName &&
                errors.reportDataContactPersonName.message
              }
              css={{
                maxWidth: "400px",
              }}
            />
          </div>

          <div css={{ display: "flex", marginBottom: "16px" }}>
            <Input
              label="Function*"
              placeholder={`R&D Scientist`}
              register={register}
              registerOption={{ required: `Contact function can't be empty` }}
              name="reportDataContactPersonEmail"
              error={
                errors.reportDataContactPersonEmail &&
                errors.reportDataContactPersonEmail.message
              }
              css={{
                maxWidth: "400px",
              }}
            />
          </div>

          <div css={{ display: "flex", marginBottom: "32px" }}>
            <Input
              label="Email*"
              placeholder="name.surname@company.com"
              register={register}
              registerOption={{
                required: `Contact email can't be empty`,
                pattern: {
                  value: emailRegex,
                  message: "Incorrect email",
                },
              }}
              autoComplete="email"
              name="reportDataContactPersonFunction"
              error={
                errors.reportDataContactPersonFunction &&
                errors.reportDataContactPersonFunction.message
              }
              css={{
                maxWidth: "400px",
              }}
            />
          </div>

          <hr
            css={{
              maxWidth: "400px",
              marginLeft: "0px",
              marginBottom: "32px",
              borderTop: `1px solid ${theme.primary}`,
            }}
          />

          <div css={{ display: "flex", marginBottom: "16px" }}>
            <TextArea
              label="Use of the product*"
              placeholder="Cosmetic ingredient"
              register={register}
              registerOption={{
                required: `Use of the product can't be empty`,
              }}
              name="reportDataProductUse"
              error={
                errors.reportDataProductUse &&
                errors.reportDataProductUse.message
              }
              css={{
                maxWidth: "400px",
              }}
            />
            <InformationPoint
              css={{
                marginTop: "40px",
                marginLeft: "16px",
              }}
              text={`Describe in a few sentences the use of this product, its markets and benefits`}
            />
          </div>

          <div css={{ display: "flex", marginBottom: "16px" }}>
            <TextArea
              label="Description of company’s activity*"
              placeholder="No character limit"
              register={register}
              registerOption={{
                required: `Description of company’s activity can't be empty`,
              }}
              name="reportDataCompanyActivity"
              error={
                errors.reportDataCompanyActivity &&
                errors.reportDataCompanyActivity.message
              }
              css={{
                maxWidth: "400px",
              }}
            />
          </div>

          <div css={{ display: "flex", marginBottom: "16px" }}>
            <TextArea
              label="Annual volume in tons*"
              placeholder="Batch process - 50 tons/year"
              register={register}
              registerOption={{
                required: `Annual volume can't be empty`,
              }}
              name="reportDataProcessAndVolume"
              error={
                errors.reportDataProcessAndVolume &&
                errors.reportDataProcessAndVolume.message
              }
              css={{
                maxWidth: "400px",
              }}
            />
            <InformationPoint
              css={{
                marginTop: "40px",
                marginLeft: "16px",
              }}
              text={`General information on the process and annual volume of production in tons`}
            />
          </div>

          <div css={{ display: "flex", marginBottom: "32px" }}>
            <TextArea
              label="Comments"
              placeholder="No character limit"
              register={register}
              name="reportDataComments"
              error={
                errors.reportDataComments && errors.reportDataComments.message
              }
              css={{
                maxWidth: "400px",
              }}
            />
          </div>

          <div css={uploadFileCss}>
            <label>
              Molecular structure of the product*:
              {reportDataDownloadMolecularStructureUploaded ? (
                <span>molecular structure.pdf</span>
              ) : (
                <>
                  <span>upload file (1 Mo maximum)</span>
                  <input
                    onChange={(ev) =>
                      uploadFile("reportDataDownloadMolecularStructure", ev)
                    }
                    onClick={(event: any) => (event.target.value = null)}
                    type="file"
                    accept=".pdf,.doc,.docx,.xls"
                  />
                </>
              )}
            </label>
            {reportDataDownloadMolecularStructureUploaded && (
              <CrossIcon
                width="15"
                height="15"
                onClick={() =>
                  deleteFile("reportDataDownloadMolecularStructure")
                }
              />
            )}
          </div>

          {errors.reportDataDownloadMolecularStructure && (
            <p
              css={{
                marginTop: "-28px",
                marginBottom: "16px",
                color: theme.error,
                fontSize: "12px",
                lineHeight: "15px",
              }}
            >
              {errors.reportDataDownloadMolecularStructure.message}
            </p>
          )}

          <div css={uploadFileCss}>
            <label>
              Molecular synthetic route:
              {reportDataDownloadMolecularSyntheticRouteUploaded ? (
                <span>molecular synthetic.pdf</span>
              ) : (
                <>
                  <span>upload file (1 Mo maximum)</span>
                  <input
                    onChange={(ev) =>
                      uploadFile(
                        "reportDataDownloadMolecularSyntheticRoute",
                        ev
                      )
                    }
                    onClick={(event: any) => (event.target.value = null)}
                    type="file"
                    accept=".pdf,.doc,.docx,.xls"
                  />
                </>
              )}
            </label>
            {reportDataDownloadMolecularSyntheticRouteUploaded && (
              <CrossIcon
                width="15"
                height="15"
                onClick={() =>
                  deleteFile("reportDataDownloadMolecularSyntheticRoute")
                }
              />
            )}
          </div>
          {errors.reportDataDownloadMolecularSyntheticRoute && (
            <p
              css={{
                marginTop: "-28px",
                marginBottom: "16px",
                color: theme.error,
                fontSize: "12px",
                lineHeight: "15px",
              }}
            >
              {errors.reportDataDownloadMolecularSyntheticRoute.message}
            </p>
          )}

          <div css={uploadFileCss}>
            <label>
              Process Flow Diagram (if available):
              {reportDataDownloadProcessFlowDiagramUploaded ? (
                <span>processflow diagram.pdf</span>
              ) : (
                <>
                  <span>upload file (1 Mo maximum)</span>
                  <input
                    onChange={(ev) =>
                      uploadFile("reportDataDownloadProcessFlowDiagram", ev)
                    }
                    onClick={(event: any) => (event.target.value = null)}
                    type="file"
                    accept=".pdf,.doc,.docx,.xls"
                  />
                </>
              )}
            </label>
            {reportDataDownloadProcessFlowDiagramUploaded && (
              <CrossIcon
                width="15"
                height="15"
                onClick={() =>
                  deleteFile("reportDataDownloadProcessFlowDiagram")
                }
              />
            )}
          </div>
        </div>
        {errors.reportDataDownloadProcessFlowDiagram && (
          <p
            css={{
              marginTop: "-28px",
              marginBottom: "16px",
              color: theme.error,
              fontSize: "12px",
              lineHeight: "15px",
            }}
          >
            {errors.reportDataDownloadProcessFlowDiagram.message}
          </p>
        )}

        <div>
          <CurrentStepVisualiser step={2} />
          <Button variant="dark" disabled={isLoadingFile} onClick={handleNext}>
            Next step
          </Button>
        </div>
      </div>
      <div
        css={{
          display: isLoadingFile ? "flex" : "none",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
          position: "absolute",
          zIndex: 5,
          inset: 0,
          backgroundColor: "white",
        }}
      >
        <CircularProgress />
      </div>
    </div>
  );
}

export default Step2;
