/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import { useState } from 'react';
import { ReactComponent as InformationPointIcon } from 'assets/svg/InformationPoint.svg';
import informationIcon from 'assets/Information.png';

export function InformationPoint({ text, className }: {
    text: JSX.Element | string;
    className?: string;
}) {
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const handleMouseOver = () => setOpen(true);
    const handleMouseLeave = () => setOpen(false);

    return (
        <div 
            className={className} 
            css={{ 
                position: 'relative',
                width: '20px',
                height: '20px',
            }}
        >
            <button 
                onMouseEnter={handleMouseOver}
                onMouseLeave={handleMouseLeave}
                type="button"
                css={{
                    cursor: 'pointer',
                    background: 'transparent',
                    border: 'none',
                    width: '20px',
                    height: '20px',
                    padding: '0px',
                    'svg': {
                        'path': {
                            fill: open ? theme.secondary : theme.black,
                        }
                    }
                }}
            >
                <InformationPointIcon />
            </button>
            <div css={{
                position: 'absolute',
                left: '32px',
                top: '-14px',
                boxShadow: '0px 0px 10px 0px #040C1E14',
                padding: '12px',
                fontSize: '12px',
                lineHeight: '15px',
                width: '220px',
                borderRadius: '2px',
                backgroundColor: theme.white,
                display: open ? 'flex' : 'none',
                minHeight: '48px',
                justifyContent: 'center',
                alignItems: 'center',
            }}>{text}</div>
        </div>
    )
}

export function InformationPointNonSVG ({ text, className }: {
    text: JSX.Element | string;
    className?: string;
}) {
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const handleMouseOver = () => setOpen(true);
    const handleMouseLeave = () => setOpen(false);

    return (
        <div 
            className={className} 
            css={{ 
                position: 'relative',
                width: '14px',
                height: '14px',
            }}
        >
            <span 
                onMouseEnter={handleMouseOver}
                onMouseLeave={handleMouseLeave}
                css={{
                    cursor: 'pointer',
                    background: 'transparent',
                    border: 'none',
                    width: '14px',
                    height: '14px',
                    padding: '0px',
                }}
            >
                <img src={informationIcon} alt="i" />
            </span>
            <div css={{
                position: 'absolute',
                left: '32px',
                top: '-14px',
                boxShadow: '0px 0px 10px 0px #040C1E14',
                padding: '12px',
                fontSize: '12px',
                lineHeight: '12px',
                width: '220px',
                borderRadius: '2px',
                backgroundColor: theme.white,
                display: open ? 'flex' : 'none',
                minHeight: '48px',
                justifyContent: 'center',
                alignItems: 'center',
            }}>{text}</div>
        </div>
    )
}