import { PlantParsed } from 'utils/plant';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { client } from '../client';

export function useGetPlants() {
    const { data, isLoading, isError, isSuccess } = useQuery(
        'plants',
        () => client('plant'),
    );
    return {
        plants: data ?? [],
        isLoading,
        isError,
        isSuccess,
    }
}
export function useGetPlant(plantId: string) {
    const { data, isLoading, isError, isSuccess } = useQuery(
        ['plant', plantId],
        () => client(`plant/${plantId}`),
    );
    return {
        plant: data ?? null,
        isLoading,
        isError,
        isSuccess,
    }
}

export function useCreatePlant() {
    const queryClient = useQueryClient();
    const { mutate, ...rest } = useMutation(
        (state: PlantParsed) => client(`plant/create`, state),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('plants');
            }
        }
    );
    return { mutate, ...rest };
}

export function useUpdatePlant(plantId: string) {
    const queryClient = useQueryClient();
    const { mutate, ...rest } = useMutation(
        (state: PlantParsed) => client(`plant/update/${plantId}`, state),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('plants');
            }
        }
    );
    return { mutate, ...rest };
}
