/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { useGetClientInfo } from "utils/hooks/client.hook";
import { getDayMonthYear } from "utils/formatDate";
import _ from "lodash";

function FakeInput({
    label,
    className,
    value,
}: {
    label: string;
    className?: any;
    value: string;
}) {
    const theme = useTheme();

    return (
        <div
            css={{
                display: "flex",
                flexDirection: "column",
                width: '100%',  
            }}
            className={className}
        >
            <label
                css={{
                    fontSize: '14px',
                    lineHeight: '17px',
                    fontWeight: 500,
                    marginBottom: '8px',
                    color: theme.black,
                }}
            >{label}</label>

            <div
                className="input__container"
                css={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <input
                    className="input__block"
                    disabled
                    css={{
                        border: '2px solid transparent',
                        borderRadius: '2px',
                        backgroundColor: theme.lightGrey,
                        padding: '16px',
                        paddingRight: '16px', 
                        fontSize: '16px',
                        height: '52px',
                        width: '100%',
                        fontWeight: 400,
                        lineHeight: '20px',
                        color: theme.black,
                        outline: 'none',
                        '::placeholder': {
                            color: theme.grey,
                        },
                        '&:hover, &:focus': {
                            borderColor: theme.primary,
                        },
                    }}
                    value={value}
                    type="text"
                />
            </div>
        </div>
    )
}

function SubscriptionInformation() {
    const theme = useTheme();
    const { client } = useGetClientInfo();
    const invoices = (client && client.subscriptionsUrl) ? _.sortBy(client.subscriptionsUrl, 'year') : [];

    return (
        <div
            css={{
                padding: '24px 40px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <h3 css={{
                fontWeight: 600,
                fontSize: '24px',
                lineHeight: '29px',
                color: theme.black,
                marginTop: '0px',
                marginBottom: '48px',
            }}>Subscription information</h3>

            <FakeInput
                label="Subscription start date"
                value={client ? getDayMonthYear(client.subscriptionStartDate) : ''}
                css={{
                    marginBottom: '24px',
                    maxWidth: '344px',
                }}
            />

            <FakeInput
                label="Subscription end date"
                value={client ? getDayMonthYear(client.subscriptionEndDate) : ''}
                css={{
                    marginBottom: '24px',
                    maxWidth: '344px',
                }}
            />

            <div css={{ display: 'flex', marginBottom: '24px', }}>
                <FakeInput
                    label="Subscription type"
                    value={client?.accountType === 'PREMIUM' ? 'Premium' : 'Standard'}
                    css={{
                        marginRight: '24px',
                        maxWidth: '344px',
                    }}
                />
                <FakeInput
                    label="Pre-paid amount"
                    value={client?.prepaidAmount}
                    css={{
                        maxWidth: '170px',
                    }}
                />
            </div>
            
            {invoices.length > 0 &&
            <>
            <p
                css={{
                    margin: 0,
                    marginBottom: '16px',
                    fontSize: '14px',
                    lineHeight: '17px',
                    fontWeight: 500,
                    color: theme.black,
                }}
            >Invoices:</p>

            {invoices.map((subscription: any, index: number) => (
                <a
                    key={`subscription_${index}`}
                    href={subscription.url}
                    rel="noreferrer"
                    target="_blank"
                    css={{
                        color: theme.primary,
                        marginBottom: '8px',
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: '20px',
                        '&:hover': {
                            textDecoration: 'underline'
                        }
                    }}
                >Download invoice {subscription.year}</a>
            ))}
            </>
            }
        </div>
    );
}

export default SubscriptionInformation;