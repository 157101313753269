/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { Redirect } from "react-router";
import { useEffect } from "react";
import { useGetClientInfo, useUpdateInvoivingInformation } from "utils/hooks/client.hook";
import Input from "component/Input";
import { useForm } from "react-hook-form";
import Button from "component/Button";
import { CircularProgress } from "@material-ui/core";
import _ from "lodash";

export type InvoicingInformationFormData = {
    companylegalName: string;
    billingAddress: string;
    accountingPhone: string;
    accountingEmail: string;
}

function InvoicingInformation() {
    const theme = useTheme();
    const { client, isLoading, isError } = useGetClientInfo();
    const { mutate: update } = useUpdateInvoivingInformation();
    const { register, reset, handleSubmit, setValue, formState: { dirtyFields, errors } } = useForm<InvoicingInformationFormData>({
        mode: "onChange"
    });

    useEffect(() => {
        if (client) {
            setValue('companylegalName', client.companylegalName);
            setValue('billingAddress', client.billingAddress);
            setValue('accountingPhone', client.accountingPhone);
            setValue('accountingEmail', client.accountingEmail);
        }
    }, [client, setValue]);

    const onSubmit = (data: InvoicingInformationFormData) => {
        update(data);
        reset(data);
    }

    const formDisabled = (!_.isEmpty(dirtyFields) && _.isEmpty(errors));

    if (isLoading)
        return (
            <div css={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <CircularProgress />
            </div>
        )

    if (isError)
        return <Redirect to="/my_account" />

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            css={{
                padding: '24px 40px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <h3 css={{
                fontWeight: 600,
                fontSize: '24px',
                lineHeight: '29px',
                color: theme.black,
                marginTop: '0px',
                marginBottom: '48px',
            }}>Edit invoicing information</h3>

            <Input
                label="Company legal name"
                placeholder=""
                name="companylegalName"
                register={register}
                registerOption={{ required: `Company legal name can't be empty` }}
                error={errors.companylegalName && errors.companylegalName.message}
                css={{
                    marginBottom: '24px',
                    maxWidth: '344px',
                }}
            />

            <Input
                label="Billing address"
                placeholder=""
                name="billingAddress"
                register={register}
                registerOption={{ required: `Billing address can't be empty` }}
                error={errors.billingAddress && errors.billingAddress.message}
                css={{
                    marginBottom: '24px',
                    maxWidth: '344px',
                }}
            />

            <Input
                label="Accounting Dpt phone number"
                placeholder=""
                name="accountingPhone"
                register={register}
                registerOption={{ required: `Accounting Phone can't be empty` }}
                error={errors.accountingPhone && errors.accountingPhone.message}
                css={{
                    marginBottom: '24px',
                    maxWidth: '344px',
                }}
            />

            <Input
                label="Accounting email"
                placeholder=""
                name="accountingEmail"
                register={register}
                registerOption={{ required: `Accounting Email can't be empty` }}
                error={errors.accountingEmail && errors.accountingEmail.message}
                css={{
                    marginBottom: '24px',
                    maxWidth: '344px',
                }}
            />

            <Button
                type="submit"
                disabled={!formDisabled}
                variant="dark"
                css={{
                    maxWidth: '344px',
                }}
            >Save</Button>
        </form>
    );
}

export default InvoicingInformation;