/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import Navbar from 'component/Navbar';
import Step1 from 'pages/carbonFootprint/secondPart/Step1';
import { useAdminGetCarbonSecondPart, useAdminUpdateCarbonSecondPart } from 'utils/hooks/admin.hook';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';
import StepSubstance, { Substance } from 'pages/carbonFootprint/secondPart/StepSubstance';
import StepPlant from 'pages/carbonFootprint/secondPart/StepPlant';
import { Plant } from 'utils/plant';
import FinalStep from './FinalStep';
import UnsavedChangeDialog from 'component/UnsavedChangeDialog';

function AdminCarbonFootprintSecondPart() {
    const theme = useTheme();
    const [step, setStep] = useState(1);
    const containerRef = useRef<HTMLDivElement>(null);
    const params = useParams<{ id: string }>();
    const [valuesChanged, setValuesChanged] = useState(false);
    const [redirectionPath, setRedirectionPath] = useState<string | null>(null);
    const { isLoading, isError, carbon } = useAdminGetCarbonSecondPart(params.id);
    const { mutate: update } = useAdminUpdateCarbonSecondPart(params.id);
    const [state, setState] = useState<any>({
        plants: [],
        substances: [],
    })
    const [currentSubstanceIndex, setCurrentSubstanceIndex] = useState(0);

    const filteredSubstances = _.reject(state.substances, { 'usedInReactionId': null });
    const currentSubstance = filteredSubstances[currentSubstanceIndex];

    const handleStep = (type: "decrement" | "increment") => {
        if (containerRef.current)
            containerRef.current.scrollTo(0, 0);
        setStep(step => type === "decrement" ? step - 1 : step + 1);
    }

    const substanceStepHandlePrevious = () => {
        if (currentSubstanceIndex === 0) {
            handleStep('decrement');
        } else {
            setCurrentSubstanceIndex(index => index - 1);
            handleStep('increment');
        }
    }

    const substanceStepHandleNext = () => {
        handleStep('increment');
    }

    const plantStepHandlePrevious = () => {
        handleStep('decrement');
    }

    const plantStepHandleNext = () => {
        if ((currentSubstanceIndex + 1) >= filteredSubstances.length) {
            handleStep('increment');
        } else {
            setCurrentSubstanceIndex(index => index + 1);
            handleStep('decrement');
        }
    }

    const saveDataSubstance = (data: Substance) => {
        setValuesChanged(true);  
        const parentSubstanceIndex = _.findIndex(state.substances, function(o: any) { return o.productBy.id === currentSubstance.usedInReactionId });
        const currentSubstanceIndex = _.findIndex(state.substances, { 'id': currentSubstance.id });
        const newSubstances = [...state.substances];
        const updatedParentSubstance = {
            ...newSubstances[parentSubstanceIndex],
            molecularWeight: data.parentSubstance.molecularWeight,
            weightPerBatch: data.parentSubstance.weightPerBatch 
        }
        const productBy = {
            ...newSubstances[currentSubstanceIndex].productBy,
            carbonReactionAqueousWastes: data.carbonReactionAqueousWastes,
            carbonReactionOrganicWastes: data.carbonReactionOrganicWastes,
            carbonReactionGasWastes: data.carbonReactionGasWastes,
            carbonReactionCleanings: data.carbonReactionCleanings,
            carbonReactionRawMaterials: data.carbonReactionRawMaterials,
            carbonReactionSolventRecyclings: data.carbonReactionSolventRecyclings,
            packagingMaterial: data.packaging.material,
            packagingType: data.packaging.type,
            packagingWeightPerPackage: data.packaging.weightPerPackage,
            byProductCas: data.byProduct.cas,
            byProductName: data.byProduct.name,
            byProductMolecularWeight: data.byProduct.molecularWeight,
            byProductWeightPerBatch: data.byProduct.weightPerBatch,
            processType: data.processType,
            purificationType: data.purificationType,
        }
        const updatedCurrentSubstance = {
            ...newSubstances[currentSubstanceIndex],
            productBy: productBy,
        }
        newSubstances[currentSubstanceIndex] = updatedCurrentSubstance;
        newSubstances[parentSubstanceIndex] = updatedParentSubstance;
        setState({ ...state, substances: newSubstances });
    }

    const saveDataPlant = (data: Plant, plantId: number) => {
        setValuesChanged(true);
        const currentPlantIndex = _.findIndex(state.plants, { 'id': plantId });
        if (currentPlantIndex === -1)
            return console.error('Unknown plant');

        const mergedPlant = _.merge(state.plants[currentPlantIndex], data);
        const newPlants = [...state.plants];
        newPlants[currentPlantIndex] = mergedPlant;
        
        const parentSubstanceIndex = _.findIndex(state.substances, (o: any) => o.productBy.id === currentSubstance.usedInReactionId);
        const newSubstances = [...state.substances];
        newSubstances[parentSubstanceIndex]['linkedWithPlant'] = plantId;
        setState((state: any) => ({
            substances: [...newSubstances],
            plants: [...state.plants],
        }));
    }

    useEffect(() => {
        if (carbon) setState(carbon);
    }, [carbon]);

    const handleSave = () => { 
        update(state);
    }

    if (isLoading) {
        return (
            <div css={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100vh',
            }}>
                <CircularProgress />
            </div>
        )
    }

    if (isError) {
        return <Redirect to="/admin/panel" />
    }

    return (
        <>
            <UnsavedChangeDialog
                redirectionPath={redirectionPath}
                setRedirectionPath={setRedirectionPath}
                valuesChanged={valuesChanged}
                setValuesChanged={setValuesChanged}
                saveAction={handleSave}
            />
            <div
                css={{
                    flexGrow: 1,
                    backgroundColor: theme.white,
                    color: theme.black,
                    display: 'flex',
                }}
            >
                <Navbar customRedirection={setRedirectionPath} />
                <div
                    ref={containerRef}
                    css={{
                        flexGrow: 1,
                        maxHeight: '100vh',
                        overflowY: 'auto'
                    }}
                >
                    {step === 1 && <Step1 handleSaveBeforeLeave={setRedirectionPath} handleStep={handleStep} /> }
                    {step === 2 && <StepSubstance
                        handlePrevious={substanceStepHandlePrevious}
                        handleNext={substanceStepHandleNext}
                        handleSave={saveDataSubstance}
                        currentSubstance={currentSubstance}
                        currentIndex={currentSubstanceIndex}
                        substances={state.substances}
                    /> }
                    {step === 3 && <StepPlant 
                        handlePrevious={plantStepHandlePrevious}
                        handleNext={plantStepHandleNext}
                        handleSave={saveDataPlant}
                        currentIndex={currentSubstanceIndex}
                        currentSubstance={currentSubstance}
                        substances={state.substances}
                        plants={state.plants}
                    /> }
                    {step === 4 && <FinalStep setValuesChanged={setValuesChanged} handleStep={handleStep} carbon={state} /> }
                </div>
            </div>
        </>
    );
}

export default AdminCarbonFootprintSecondPart;