/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams, Redirect } from "react-router-dom";
import Navbar from "../../Navbar";
import Step1 from "pages/carbonFootprint/firstPart/Step1";
import Step2 from "pages/carbonFootprint/firstPart/Step2";
import Step3 from "pages/carbonFootprint/firstPart/Step3";
import Step4 from "pages/carbonFootprint/firstPart/Step4";
import FinalStep from "./FinalStep";
import { CarbonFootprint, setFormData } from "utils/carbonFootprint";
import {
  useAdminUpdateCarbon,
  useAdminGetCarbon,
} from "utils/hooks/admin.hook";
import CircularProgress from "@material-ui/core/CircularProgress";
import UnsavedChangeDialog from "component/UnsavedChangeDialog";

function AdminCarbonFirstPart() {
  const theme = useTheme();
  const [redirectionPath, setRedirectionPath] = useState<string | null>(null);
  const [step, setStep] = useState(1);
  const [valuesChanged, setValuesChanged] = useState(false);
  const params = useParams<{ id: string }>();
  const { mutate: update } = useAdminUpdateCarbon(params.id);
  const { isLoading, isError, carbon } = useAdminGetCarbon(params.id);
  const methods = useForm<CarbonFootprint>({
    mode: "onChange",
    defaultValues: {
      reactionNumber: 0,
      reactionNumberWithInformation: 0,
      reactionNumberWithoutInformation: 0,
    },
  });
  const { watch, setValue } = methods;

  const handleStep = (type: "decrement" | "increment") => {
    setStep((step) => (type === "decrement" ? step - 1 : step + 1));
  };

  useEffect(() => {
    if (carbon) setFormData(carbon, setValue);
  }, [carbon, setValue]);

  const handleSave = () => {
    const data = watch();
    update(data);
  };

  if (isLoading) {
    return (
      <div
        css={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  if (isError) {
    return <Redirect to="/admin/panel" />;
  }

  return (
    <>
      <UnsavedChangeDialog
        redirectionPath={redirectionPath}
        setRedirectionPath={setRedirectionPath}
        valuesChanged={valuesChanged}
        setValuesChanged={setValuesChanged}
        saveAction={handleSave}
        watch={watch}
      />
      <div
        css={{
          flexGrow: 1,
          backgroundColor: theme.white,
          color: theme.black,
          display: "flex",
        }}
      >
        <Navbar customRedirection={setRedirectionPath} />
        <FormProvider {...methods}>
          <form
            css={{
              flexGrow: 1,
              maxHeight: "100vh",
              overflowY: "auto",
            }}
          >
            {step === 1 && (
              <Step1
                isAdminVersion
                handleSaveBeforeLeave={setRedirectionPath}
                handleStep={handleStep}
              />
            )}
            {step === 2 && <Step2 handleStep={handleStep} />}
            {step === 3 && <Step3 handleStep={handleStep} />}
            {step === 4 && (
              <Step4
                setValuesChanged={setValuesChanged}
                plants={[]}
                handleStep={handleStep}
              />
            )}
            {step === 5 && (
              <FinalStep
                setValuesChanged={setValuesChanged}
                handleStep={handleStep}
              />
            )}
          </form>
        </FormProvider>
      </div>
    </>
  );
}

export default AdminCarbonFirstPart;
