import axios from "axios";
import {
  setAccessToken,
  getAccessToken,
  getRefreshToken,
  logout,
} from "./token";

const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3001";

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error.response.status !== 401 && error.response.status !== 403)
      return Promise.reject(error);

    try {
      const RefreshToken = getRefreshToken();
      if (!RefreshToken) throw new Error("no refresh token");

      const { data } = await axios.post(apiUrl + "/auth/refresh-token", {
        RefreshToken,
      });
      
      const AccessToken = data.AuthenticationResult.AccessToken;
      if (!AccessToken) throw new Error("no access token");

      setAccessToken(AccessToken);
      error.config.__isRetry = true;
      error.config.headers["Authorization"] = `${AccessToken}`;
      return axios.request(error.config);
    } catch (err) {
      logout();
      return Promise.reject(error);
    }
  }
);

export function client(endpoint: string, body?: {}, customHeader?: {}) {
  const AccessToken = getAccessToken();
  const headers: any = { "Content-Type": "application/json" };
  if (AccessToken) {
    headers.Authorization = `${AccessToken}`;
  }
  const config: any = {
    method: body ? "POST" : "GET",
    headers,
    ...customHeader,
  };
  if (body) {
    config.data = body;
  }

  return axios(`${apiUrl}/${endpoint}`, config)
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err));
}
