const accessTokenKey = 'access_token';
const refreshTokenKey = 'refresh_token';

export function getAccessToken() {
    return localStorage.getItem(accessTokenKey) || null;
}

export function getRefreshToken() {
    return localStorage.getItem(refreshTokenKey) || null;
}

export function getToken() {
    return {
        AccessToken: getAccessToken(),
        RefreshToken: getRefreshToken(),
    }
}

export function setAccessToken(AccessToken: string) {
    if (AccessToken && AccessToken.length > 0)
        localStorage.setItem(accessTokenKey, AccessToken);
}

export function setRefreshToken(RefreshToken: string) {
    if (RefreshToken && RefreshToken.length > 0)
        localStorage.setItem(refreshTokenKey, RefreshToken);
}

export function setToken(AccessToken: string, RefreshToken: string) {
    setAccessToken(AccessToken);
    setRefreshToken(RefreshToken);
}

export function checkAuth(): boolean {
    const { AccessToken } = getToken();
    if (AccessToken && AccessToken.length > 0)
      return true;
    return false;
}

export function logout() {
    localStorage.removeItem(refreshTokenKey);
    localStorage.removeItem(accessTokenKey);
}