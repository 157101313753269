/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

export function ControlledCheckbox({
  className,
  label,
  name,
  checked,
  onChange,
}: {
  className?: string;
  label: string | JSX.Element;
  name?: string;
  checked: boolean;
  onChange: (ev: { target: HTMLInputElement }) => void;
}) {
  const theme = useTheme();

  return (
    <label
      className={className}
      css={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        paddingLeft: "35px",
        cursor: "pointer",
        userSelect: "none",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "17px",
        height: "24px",
        color: theme.grey,
        input: {
          position: "absolute",
          opacity: 0,
          cursor: "pointer",
          height: 0,
          width: 0,
        },
        ".checkmark": {
          position: "absolute",
          top: 0,
          left: 0,
          height: "24px",
          width: "24px",
          backgroundColor: theme.white,
          border: `1px solid ${theme.grey}`,
          borderRadius: "2px",
        },
        "&:hover input ~": {
          ".checkmark": {
            borderColor: theme.primary,
          },
        },
        "input:checked ~": {
          ".checkmark": {
            backgroundColor: theme.primary,
            borderColor: theme.primary,
          },
          ".checkmark:after": {
            display: "block",
          },
        },
        ".checkmark:after": {
          content: "''",
          position: "absolute",
          display: "none",
          left: "8px",
          top: "2px",
          width: "6px",
          height: "15px",
          border: "solid white",
          borderWidth: "0 1px 1px 0",
          transform: "rotate(45deg)",
        },
      }}
    >
      {label}
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <span className="checkmark" />
    </label>
  );
}

export function Checkbox({
  title,
  className,
  name,
  register,
  registerOption = {},
  required = false,
  type = "checkbox",
  value,
}: {
  title: string | JSX.Element;
  className?: string;
  name: string;
  register?: any;
  registerOption?: any;
  required?: boolean;
  type?: string;
  value?: any;
}) {
  const theme = useTheme();

  return (
    <label
      className={className}
      css={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        paddingLeft: "35px",
        cursor: "pointer",
        userSelect: "none",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "17px",
        height: "24px",
        color: theme.grey,
        input: {
          position: "absolute",
          opacity: 0,
          cursor: "pointer",
          height: 0,
          width: 0,
        },
        ".checkmark": {
          position: "absolute",
          top: 0,
          left: 0,
          height: "24px",
          width: "24px",
          backgroundColor: theme.white,
          border: `1px solid ${theme.grey}`,
          borderRadius: "2px",
        },
        "&:hover input ~": {
          ".checkmark": {
            borderColor: theme.primary,
          },
        },
        "input:checked ~": {
          ".checkmark": {
            backgroundColor: theme.primary,
            borderColor: theme.primary,
          },
          ".checkmark:after": {
            display: "block",
          },
        },
        ".checkmark:after": {
          content: "''",
          position: "absolute",
          display: "none",
          left: "8px",
          top: "2px",
          width: "6px",
          height: "15px",
          border: "solid white",
          borderWidth: "0 1px 1px 0",
          transform: "rotate(45deg)",
        },
      }}
    >
      {title}
      <input
        type={type}
        value={value}
        {...register(name, { required, ...registerOption })}
      />
      <span className="checkmark" />
    </label>
  );
}
