/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { useHistory, useParams } from "react-router-dom";
import Button from "component/Button";
import { useFormContext } from "react-hook-form";
import { CarbonFootprint } from "utils/carbonFootprint";
import { useCreateOrValidateCarbon } from "utils/hooks/carbon.hook";
import { useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

function FinalStep({
  setValuesChanged,
}: {
  setValuesChanged: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const theme = useTheme();
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { watch } = useFormContext<CarbonFootprint>();
  const {
    isLoading,
    isSuccess,
    isError,
    mutate: createOrValidate,
  } = useCreateOrValidateCarbon(params.id);

  const redirectHome = () => {
    history.push("/");
  };

  useEffect(() => {
    setValuesChanged(false);
    const data = watch();
    createOrValidate(data);
  }, [setValuesChanged, createOrValidate, watch]);

  return (
    <div
      css={{
        flexGrow: 1,
        backgroundColor: theme.white,
        padding: "50px 56px",
        color: theme.black,
        height: "100%",
      }}
    >
      <div
        css={{
          maxWidth: "636px",
          margin: "0px auto",
          marginTop: "200px",
          textAlign: "center",
          color: theme.black,
          h4: {
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "18px",
          },
          p: {
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "20px",
            margin: "40px 0px 48px",
            span: {
              fontWeight: 600,
            },
          },
          button: {
            width: "268px",
            margin: "0px auto",
          },
        }}
      >
        {isLoading && (
          <div
            css={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <CircularProgress />
          </div>
        )}

        {isError && (
          <p
            css={{
              color: theme.error,
            }}
          >
            An error happened
          </p>
        )}

        {isSuccess && (
          <>
            <h4>Thank you!</h4>
            <p>
              You have validated your synthetic route
              <br />
              <br />
              The Ecovamed team will analyze this route and will eventually add
              up-stream steps to ensure this synthetic route is starting from
              commodities with known emission factors
              <br />
              <br />
              If we amend the synthetic route, you will have to check and
              validate it before going to the next steps. We will also provide
              you with an estimation of the cost of this carbon footprint.
            </p>
            <Button variant="dark" onClick={redirectHome}>
              Go back Home
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

export default FinalStep;
