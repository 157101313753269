/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import Button from "component/Button";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams, Redirect } from "react-router-dom";
import Header from "component/StepHeader";
import PlantForm from "./PlantForm";
import Navbar from "component/Navbar";
import Select from "component/Select";
import { useCountries } from "utils/hooks/countries.hook";
import Input from "component/Input";
import { useGetPlant, useUpdatePlant } from "utils/hooks/plant.hook";
import { CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import { parsePlant, setPlant, Plant } from "utils/plant";
import UnsavedChangeDialog from "component/UnsavedChangeDialog";
import { createYearReportOption } from "utils/enums";

function ExistingPlant() {
  const yearReportOption = createYearReportOption();
  const theme = useTheme();
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const [redirectionPath, setRedirectionPath] = useState<string | null>(null);
  const [valuesChanged, setValuesChanged] = useState(false);
  const { countries } = useCountries();
  const { mutate: updatePlant, isLoading: updateIsLoading } = useUpdatePlant(
    params.id
  );
  const { plant, isLoading, isError } = useGetPlant(params.id);
  const methods = useForm<Plant>({
    mode: "onChange",
    defaultValues: {
      refrigerantGases: [{ value: "", type: null }],
      volatileOrganicCompounds: [{ name: "", quantity: "" }],
    },
  });

  const {
    setValue,
    watch,
    trigger,
    control,
    register,
    formState: { errors },
  } = methods;

  const handleSubmit = async () => {
    const isStepValid = await trigger();
    if (isStepValid) {
      const data = parsePlant(watch());
      updatePlant(data);
      history.push("/my_plants");
    }
  };

  const handleSave = () => {
    const data = parsePlant(watch());
    updatePlant(data);
  };

  useEffect(() => {
    if (plant) setPlant(plant, setValue);
  }, [plant, setValue]);

  if (isLoading) {
    return (
      <div
        css={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (isError) {
    return <Redirect to="/my_plants" />;
  }

  return (
    <>
      <UnsavedChangeDialog
        redirectionPath={redirectionPath}
        setRedirectionPath={setRedirectionPath}
        valuesChanged={valuesChanged}
        setValuesChanged={setValuesChanged}
        saveAction={handleSave}
        watch={watch}
      />
      <div
        css={{
          flexGrow: 1,
          backgroundColor: theme.white,
          color: theme.black,
          display: "flex",
        }}
      >
        <Navbar customRedirection={setRedirectionPath} />
        <div
          css={{
            width: "100%",
            maxHeight: "100vh",
            backgroundColor: theme.white,
            padding: "50px 56px",
            color: theme.black,
            display: "grid",
            gridTemplateColumns: "auto 268px",
            columnGap: "30px",
            overflowY: "auto",
          }}
        >
          <div>
            <Header
              title="Modify your plant"
              stepTitle="Manufacturing plant information"
              backName="Back Home"
              backAction={() => history.push("/home")}
            />
            <FormProvider {...methods}>
              <form>
                <div
                  css={{
                    display: "flex",
                    marginBottom: "16px",
                    marginTop: "24px",
                  }}
                >
                  <Controller
                    control={control}
                    name="reportingYear"
                    defaultValue={null}
                    rules={{ required: `Reporting year can't be empty` }}
                    render={({
                      field: { onChange, value, ref },
                      fieldState: { error },
                    }) => (
                      <Select
                        onChange={(data: any) => onChange(data.value)}
                        options={yearReportOption}
                        inputRef={ref}
                        label="Year of reporting*"
                        placeholder="Select a year"
                        getOptionLabel={(option: any) => option.label}
                        value={yearReportOption.find(
                          (el: any) => el.value === value
                        )}
                        error={error?.message}
                        css={{
                          maxWidth: "450px",
                        }}
                      />
                    )}
                  />
                </div>
                <div css={{ display: "flex", marginBottom: "16px" }}>
                  <Controller
                    control={control}
                    name="countryId"
                    defaultValue={null}
                    rules={{ required: `Country can't be empty` }}
                    render={({
                      field: { onChange, value, ref },
                      fieldState: { error },
                    }) => (
                      <Select
                        onChange={(data: any) => onChange(data.id)}
                        options={countries}
                        inputRef={ref}
                        label="Select a country"
                        placeholder="Country*"
                        getOptionLabel={(option: any) => option.name}
                        value={
                          countries &&
                          countries.find((el: any) => el.id === value)
                        }
                        error={error?.message}
                        css={{
                          maxWidth: "450px",
                        }}
                      />
                    )}
                  />
                </div>
                <div css={{ display: "flex", marginBottom: "16px" }}>
                  <Input
                    label="City*"
                    placeholder="City name"
                    register={register}
                    name="city"
                    error={errors.city?.message}
                    registerOption={{
                      required: `City can't be empty`,
                    }}
                    css={{
                      maxWidth: "450px",
                    }}
                  />
                </div>
                <div css={{ display: "flex", marginBottom: "16px" }}>
                  <Input
                    label="Company Name*"
                    placeholder="Company name"
                    register={register}
                    name="companyName"
                    error={errors.companyName?.message}
                    registerOption={{
                      required: `Company name can't be empty`,
                    }}
                    css={{
                      maxWidth: "450px",
                    }}
                  />
                </div>
                <PlantForm />
              </form>
            </FormProvider>
          </div>
          <div
            css={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <Button
              variant="dark"
              disabled={updateIsLoading}
              type="button"
              onClick={() => handleSubmit()}
              css={{
                fontSize: "14px",
              }}
            >
              Validate data for this plant
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExistingPlant;
