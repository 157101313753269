/** @jsxImportSource @emotion/react */

import { useTheme } from "@emotion/react";
import { useState } from "react";

function StatusButton({
    className = '',
    status,
}: {
    className?: string;
    status: 'COMPLETED' | 'ONGOING' | 'ONGOING2' | 'DRAFT' | 'DRAFT2' | 'CHECK' | 'CHECK2';
}) {
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const handleMouseOver = () => setOpen(true);
    const handleMouseLeave = () => setOpen(false);

    let text = 'Draft';
    let hoverText = 'This project requires additional data before being processed';
    if (status === 'ONGOING' || status === 'ONGOING2') {
        text = 'On-going'
        hoverText = 'Ecovamed is working on your project';
    }
    if (status === 'CHECK' || status === 'CHECK2') {
        text = 'Check'
        hoverText = 'Ecovamed amended your data and you have to check and validate them';
    }
    if (status === 'COMPLETED') {
        text = 'Completed'
        hoverText = 'This project is finalized and the report is edited';
    }

    return (
        <div
            css={{ 
                position: 'relative',
            }}
        >
            <span
                onMouseEnter={handleMouseOver}
                onMouseLeave={handleMouseLeave}
                className={className}
                css={{
                    padding: '8px 16px',
                    borderRadius: '8px',
                    textAlign: 'center',
                    backgroundColor: status === 'COMPLETED' ? theme.secondary : theme.notCompleted,
                    color: theme.white,
                    fontWeight: 500,
                    fontSize: '12px',
                    lineHeight: '15px',
                }}
            >
            {text}
            </span>

            <div css={{
                position: 'absolute',
                left: '-50px',
                top: '35px',
                boxShadow: '0px 0px 10px 0px #040C1E14',
                padding: '12px',
                fontSize: '12px',
                lineHeight: '12px',
                width: '220px',
                borderRadius: '2px',
                backgroundColor: theme.white,
                display: open ? 'flex' : 'none',
                minHeight: '48px',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 2,
            }}>{hoverText}</div>
        </div>
    )
}

export default StatusButton;