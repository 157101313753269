/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import Navbar from "component/Navbar";
import { ReactComponent as ArrowBackIcon } from "assets/svg/ArrowBack.svg";
import { ReactComponent as ProfileFilledIcon } from "assets/svg/ProfileFilled.svg";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import GeneralInformation from "./GeneralInformation";
import ChangePassword from "./ChangePassword";
import InvoicingInformation from "./InvoicingInformation";
import { useCheckIsSubUser } from "utils/hooks/user.hook";

const menuList = [
  {
    title: "General information",
  },
  {
    title: "Invoicing information",
  },
  {
    title: "Change password",
  },
];

function Administrator() {
  const theme = useTheme();
  const history = useHistory();
  const [menuIndex, setMenuIndex] = useState(0);

  const { isSubUser, isSuccess } = useCheckIsSubUser();
  useEffect(() => {
    if (isSuccess && isSubUser) history.push("/");
  }, [isSuccess, isSubUser, history]);

  const handleRedirection = () => {
    history.push("/my_account");
  };

  return (
    <div
      css={{
        width: "100%",
        minHeight: "100vh",
        backgroundColor: theme.white,
        color: theme.black,
        display: "flex",
      }}
    >
      <Navbar />
      <div
        css={{
          flexGrow: 1,
          display: "grid",
          gridTemplateColumns: "315px auto",
          gridTemplateRows: "170px auto",
          overflow: "hidden",
        }}
      >
        <div
          css={{
            gridColumn: "span 2",
            padding: "50px 56px 0px",
            boxShadow: "0px 0px 10px rgba(4, 12, 30, 0.08)",
          }}
        >
          <button
            type="button"
            css={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              color: theme.black,
              fontSize: "14px",
              lineHeight: "17px",
              border: "none",
              background: "transparent",
              padding: "0px",
              svg: {
                marginRight: "14px",
              },
            }}
            onClick={handleRedirection}
          >
            <ArrowBackIcon />
            Back to My account
          </button>

          <h2
            css={{
              fontSize: "24px",
              lineHeight: "28px",
              fontWeight: 600,
              marginTop: "26px",
              marginBottom: "0px",
              display: "flex",
              alignItems: "center",
              svg: {
                marginRight: "16px",
              },
            }}
          >
            <ProfileFilledIcon />
            Administrator
          </h2>
        </div>
        <div
          css={{
            borderRight: "1px solid #E8E8E8",
            ".link": {
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
              width: "100%",
              height: "72px",
              padding: "25px 22px 25px 56px",
              borderBottom: "1px solid #E8E8E8",
              p: {
                margin: 0,
                fontWeight: 300,
                fontSize: "18px",
                lineHeight: "22px",
                color: theme.black,
              },
              svg: {
                display: "none",
                transform: "rotate(180deg)",
              },
            },
            ".link--active": {
              p: {
                fontWeight: 600,
              },
              svg: {
                display: "block",
              },
            },
          }}
        >
          {menuList.map((item, index) => (
            <div
              key={index}
              className={index === menuIndex ? "link link--active" : "link"}
              onClick={() => setMenuIndex(index)}
            >
              <p>{item.title}</p>
              <ArrowBackIcon />
            </div>
          ))}
        </div>

        {menuIndex === 0 && <GeneralInformation />}
        {menuIndex === 1 && <InvoicingInformation />}
        {menuIndex === 2 && <ChangePassword />}
      </div>
    </div>
  );
}

export default Administrator;
