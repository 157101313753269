/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import Input from "component/Input";
import NumberInput from "component/NumberInput";
import { InformationPoint } from "component/InformationPoint";
import Button from "component/Button";
import { useFormContext, Controller } from "react-hook-form";
import { CarbonFootprint } from "utils/carbonFootprint";
import Header from "component/StepHeader";
import CurrentStepVisualiser from "../CurrentStepVisualiser";
import Select from "component/Select";
import { ControlledCheckbox } from "component/Checkbox";
import { useEffect, useState } from "react";
import { Country, useCountries } from "utils/hooks/countries.hook";
import { useParams } from "react-router-dom";
import { useCreateOrValidateCarbonWithoutProductionData } from "utils/hooks/carbon.hook";
import { useAdminValidateCarbonWithoutProductionData } from "utils/hooks/admin.hook";

function Step1({
  handleStep,
  handleSaveBeforeLeave,
  isAdminVersion = false,
}: {
  handleStep: (type: "decrement" | "increment") => void;
  handleSaveBeforeLeave: (path: string) => void;
  isAdminVersion?: boolean;
}) {
  const theme = useTheme();
  const params = useParams<{ id: string }>();
  const {
    watch,
    getValues,
    setError,
    setValue,
    register,
    control,
    trigger,
    formState: { errors },
  } = useFormContext<CarbonFootprint>();
  const [haveProductionData, setHaveProductionData] = useState(true);
  const { countries } = useCountries();
  const { mutate, isLoading } = useCreateOrValidateCarbonWithoutProductionData(
    params.id
  );
  const { mutate: validateAsAdmin, isLoading: isLoadingAdmin } = useAdminValidateCarbonWithoutProductionData(
    params.id
  );

  const nameOfSupplier = watch("nameOfSupplier");
  const productionCountry = watch("productionCountry");

  useEffect(() => {
    if (nameOfSupplier || productionCountry) setHaveProductionData(false);
  }, [nameOfSupplier, productionCountry]);

  const handleNext = async () => {
    const isStepValid = await trigger();
    if (isStepValid) {
      const syntheticStepInformationCount = getValues(
        "reactionNumberWithInformation"
      );
      const syntheticStepNoInformationCount = getValues(
        "reactionNumberWithoutInformation"
      );

      if (
        reactionNumber !==
        syntheticStepInformationCount + syntheticStepNoInformationCount
      ) {
        setError("reactionNumber", {
          type: "manual",
          message:
            "The number of synthetic steps must be equal to the sum of the steps with production information and the steps without production information",
        });
      } else {
        if (haveProductionData) {
          handleStep("increment");
        } else {
          mutate(watch());
          console.log(watch());
        }
      }
    }
  };

  const handleNextAdmin = async () => {
    const isStepValid = await trigger();
    if (isStepValid) {
      const syntheticStepInformationCount = getValues(
        "reactionNumberWithInformation"
      );
      const syntheticStepNoInformationCount = getValues(
        "reactionNumberWithoutInformation"
      );

      if (
        reactionNumber !==
        syntheticStepInformationCount + syntheticStepNoInformationCount
      ) {
        setError("reactionNumber", {
          type: "manual",
          message:
            "The number of synthetic steps must be equal to the sum of the steps with production information and the steps without production information",
        });
      } else {
        if (haveProductionData) {
          handleStep("increment");
        } else {
          validateAsAdmin(watch());
        }
      }
    }
  };

  const reactionNumber = watch("reactionNumber");

  return (
    <div
      css={{
        backgroundColor: theme.white,
        padding: "50px 56px",
        color: theme.black,
        display: "grid",
        gridTemplateColumns: "auto 268px",
        columnGap: "30px",
      }}
    >
      <div>
        <Header
          title="Carbon footprint"
          stepName="Step 1"
          stepTitle="Identify your product"
          backName="Back Home"
          backAction={() => handleSaveBeforeLeave("/home")}
        />

        <p
          css={{
            color: theme.darkGrey,
            fontSize: "12px",
            lineHeight: "18px",
            marginTop: "0px",
            marginBottom: "24px",
          }}
        >
          *required fields
        </p>

        <div css={{ display: "flex", marginBottom: "16px" }}>
          <Input
            label="Name*"
            placeholder="Ibuprofen"
            register={register}
            registerOption={{ required: `Name can't be empty` }}
            name="name"
            error={errors.name && errors.name.message}
            css={{
              maxWidth: "400px",
            }}
          />
        </div>

        <div css={{ display: "flex", marginBottom: "32px" }}>
          <Input
            label="CAS number*"
            placeholder="15687-27-1"
            register={register}
            registerOption={{ required: `Cas can't be empty` }}
            name="cas"
            error={errors.cas && errors.cas.message}
            css={{
              maxWidth: "400px",
            }}
          />
          <InformationPoint
            css={{
              marginTop: "40px",
              marginLeft: "16px",
            }}
            text={`CAS Registry Number assigned by the Chemical Abstracts Service`}
          />
        </div>

        <div
          css={{ display: "flex", marginBottom: "12px", alignItems: "center" }}
        >
          <Controller
            control={control}
            name="reactionNumber"
            defaultValue={0}
            render={({ field: { onChange, value } }) => (
              <NumberInput
                minValue={0}
                title="Number of synthetic steps*"
                onChange={onChange}
                value={value}
              />
            )}
          />

          <InformationPoint
            css={{
              marginLeft: "16px",
            }}
            text={`All steps to manufacture the Product from chemical commodities (list). If unknown, mention your best guess and we will amend it`}
          />
        </div>

        <div
          css={{ display: "flex", marginBottom: "16px", alignItems: "center" }}
        >
          <Controller
            control={control}
            name="reactionNumberWithInformation"
            defaultValue={0}
            render={({ field: { onChange, value } }) => (
              <NumberInput
                minValue={0}
                maxValue={reactionNumber}
                disabled={reactionNumber <= 0}
                title="Among which you have production information*"
                onChange={onChange}
                value={value}
                css={{
                  maxWidth: "580px",
                  marginLeft: "16px",
                  ".title": {
                    display: "list-item",
                    listStyle: "disc",
                    fontWeight: 400,
                  },
                }}
              />
            )}
          />
        </div>

        <div
          css={{ display: "flex", marginBottom: "32px", alignItems: "center" }}
        >
          <Controller
            control={control}
            name="reactionNumberWithoutInformation"
            defaultValue={0}
            render={({ field: { onChange, value } }) => (
              <NumberInput
                minValue={0}
                maxValue={reactionNumber}
                disabled={reactionNumber <= 0}
                title="Among which you do not have production information*"
                onChange={onChange}
                value={value}
                css={{
                  maxWidth: "580px",
                  marginLeft: "16px",
                  ".title": {
                    display: "list-item",
                    listStyle: "disc",
                    fontWeight: 400,
                  },
                }}
              />
            )}
          />
        </div>

        {errors.reactionNumber && (
          <p
            css={{
              marginTop: "-14px",
              marginBottom: "32px",
              color: theme.error,
              fontSize: "12px",
              lineHeight: "15px",
            }}
          >
            {errors.reactionNumber.message}
          </p>
        )}

        <div css={{ marginBottom: "30px", display: "flex" }}>
          <div css={{ display: "flex", flexDirection: "column" }}>
            <p css={{ marginTop: 0 }}>
              Do you have the production data for this product?
            </p>
            <div css={{ display: "flex" }}>
              <ControlledCheckbox
                name="haveProductionData"
                checked={haveProductionData === true}
                onChange={() => setHaveProductionData(true)}
                label="Yes"
                css={{
                  marginRight: "20px",
                  color: "black",
                }}
              />
              <ControlledCheckbox
                name="haveProductionData"
                checked={haveProductionData === false}
                onChange={() => {
                  setHaveProductionData(false);
                  setValue("nameOfSupplier", "");
                  setValue("productionCountry", null);
                }}
                label="No"
                css={{
                  color: "black",
                }}
              />
            </div>
          </div>
          <InformationPoint
            css={{
              marginLeft: "16px",
            }}
            text={`Production data are the synthesis route, the bill of materials and some information on the plant (energy, wastes...). If you want to get the carbon footprint of a product you buy, please select No`}
          />
        </div>

        {haveProductionData === false && (
          <>
            <div css={{ display: "flex", marginBottom: "16px" }}>
              <Input
                label="Name of the supplier*"
                placeholder=""
                register={register}
                registerOption={{
                  required: `Name of the supplier can't be empty`,
                }}
                name="nameOfSupplier"
                error={errors.nameOfSupplier && errors.nameOfSupplier.message}
                css={{
                  maxWidth: "400px",
                }}
              />
            </div>

            <div css={{ display: "flex" }}>
              <Controller
                control={control}
                name="productionCountry"
                rules={{ required: `Country of production can't be empty` }}
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <Select
                    onChange={(data: any) => onChange(data ? data.id : null)}
                    options={countries}
                    inputRef={ref}
                    label="Country of production for this supplier*"
                    placeholder="Select a country"
                    getOptionLabel={(option: any) => option.name}
                    value={
                      countries &&
                      countries.find((el: Country) => el.id === value)
                    }
                    error={error?.message}
                    css={{
                      maxWidth: "400px",
                    }}
                  />
                )}
              />
            </div>
          </>
        )}
      </div>

      <div>
        <CurrentStepVisualiser step={1} />
        <Button
          disabled={isLoading || isLoadingAdmin}
          variant="dark"
          onClick={isAdminVersion ? handleNextAdmin : handleNext}
        >
          {haveProductionData ? "Next step" : "Validate"}
        </Button>
      </div>
    </div>
  );
}

export default Step1;
