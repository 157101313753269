/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import _ from "lodash";
import Navbar from "component/Navbar";
import DeleteDialog from "component/DeleteDialog";
import Button from "component/Button";
import { useHistory } from "react-router-dom";
import { ReactComponent as ArrowBackIcon } from "assets/svg/ArrowBack.svg";
import { ReactComponent as AddCircleIcon } from "assets/svg/AddCircle.svg";
import { ReactComponent as TrashIcon } from "assets/svg/Trash.svg";
import { useEffect, useState } from "react";
import { useCheckIsSubUser } from "utils/hooks/user.hook";
import { useGetClientInfo } from "utils/hooks/client.hook";
import { useDeleteSubUser } from "utils/hooks/auth.hook";

function UserList() {
  const theme = useTheme();
  const history = useHistory();
  const [deleteAction, setDeleteAction] = useState<null | (() => void)>(null);
  const { isSubUser, isSuccess: isSuccessCheckSubUser } = useCheckIsSubUser();
  const { client } = useGetClientInfo();
  const { mutate: deleteSubUser } = useDeleteSubUser();

  useEffect(() => {
    if (isSuccessCheckSubUser && isSubUser) history.push("/");
  }, [isSuccessCheckSubUser, isSubUser, history]);

  const subUsers = _.filter(
    client?.users ? client.users : [],
    (o) => o.isSubUser
  );

  const handleDeleteSubUser = (subUserId: number) => {
    setDeleteAction(() => () => deleteSubUser({ subUserId }));
  };

  const close = () => setDeleteAction(null);

  return (
    <>
      <DeleteDialog
        close={close}
        isOpen={deleteAction ? true : false}
        action={deleteAction}
        title="Are you sure you want to delete this user ?"
        subtitle=""
      />
      <div
        css={{
          width: "100%",
          minHeight: "100vh",
          backgroundColor: theme.white,
          color: theme.black,
          display: "flex",
        }}
      >
        <Navbar />
        <div
          css={{
            flexGrow: 1,
            backgroundColor: theme.white,
            padding: "50px 56px",
            display: "flex",
            flexDirection: "column",
            maxHeight: "100vh",
            overflowY: "auto",
            maxWidth: "1100px",
          }}
        >
          <button
            type="button"
            css={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              color: theme.black,
              fontSize: "14px",
              lineHeight: "17px",
              border: "none",
              background: "transparent",
              padding: "0px",
              svg: {
                marginRight: "14px",
              },
            }}
            onClick={() => history.push("/my_account")}
          >
            <ArrowBackIcon />
            Back Home
          </button>

          <h2
            css={{
              fontSize: "24px",
              lineHeight: "28px",
              fontWeight: 600,
              marginTop: "26px",
              marginBottom: "40px",
            }}
          >
            My User list
          </h2>

          {/* <p css={{
                        color: theme.black, 
                        fontSize: '14px',
                        lineHeight: '18px',
                        marginTop: '0px',
                        marginBottom: '40px'
                    }}
                >Here you will find a list of all the account</p> */}

          {true && (
            <table
              css={{
                textAlign: "center",
                borderCollapse: "collapse",
                "tr, th, td": {
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "17px",
                },
                "tr th, tr td": {
                  border: `1px solid ${theme.primary}`,
                  height: "50px",
                  color: theme.black,
                },
                "tr th": {
                  backgroundColor: theme.primary,
                  color: theme.white,
                  width: "33%",
                },
                "tr td a": {
                  color: theme.black,
                  fontWeight: 600,
                  textDecoration: "underline",
                },
              }}
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Function</th>
                </tr>
              </thead>
              <tbody>
                {subUsers.map((subUser: any) => (
                  <tr key={`subUser_${subUser.id}`}>
                    <td>{subUser.name}</td>
                    <td>{subUser.email}</td>
                    <td>{subUser.function}</td>
                    <td
                      onClick={() => handleDeleteSubUser(subUser.id)}
                      css={{
                        cursor: "pointer",
                        border: "none !important",
                        padding: "0px 4px",
                      }}
                    >
                      <TrashIcon />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          <Button
            onClick={() => history.push("/my_account/setup_new_user")}
            variant="dark"
            icon={<AddCircleIcon />}
            css={{
              margin: "0px auto",
              marginTop: "100px",
              maxWidth: "330px",
            }}
          >
            Create new user
          </Button>
        </div>
      </div>
    </>
  );
}

export default UserList;
