/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import Button from 'component/Button';
import { useCreateOrValidateOrigin } from 'utils/hooks/origin.hook';
import { DrugOrigin } from 'utils/drugOrigin';
import CircularProgress from '@material-ui/core/CircularProgress';

function FinalStep({ setValuesChanged }: {
    setValuesChanged: React.Dispatch<React.SetStateAction<boolean>>
}) {
    const theme = useTheme();
    const history = useHistory();
    const params = useParams<{ id: string }>();
    const { watch } = useFormContext<DrugOrigin>();
    const { isLoading, isSuccess, isError, mutate: createOrValidate } = useCreateOrValidateOrigin(params.id);

    const redirectHome = () => {
        history.push('/');
    }

    useEffect(() => {
        createOrValidate(watch());
        setValuesChanged(false);
    }, [setValuesChanged, createOrValidate, watch]);

    return (
        <div
            css={{
                flexGrow: 1,
                backgroundColor: theme.white,
                padding: '50px 56px',
                color: theme.black,
                height: '100%',
            }}
        >
            <div 
                css={{
                    maxWidth: '636px',
                    margin: '0px auto',
                    marginTop: '200px',
                    textAlign: 'center',
                    color: theme.black,
                    'h4': {
                        fontWeight: 600,
                        fontSize: '18px',
                        lineHeight: '18px',
                    },
                    'p': {
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '20px',
                        margin: '48px 0px 72px'
                    },
                    'button': {
                        width: '268px',
                        margin: '0px auto',
                    }
                }}
            >
                {isLoading &&
                <div css={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }}>
                    <CircularProgress />
                </div>
                }

                {isError &&
                <p css={{
                    color: theme.error
                }}>An error happened</p>
                }

                {isSuccess &&
                <>
                <h4>Thank you!</h4>
                <p>The percentage of European origin of your drug product will be evaluated in the coming days after reviewing all your data. We may have to ask additional questions before issuing the label of origin.</p>
                <Button variant="dark" onClick={redirectHome}>Go back Home</Button>
                </>}
            </div>
        </div>
    );
}

export default FinalStep;