/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';

type PencentageInputProps = {
    placeholder: string;
    name: string;
    error?: string;
    className?: string;
    defaultValue?: string;
    disabled?: boolean;
    register: any;
    registerOption?: any;
    required?: boolean;
}

export default function PencentageInput({
    placeholder,
    name,
    error = "",
    disabled = false,
    className,
    defaultValue,
    register,
    registerOption = {},
    required = false,
    }: PencentageInputProps
) {
    const theme = useTheme();

    return (
        <div
            css={{
                display: "flex",
                flexDirection: "column",
                width: '80px',
                minWidth: '80px',
            }}
            className={className}
        >
            <div
                className="input__container"
                css={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    '.percentage': {
                        userSelect: 'none',
                        position: 'absolute',
                        right: '16px',
                        color: theme.black,
                        fontWeight: 500,
                    }
                }}
            >
                <input
                    className="input__block"
                    css={{
                        border: '2px solid transparent',
                        borderRadius: '2px',
                        backgroundColor: theme.lightGrey,
                        padding: '16px',
                        fontSize: '16px',
                        height: '52px',
                        width: '100%',
                        fontWeight: 400,
                        lineHeight: '20px',
                        color: theme.black,
                        outline: 'none',
                        '::placeholder': {
                            color: theme.grey,
                        },
                        '&:hover, &:focus': {
                            borderColor: theme.primary,
                        },
                    }}
                    {...register(name, { required, ...registerOption })}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    maxLength={3}
                    disabled={disabled}
                    type="text"
                />
                <span className="percentage">%</span>
            </div>
            {error &&
                <p css={{
                    marginTop: '4px',
                    marginBottom: '0px',
                    color: theme.error,
                    fontSize: '12px',
                    lineHeight: '15px',
                }}>{error}</p>
            }
        </div>
    );
}