/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import Navbar from 'component/Navbar';
import Button from 'component/Button';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as ArrowBackIcon } from 'assets/svg/ArrowBack.svg';
import { ReactComponent as AddCircleIcon } from 'assets/svg/AddCircle.svg';
import StatusButton from 'component/StatusButton';
import DeleteDialog from 'component/DeleteDialog';
import { useDeleteCarbon, useGetCarbons } from 'utils/hooks/carbon.hook';
import { ReactComponent as TrashIcon } from 'assets/svg/Trash.svg';
import { useState } from 'react';

function MyFootprints() {
    const theme = useTheme();
    const history = useHistory();
    const [deleteAction, setDeleteAction] = useState<null | (() => void)>(null);
    const { carbons } = useGetCarbons();
    const { mutate: deleteCarbon } = useDeleteCarbon();

    const handleRedirection = () => {
        history.push('/home');
    }

    const redirectToCarbonFootprint = () => {
        history.push('/carbon_footprint/first_part/new');
    }

    const handleDeleteCarbon = (carbonId: number) => {
        setDeleteAction(() => () => deleteCarbon(carbonId));
    }

    const close = () => setDeleteAction(null);

    return (
        <>
        <DeleteDialog 
            close={close}
            isOpen={deleteAction ? true : false}
            action={deleteAction}
        />
        <div
            css={{
                width: '100%',
                minHeight: '100vh',
                backgroundColor: theme.white,
                color: theme.black,
                display: 'flex',
            }}
        >
            <Navbar />
            <div
                css={{
                    flexGrow: 1,
                    backgroundColor: theme.white,
                    padding: '50px 56px',
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight: '100vh',
                    overflowY: 'auto',
                    maxWidth: '1100px',
                }}
            >
                <button
                    type="button"
                    css={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        color: theme.black,
                        fontSize: '14px',
                        lineHeight: '17px',
                        border: 'none',
                        background: 'transparent',
                        padding: '0px',
                        'svg': {
                            marginRight: '14px'
                        }
                    }}
                    onClick={handleRedirection}
                >
                    <ArrowBackIcon />
                    Back Home
                </button>

                <h2 css={{
                        fontSize: '24px', 
                        lineHeight: '28px', 
                        fontWeight: 600, 
                        marginTop: '26px', 
                        marginBottom: '16px'
                    }}
                >My Footprints</h2>

                <p css={{
                        color: theme.black, 
                        fontSize: '14px',
                        lineHeight: '18px',
                        marginTop: '0px',
                        marginBottom: '40px'
                    }}
                >Here you will find a list of all your carbon footprints</p>

                {carbons.length > 0 &&
                <table
                    css={{
                        textAlign: 'center',
                        borderCollapse: 'collapse',
                        'tr, th, td': {
                            fontWeight: 500,
                            fontSize: '14px',
                            lineHeight: '17px',
                        },
                        'tr th, tr td': {
                            border: `1px solid ${theme.primary}`,
                            height: '50px',
                            color: theme.black,
                        },
                        'tr th': {
                            backgroundColor: theme.primary,
                            color: theme.white,
                        },
                        'tr td a': {
                            color: theme.black,
                            fontWeight: 600,
                            textDecoration: 'underline',
                        },
                        '.status, .document, .access': {
                            width: '120px',
                        }
                    }}
                >
                    <thead>
                        <tr>
                            <th>Name of product</th>
                            <th>Type</th>
                            <th className="status">Status</th>
                            <th className="document">Document</th>
                            <th className="access">Access</th>
                        </tr>
                    </thead>
                    <tbody>
                        {carbons.map((carbon: any) => (
                            <tr key={`origin_${carbon.id}`}>
                                <td>{carbon.name}</td>
                                <td>Carbon footprint</td>
                                <td><StatusButton status={carbon.status} /></td>
                                <td>{carbon.reportUrl ?
                                    <a href={carbon.reportUrl} rel="noreferrer" target="_blank">report</a> :
                                    '-'}
                                </td>
                                <td>
                                    {(carbon.status === 'DRAFT' || carbon.status === 'CHECK') ?
                                        <Link to={`/carbon_footprint/first_part/${carbon.id}`}>edit</Link> :
                                            (carbon.status === 'DRAFT2' || carbon.status === 'CHECK2') ?
                                            <Link to={`/carbon_footprint/second_part/${carbon.id}`}>edit</Link> :
                                            '-'
                                    }
                                </td>
                                {(carbon.status === 'DRAFT' || carbon.status === 'DRAFT2') &&
                                    <td 
                                        onClick={() => handleDeleteCarbon(carbon.id)}
                                        css={{
                                            cursor: 'pointer',
                                            border: 'none !important',
                                            padding: '0px 4px',
                                        }}
                                    ><TrashIcon /></td>
                                }
                            </tr>
                        ))}
                    </tbody>
                </table>
                }

                <Button
                    onClick={redirectToCarbonFootprint}
                    variant="dark"
                    icon={<AddCircleIcon />}
                    css={{
                        margin: '0px auto',
                        marginTop: '100px',
                        maxWidth: '330px',
                    }}
                >New carbon footprint</Button>
            </div>
        </div>
        </>
    );
}

export default MyFootprints;