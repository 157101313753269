/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { useRouteMatch, useHistory } from "react-router-dom";
import Logo from "assets/Logo.png";
import { ReactComponent as HomeIcon } from "assets/svg/Home.svg";
import { ReactComponent as NotesIcon } from "assets/svg/Notes.svg";
import { ReactComponent as OriginLabelIcon } from "assets/svg/OriginLabel.svg";
import { ReactComponent as CO2Icon } from "assets/svg/CO2.svg";
import { ReactComponent as PlantsIcon } from "assets/svg/Plants.svg";
import { ReactComponent as ProfileIcon } from "assets/svg/Profile.svg";
import { ReactComponent as LogoutIcon } from "assets/svg/Logout.svg";
import { useCheckIsSubUser } from "utils/hooks/user.hook";

function CustomLink({
  icon,
  title,
  to,
  matchingPaths,
  handleRedirection,
}: {
  icon: JSX.Element;
  title: string;
  to: string;
  matchingPaths: string[];
  handleRedirection: (path: string) => void;
}) {
  const currentPage = useRouteMatch(matchingPaths);
  const theme = useTheme();
  const defaultLinkCss = css({
    backgroundColor: theme.primary,
    color: theme.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    height: "48px",
    border: "none",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "17px",
    padding: "0px 20px",
    cursor: "pointer",
    marginBottom: "24px",
    svg: {
      width: "28px",
      marginRight: "10px",
      path: {
        stroke: theme.white,
      },
    },
  });

  if (currentPage)
    return (
      <button onClick={() => handleRedirection(to)} css={defaultLinkCss}>
        {icon}
        {title}
      </button>
    );
  return (
    <button
      onClick={() => handleRedirection(to)}
      css={[
        defaultLinkCss,
        {
          backgroundColor: "transparent",
          color: theme.black,
          fontWeight: 500,
          "&:last-of-type": {
            marginTop: "auto",
            paddingTop: "32px",
            borderTop: "1px solid #F6F5F6",
          },
          "&:hover": {
            color: theme.primary,
            fontWeight: 600,
            svg: {
              path: {
                stroke: theme.primary,
              },
            },
          },
          svg: {
            path: {
              stroke: theme.black,
            },
          },
        },
      ]}
    >
      {icon}
      {title}
    </button>
  );
}

function Navbar({ customRedirection }: { customRedirection?: any }) {
  const history = useHistory();
  const { isSubUser, isSuccess } = useCheckIsSubUser();
  const handleRedirection = (path: string) => {
    if (customRedirection) customRedirection(path);
    else history.push(path);
  };

  return (
    <nav
      css={{
        zIndex: 2,
        minWidth: "360px",
        height: "100vh",
        padding: "80px 60px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxShadow: "0px 0px 10px rgba(4, 12, 30, 0.08)",
      }}
    >
      <img css={{ marginBottom: "68px" }} src={Logo} alt="Logo" />

      <CustomLink
        title="Home"
        icon={<HomeIcon />}
        to="/home"
        handleRedirection={handleRedirection}
        matchingPaths={["/home"]}
      />

      <CustomLink
        title="My Projects"
        icon={<NotesIcon />}
        to="/my_projects"
        handleRedirection={handleRedirection}
        matchingPaths={["/my_projects"]}
      />

      <CustomLink
        title="My Origin labels"
        icon={<OriginLabelIcon />}
        to="/my_labels"
        handleRedirection={handleRedirection}
        matchingPaths={["/my_labels"]}
      />

      <CustomLink
        title="My Footprints"
        icon={<CO2Icon />}
        to="/my_footprints"
        handleRedirection={handleRedirection}
        matchingPaths={["/my_footprints"]}
      />

      <CustomLink
        title="My Plants"
        icon={<PlantsIcon />}
        to="/my_plants"
        handleRedirection={handleRedirection}
        matchingPaths={["/my_plants"]}
      />

      {isSuccess && isSubUser === false && (
        <CustomLink
          title="My Account"
          icon={<ProfileIcon />}
          to="/my_account"
          handleRedirection={handleRedirection}
          matchingPaths={["/my_account"]}
        />
      )}

      <CustomLink
        title="Log out"
        icon={<LogoutIcon />}
        to="/logout"
        handleRedirection={handleRedirection}
        matchingPaths={["/logout"]}
      />
    </nav>
  );
}

export default Navbar;
