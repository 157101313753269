import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API } from 'utils/drugSubstance';
import { client } from 'utils/client';

export function useGetSubstances() {
    const { data: substances , isLoading, isError, isSuccess } = useQuery(
        'substances',
        () => client(`substance`)
    );
    return {
        substances: substances ?? [],
        isLoading,
        isError,
        isSuccess,
    }
}

export function useGetSubstancesCompleted() {
    const { data, isLoading, isError, isSuccess } = useQuery(
        'substances_completed',
        () => client(`substance/completed`)
    );
    return {
        substances: data ?? [],
        isLoading,
        isError,
        isSuccess,
    }
}

export function useGetSubstance(id: string) {
    const { data: substance , isLoading, isError, isSuccess } = useQuery(
        ['substance', id],
        () => id ? client(`substance/${id}`) : null
    );
    return {
        substance: substance ?? null,
        isLoading,
        isError,
        isSuccess,
    }
}

export function useCreateOrValidateSubstance(id: string) {
    const queryClient = useQueryClient();
    const { mutate, ...rest } = useMutation(
        (state: API) => id ? client(`substance/validate/${id}`, state) : client(`substance/create`, state),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('substances');
                if (id) queryClient.invalidateQueries(['substance', id]);
            }
        }
    );
    return { mutate, ...rest };
}

export function useCreateNotCompleteSubstance() {
    const queryClient = useQueryClient();
    const { mutate, ...rest } = useMutation(
        (state: API) => client(`substance/draft/create`, state),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('substances');
            }
        }
    );
    return { mutate, ...rest };
}

export function useUpdateSubstance(id: string) {
    const queryClient = useQueryClient();
    const { mutate, ...rest } = useMutation(
        (state: API) => client(`substance/update/${id}`, state),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('substances');
            }
        }
    );
    return { mutate, ...rest };
}

export function useDeleteSubstance() {
    const queryClient = useQueryClient();
    const { mutate, ...rest } = useMutation(
        (id: number) => client(`substance/${id}`, {}, {
            method: 'DELETE'
        }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('substances');
            }
        }
    );
    return { mutate, ...rest };
}
