/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import Button from "component/Button";
import { useFormContext } from "react-hook-form";
import { CarbonFootprint } from "utils/carbonFootprint";
import { useAdminValidateCarbon } from "utils/hooks/admin.hook";
import { Redirect, useParams } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Header from "component/StepHeader";
import { useEffect } from "react";

function FinalStep({
  handleStep,
  setValuesChanged,
}: {
  handleStep: (type: "decrement" | "increment") => void;
  setValuesChanged: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const theme = useTheme();
  const params = useParams<{ id: string }>();
  const { watch } = useFormContext<CarbonFootprint>();
  const {
    isLoading,
    isSuccess,
    isIdle,
    mutate: validateCarbon,
  } = useAdminValidateCarbon(params.id);

  const handleValidateCheck = () => {
    const data = watch();
    validateCarbon({ ...data, status: "CHECK" });
  };

  const handleValidateFirstPartCompleted = () => {
    const data = watch();
    validateCarbon({ ...data, status: "DRAFT2" });
  };

  useEffect(() => {
    setValuesChanged(false);
  }, [setValuesChanged]);

  if (isSuccess) {
    return <Redirect to="/admin" />;
  }

  if (isLoading) {
    return (
      <div
        css={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div
      css={{
        backgroundColor: theme.white,
        padding: "50px 56px",
        color: theme.black,
        display: "grid",
        gridTemplateColumns: "auto 268px",
        columnGap: "30px",
      }}
    >
      <div>
        <Header
          title="Carbon footprint"
          stepName=""
          stepTitle="Validation Step"
          backName="Previous step"
          backAction={() => handleStep("decrement")}
        />
        <div
          css={{
            marginTop: "80px",
          }}
        >
          <p>
            If no modifications (or no important modification for the client)
            you can allow the client to access the second part of carbon
            footprint
          </p>
          {isIdle && (
            <Button variant="dark" onClick={handleValidateFirstPartCompleted}>
              Save first part as completed
            </Button>
          )}
        </div>
        <div
          css={{
            marginTop: "40px",
          }}
        >
          <p>If the client has to check the ecovamed's modification</p>
          {isIdle && (
            <Button variant="dark" onClick={handleValidateCheck}>
              Save as Check
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default FinalStep;
