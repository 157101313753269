/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import Input from "component/Input";
import { Checkbox } from "component/Checkbox";
import Button from "component/Button";
import { Link, useHistory } from "react-router-dom";
import Logo from "assets/Logo.png";
import BackgroundImage from "assets/background.jpg";
import { useLogin } from "utils/hooks/auth.hook";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import { useState } from "react";
import CreatePassword from "./CreatePassword";

export type LoginFormData = {
  email: string;
  password: string;
  keepLogged: boolean;
};

function Login() {
  const theme = useTheme();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [challengeSession, setChallengeSession] = useState<string | null>(null);
  const { mutate, isIdle, isLoading, isError } = useLogin(
    setErrorMessage,
    setChallengeSession
  );
  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid },
  } = useForm<LoginFormData>({
    mode: "onChange",
  });

  const onSubmit = (data: LoginFormData) => {
    mutate(data);
  };

  if (challengeSession) {
    return <CreatePassword email={watch("email")} challengeSession={challengeSession} />;
  }

  return (
    <div
      css={{
        width: "100%",
        height: "100vh",
        backgroundColor: theme.white,
        color: theme.black,
        display: "flex",
      }}
    >
      <form
        css={{
          width: "504px",
          padding: "80px",
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <img
          src={Logo}
          alt="Logo"
          onClick={() => {
            history.push("/login");
          }}
          css={{
            height: "66px",
            width: "200px",
            cursor: "pointer",
          }}
        />

        <h1
          css={{
            fontSize: "24px",
            lineHeight: "28px",
            fontWeight: 600,
            marginTop: "40px",
            marginBottom: "0px",
          }}
        >
          Welcome to Ecovamed
        </h1>

        <h4
          css={{
            fontSize: "14px",
            lineHeight: "18px",
            fontWeight: "normal",
            color: theme.darkGrey,
            marginTop: "24px",
            marginBottom: "40px",
          }}
        >
          Log in with the data that you entered during your registration
        </h4>

        <Input
          label="Email"
          placeholder="Email"
          name="email"
          register={register}
          css={{
            marginBottom: "24px",
          }}
        />

        <Input
          label="Password"
          placeholder="Password"
          name="password"
          type="password"
          register={register}
          css={{
            marginBottom: "32px",
          }}
        />

        <Checkbox
          title="Keep me logged in"
          register={register}
          name="keepLogged"
        />

        {errorMessage && (
          <p
            css={{
              color: theme.error,
              fontSize: "14px",
              marginBottom: "-30px",
            }}
          >
            {errorMessage}
          </p>
        )}

        {(isIdle || isError) && (
          <Button
            disabled={!isValid}
            type="submit"
            css={{
              marginTop: "48px",
              marginBottom: "12px",
            }}
          >
            Log In
          </Button>
        )}

        {isLoading && (
          <CircularProgress
            css={{
              margin: "auto",
              marginTop: "48px",
              marginBottom: "12px",
              circle: {
                stroke: theme.primary,
              },
            }}
          />
        )}

        <Link
          to="forgot_password"
          css={{
            textAlign: "center",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "17px",
            color: theme.black,
            display: "block",
            marginBottom: "80px",
          }}
        >
          Forgot password ?
        </Link>

        <p
          css={{
            marginTop: "50px",
            textAlign: "center",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "17px",
            a: {
              textDecoration: "underline",
              color: theme.black,
              fontWeight: 600,
            },
          }}
        >
          Don’t have an account yet ? <Link to="register">Register here</Link>
        </p>
      </form>
      <div
        css={{
          flexGrow: 1,
          backgroundSize: "cover",
          backgroundRepeat: "none",
          backgroundImage: `url(${BackgroundImage})`,
        }}
      />
    </div>
  );
}

export default Login;
