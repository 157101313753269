/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import CurrentStepVisualiser from './CurrentStepVisualiser';
import Button from 'component/Button';
import Header from 'component/StepHeader';

function Step3({ handleStep }: {
    handleStep: (type: "decrement" | "increment") => void;
}) {
    const theme = useTheme();

    return (
        <div
            css={{
                backgroundColor: theme.white,
                padding: '50px 56px',
                color: theme.black,
                display: 'grid',
                gridTemplateColumns: 'auto 268px',
                columnGap: '30px',
            }}
        >
            <div>
                <Header
                    title="Label of origin for a drug product"
                    stepName="Before step 3"
                    stepTitle="Explanation of the methodology"
                    backName="Previous step"
                    backAction={() => handleStep('decrement')}
                />

                <p
                    css={{
                        marginTop: '40px',
                        maxWidth: '636px',
                        color: theme.black,
                        fontSize: '14px',
                        lineHeight: '20px',
                        fontWeight: 400,
                        'span': {
                            fontWeight: 600,
                        },
                        'a': {
                            color: theme.black,
                            fontWeight: 600,
                            textDecoration: 'underline'
                        }
                    }}
                >
                    <span>The next step is to build the synthetic route of the drug substance</span>, starting from the drug substance itself and adding step by step all precursors (or intermediates) that are needed to manufacture the drug substance from the simpliest chemicals (<a target="_blank" rel="noreferrer" href="https://ecovamed-front-document.s3.eu-west-3.amazonaws.com/commodities">commodities</a>).<br/><br/> 
                    Reagents and solvents do not need to be mentioned, but only precursors whose atoms are present in the drug substance or which are used as protecting groups.<br/><br/>
                    For Biologic and Natural APIs, you have to mention all manufaturing steps instead of chemical precursors.<br/><br/>
                    Please <a target="_blank" rel="noreferrer" href="https://ecovamed-front-document.s3.eu-west-3.amazonaws.com/synthetic_route_tree_origin">find here</a> an exemple on the way to build such a synthetic route for Ibuprofen.<br/><br/>
                    For each product (drug substance or precursor), you will have to enter origin data such as the name of the manufacturer, the production site country and the share supplied from this production site if the product is made from several plants. The share corresponds to the percentage of the volume of the last calendar year.<br/><br/>
                    Ecovamed will cross-check all data and may request additional information for some precursors, or add additional precursors to complete the synthetic route.
                </p>
            </div>
            <div>
                <CurrentStepVisualiser step={3} />
                <Button
                    variant="dark"
                    disabled={false}
                    onClick={() => handleStep('increment')}
                >Got it</Button>
            </div>
        </div>
    );
}

export default Step3;