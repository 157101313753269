/** @jsxImportSource @emotion/react */
import { useTheme, css } from "@emotion/react";
import CurrentStepVisualiser from "../CurrentStepVisualiser";
import Header from "component/StepHeader";
import Button from "component/Button";
import { ReactComponent as AddIcon } from "assets/svg/Add.svg";
import SelectInTable from "component/SelectInTable";
import {
  Controller,
  useFieldArray,
  useForm,
  UseFormSetError,
} from "react-hook-form";
import { useEffect } from "react";
import {
  AqueousTreatmentTypeOptions,
  DistanceOptions,
  GasTreatmentTypeOptions,
  OrganicTreatmentTypeOptions,
  ProcessTypeOptions,
  PurificationTypeOptions,
  RawMaterialProductionPlaceOptions,
} from "utils/enums";
import { decimalNumberRegex } from "utils/regex";
import _ from "lodash";

const tableCss = css({
  width: "100%",
  borderCollapse: "collapse",
  ".header": {
    height: "50px",
    backgroundColor: "#2B6FD6",
    color: "#FFFFFF",
    border: `1px solid #2B6FD6`,
    th: {
      lineHeight: "20px",
      fontSize: "16px",
      fontWeight: 400,
    },
  },
  ".subheader": {
    textAlign: "center",
    height: "50px",
    th: {
      border: `1px solid #908F90`,
      lineHeight: "17px",
      fontSize: "14px",
      fontWeight: 500,
      width: "25%",
    },
    ".little-col": {
      width: "120px",
    },
  },
  ".body": {
    tr: {
      height: "50px",
      textAlign: "center",
      td: {
        border: `1px solid #908F90`,
        input: {
          width: "100%",
          height: "50px",
          border: "none",
          outline: "none",
          fontSize: "16px",
          padding: "0px 10px",
        },
      },
    },
  },
});

type CarbonReactionRawMaterials = {
  name: string;
  productionPlace: string | null;
  cas: string;
  molecularWeight: string;
  weightPerBatch: string;
};

type CarbonReactionWastes = {
  roughComposition: string;
  type: string | null;
  weightPerBatch: string;
};

type CarbonReactionSolventRecycling = {
  solventName: string;
  distillationSite: string;
  recoveredWeightPerBatch: string;
};

type CarbonReactionCleaning = {
  solventName: string;
  productionPlace: string | null;
  cas: string;
  quantityPerCleaning: string;
  numberOfBatchesBetweenEachCleaning: string;
};

export type Substance = {
  // FinishedProduct first line
  parentSubstance: {
    name: string;
    cas: string;
    molecularWeight: string;
    weightPerBatch: string;
  };
  // FinishedProduct second line
  byProduct: {
    name: string;
    cas: string;
    molecularWeight: string;
    weightPerBatch: string;
  };
  packaging: {
    type: string;
    material: string;
    weightPerPackage: string;
  };
  carbonReactionRawMaterials: CarbonReactionRawMaterials[];
  carbonReactionAqueousWastes: CarbonReactionWastes[];
  carbonReactionOrganicWastes: CarbonReactionWastes[];
  carbonReactionGasWastes: CarbonReactionWastes[];
  carbonReactionSolventRecyclings: CarbonReactionSolventRecycling[];
  carbonReactionCleanings: CarbonReactionCleaning[];
  processType: string;
  purificationType: string;
};

const keyToText = {
  molecularWeight: "Molecular weight",
  weightPerBatch: "Weight per batch",
  productionPlace: "Production place",
  name: "Name",
  cas: "Cas",
  type: "Type",
  material: "Material",
  weightPerPackage: "Weight per package",
  roughComposition: "Rough composition",
  solventName: "Solvent name",
  quantityPerCleaning: "Quantity per cleaning",
  numberOfBatchesBetweenEachCleaning: "Number of batches between each cleaning",
  recoveredWeightPerBatch: "Recovered weight per batch",
  distillationSite: "Distillation site",
};

function checkLineIsEmpty(obj: any) {
  let isEmpty = true;
  let key;
  for (key in obj)
    if (obj.hasOwnProperty(key) && keyToText.hasOwnProperty(key)) {
      if (obj[key]) {
        isEmpty = false;
        break;
      }
    }
  return isEmpty;
}

function checkSubstanceFields(
  data: Substance,
  setError: UseFormSetError<Substance>
) {
  let isError = false;
  // check rawMaterials
  data.carbonReactionRawMaterials.forEach((item, index) => {
    if (!checkLineIsEmpty(item)) {
      for (const [key, value] of Object.entries(item)) {
        if (keyToText.hasOwnProperty(key) && !value) {
          isError = true;
          // @ts-ignore
          setError(`carbonReactionRawMaterials.${index}.${key}`, {
            type: "manual",
            // @ts-ignore
            message: `${keyToText[key]} is required`,
          });
        }
      }
    }
  });

  // check aqueousWastes
  data.carbonReactionAqueousWastes.forEach((item, index) => {
    if (!checkLineIsEmpty(item)) {
      for (const [key, value] of Object.entries(item)) {
        if (!value) {
          isError = true;
          // @ts-ignore
          setError(`carbonReactionAqueousWastes.${index}.${key}`, {
            type: "manual",
            // @ts-ignore
            message: `${keyToText[key]} is required`,
          });
        }
      }
    }
  });

  // check organicWastes
  data.carbonReactionOrganicWastes.forEach((item, index) => {
    if (!checkLineIsEmpty(item)) {
      for (const [key, value] of Object.entries(item)) {
        if (!value) {
          isError = true;
          // @ts-ignore
          setError(`carbonReactionOrganicWastes.${index}.${key}`, {
            type: "manual",
            // @ts-ignore
            message: `${keyToText[key]} is required`,
          });
        }
      }
    }
  });

  // check gasWastes
  data.carbonReactionGasWastes.forEach((item, index) => {
    if (!checkLineIsEmpty(item)) {
      for (const [key, value] of Object.entries(item)) {
        if (!value) {
          isError = true;
          // @ts-ignore
          setError(`carbonReactionGasWastes.${index}.${key}`, {
            type: "manual",
            // @ts-ignore
            message: `${keyToText[key]} is required`,
          });
        }
      }
    }
  });

  // check solvent
  data.carbonReactionSolventRecyclings.forEach((item, index) => {
    if (!checkLineIsEmpty(item)) {
      for (const [key, value] of Object.entries(item)) {
        if (!value) {
          isError = true;
          // @ts-ignore
          setError(`carbonReactionSolventRecyclings.${index}.${key}`, {
            type: "manual",
            // @ts-ignore
            message: `${keyToText[key]} is required`,
          });
        }
      }
    }
  });

  // check cleaning
  data.carbonReactionCleanings.forEach((item, index) => {
    if (!checkLineIsEmpty(item)) {
      for (const [key, value] of Object.entries(item)) {
        if (!value) {
          isError = true;
          // @ts-ignore
          setError(`carbonReactionCleanings.${index}.${key}`, {
            type: "manual",
            // @ts-ignore
            message: `${keyToText[key]} is required`,
          });
        }
      }
    }
  });

  // check byProduct
  if (!checkLineIsEmpty(data.byProduct)) {
    for (const [key, value] of Object.entries(data.byProduct)) {
      if (!value) {
        isError = true;
        // @ts-ignore
        setError(`byProduct.${key}`, {
          type: "manual",
          // @ts-ignore
          message: `${keyToText[key]} is required`,
        });
      }
    }
  }

  // check packaging
  if (!checkLineIsEmpty(data.packaging)) {
    for (const [key, value] of Object.entries(data.packaging)) {
      if (!value) {
        isError = true;
        // @ts-ignore
        setError(`packaging.${key}`, {
          type: "manual",
          // @ts-ignore
          message: `${keyToText[key]} is required`,
        });
      }
    }
  }
  return isError;
}

function StepSubstance({
  handlePrevious,
  handleNext,
  handleSave,
  currentIndex,
  currentSubstance,
  substances,
}: {
  handlePrevious: () => void;
  handleNext: () => void;
  handleSave: (data: Substance) => void;
  currentIndex: number;
  currentSubstance: any;
  substances: any;
}) {
  const theme = useTheme();
  console.log(currentSubstance);
  const {
    register,
    control,
    trigger,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useForm<Substance>({
    defaultValues: {
      carbonReactionRawMaterials: Array(3).fill({
        name: "",
        productionPlace: null,
        cas: "",
        molecularWeight: "",
        weightPerBatch: "",
      }),
      carbonReactionAqueousWastes: Array(2).fill({
        roughComposition: "",
        type: null,
        weightPerBatch: "",
      }),
      carbonReactionOrganicWastes: Array(2).fill({
        roughComposition: "",
        type: null,
        weightPerBatch: "",
      }),
      carbonReactionGasWastes: Array(2).fill({
        roughComposition: "",
        type: null,
        weightPerBatch: "",
      }),
      carbonReactionSolventRecyclings: Array(2).fill({
        solventName: "",
        distillationSite: "",
        recoveredWeightPerBatch: "",
      }),
      carbonReactionCleanings: Array(2).fill({
        solventName: "",
        productionPlace: null,
        cas: "",
        quantityPerCleaning: "",
        numberOfBatchesBetweenEachCleaning: "",
      }),
      byProduct: {
        name: currentSubstance.productBy.byProductsName,
        cas: currentSubstance.productBy.byProductsCas,
        molecularWeight: currentSubstance.productBy.byProductsMolecularWeight,
        weightPerBatch: currentSubstance.productBy.byProductsWeightPerBatch,
      },
      packaging: {
        type: currentSubstance.productBy.packagingType,
        material: currentSubstance.productBy.packagingMaterial,
        weightPerPackage: currentSubstance.productBy.packagingWeightPerPackage,
      },
      processType: currentSubstance.productBy.processType,
      purificationType: currentSubstance.productBy.purificationType,
    },
  });

  const { fields: rawMaterialsFields, append: appendRawMaterialsFields } =
    useFieldArray({
      control,
      name: "carbonReactionRawMaterials",
    });

  const appendRawMaterials = () => {
    appendRawMaterialsFields({
      name: "",
      productionPlace: null,
      cas: "",
      molecularWeight: "",
      weightPerBatch: "",
    });
  };

  const { fields: aqueousWastesFields, append: appendAqueousWastesFields } =
    useFieldArray({
      control,
      name: "carbonReactionAqueousWastes",
    });

  const appendAqueousWastes = () => {
    appendAqueousWastesFields({
      roughComposition: "",
      type: null,
      weightPerBatch: "",
    });
  };

  const { fields: organicWastesFields, append: appendOrganicWastesFields } =
    useFieldArray({
      control,
      name: "carbonReactionOrganicWastes",
    });

  const appendOrganicWastes = () => {
    appendOrganicWastesFields({
      roughComposition: "",
      type: null,
      weightPerBatch: "",
    });
  };

  const { fields: gasWastesFields, append: appendGasWastesFields } =
    useFieldArray({
      control,
      name: "carbonReactionGasWastes",
    });

  const appendGasWastes = () => {
    appendGasWastesFields({
      roughComposition: "",
      type: null,
      weightPerBatch: "",
    });
  };

  const { fields: solventFields, append: appendSolventFields } = useFieldArray({
    control,
    name: "carbonReactionSolventRecyclings",
  });

  const appendSolvent = () => {
    appendSolventFields({
      solventName: "",
      distillationSite: "",
      recoveredWeightPerBatch: "",
    });
  };

  const {
    fields: reactionCleaningFields,
    append: appendReactionCleaningFields,
  } = useFieldArray({
    control,
    name: "carbonReactionCleanings",
  });

  const appendReactionCleaning = () => {
    appendReactionCleaningFields({
      solventName: "",
      productionPlace: null,
      cas: "",
      quantityPerCleaning: "",
      numberOfBatchesBetweenEachCleaning: "",
    });
  };

  const handleSubmit = async () => {
    const isStepValid = await trigger();
    if (isStepValid) {
      const data = watch();
      const isError = checkSubstanceFields(data, setError);
      if (!isError) {
        handleSave(data);
        handleNext();
      }
    }
  };

  const handlePreviousAction = () => {
    handleSave(watch());
    handlePrevious();
  };

  useEffect(() => {
    if (currentSubstance) {
      const parentSubstance = _.find(substances, function (o) {
        return o.productBy.id === currentSubstance.usedInReactionId;
      });
      if (parentSubstance) setValue("parentSubstance", parentSubstance);

      if (currentSubstance.productBy.carbonReactionRawMaterials.length === 0) {
        setValue("carbonReactionRawMaterials.0.name", currentSubstance.name);
        setValue("carbonReactionRawMaterials.0.cas", currentSubstance.cas);
      } else
        setValue(
          "carbonReactionRawMaterials",
          currentSubstance.productBy.carbonReactionRawMaterials
        );

      if (currentSubstance.productBy.carbonReactionAqueousWastes.length > 0)
        setValue(
          "carbonReactionAqueousWastes",
          currentSubstance.productBy.carbonReactionAqueousWastes
        );

      if (currentSubstance.productBy.carbonReactionOrganicWastes.length > 0)
        setValue(
          "carbonReactionOrganicWastes",
          currentSubstance.productBy.carbonReactionOrganicWastes
        );

      if (currentSubstance.productBy.carbonReactionGasWastes.length > 0)
        setValue(
          "carbonReactionGasWastes",
          currentSubstance.productBy.carbonReactionGasWastes
        );

      if (currentSubstance.productBy.carbonReactionCleanings.length > 0)
        setValue(
          "carbonReactionCleanings",
          currentSubstance.productBy.carbonReactionCleanings
        );

      if (currentSubstance.productBy.carbonReactionSolventRecyclings.length > 0)
        setValue(
          "carbonReactionSolventRecyclings",
          currentSubstance.productBy.carbonReactionSolventRecyclings
        );
    }
  }, [currentSubstance, substances, setValue]);

  return (
    <div
      css={{
        backgroundColor: theme.white,
        padding: "50px 56px",
        color: theme.black,
        display: "grid",
        gridTemplateColumns: "auto 268px",
        columnGap: "30px",
      }}
    >
      <div>
        <Header
          title="Carbon footprint"
          stepName="Step 4"
          stepTitle="Mass balance and process information"
          backName="Previous step"
          backAction={() => handlePreviousAction()}
        />
        <p
          css={{
            marginTop: "32px",
            marginBottom: "24px",
            color: theme.black,
            fontSize: "16px",
            lineHeight: "18px",
            fontWeight: 500,
          }}
        >{`Synthetic route: reaction ${currentIndex + 1}/${
          substances.length - 1
        }`}</p>
        <form>
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "50px",
            }}
          >
            <table css={css(tableCss)}>
              <thead>
                <tr className="header">
                  <th colSpan={5}>
                    Raw materials, including solvents and water
                  </th>
                </tr>
                <tr className="subheader">
                  <th>Name</th>
                  <th>Place of production</th>
                  <th className="little-col">CAS number</th>
                  <th className="little-col">MW (g/mol)</th>
                  <th className="little-col">Weight/batch (kg)</th>
                </tr>
              </thead>
              <tbody className="body">
                {rawMaterialsFields.map((field, index) =>
                  index === 0 ? (
                    <tr key={field.id}>
                      <td>
                        <input
                          disabled={true}
                          {...register(
                            `carbonReactionRawMaterials.${index}.name`
                          )}
                        />
                      </td>
                      <td>
                        <Controller
                          control={control}
                          name={`carbonReactionRawMaterials.${index}.productionPlace`}
                          rules={{
                            required: `Production place can't be empty`,
                          }}
                          render={({ field: { onChange, value, ref } }) => (
                            <SelectInTable
                              onChange={(data: any) =>
                                onChange(data ? data.value : null)
                              }
                              isClearable={true}
                              options={RawMaterialProductionPlaceOptions}
                              inputRef={ref}
                              getOptionLabel={(option: any) => option.label}
                              value={RawMaterialProductionPlaceOptions.find(
                                (el) => el.value === value
                              )}
                            />
                          )}
                        />
                      </td>
                      <td>
                        <input
                          disabled={true}
                          {...register(
                            `carbonReactionRawMaterials.${index}.cas`
                          )}
                        />
                      </td>
                      <td>
                        <input
                          {...register(
                            `carbonReactionRawMaterials.${index}.molecularWeight`,
                            {
                              required: `Molecular Weight can't be empty`,
                              pattern: {
                                value: decimalNumberRegex,
                                message: "Molecular Weight has to be a number",
                              },
                            }
                          )}
                        />
                      </td>
                      <td>
                        <input
                          {...register(
                            `carbonReactionRawMaterials.${index}.weightPerBatch`,
                            {
                              required: `Weight per Batch can't be empty`,
                              pattern: {
                                value: decimalNumberRegex,
                                message: "Weight per Batch has to be a number",
                              },
                            }
                          )}
                        />
                      </td>
                    </tr>
                  ) : (
                    <tr key={field.id}>
                      <td>
                        <input
                          {...register(
                            `carbonReactionRawMaterials.${index}.name`
                          )}
                        />
                      </td>
                      <td>
                        <Controller
                          control={control}
                          name={`carbonReactionRawMaterials.${index}.productionPlace`}
                          render={({ field: { onChange, value, ref } }) => (
                            <SelectInTable
                              onChange={(data: any) =>
                                onChange(data ? data.value : null)
                              }
                              isClearable={true}
                              options={RawMaterialProductionPlaceOptions}
                              inputRef={ref}
                              getOptionLabel={(option: any) => option.label}
                              value={RawMaterialProductionPlaceOptions.find(
                                (el) => el.value === value
                              )}
                            />
                          )}
                        />
                      </td>
                      <td>
                        <input
                          {...register(
                            `carbonReactionRawMaterials.${index}.cas`
                          )}
                        />
                      </td>
                      <td>
                        <input
                          {...register(
                            `carbonReactionRawMaterials.${index}.molecularWeight`,
                            {
                              validate: (value: string) => {
                                if (value === "") return true;
                                if (!decimalNumberRegex.test(value))
                                  return "Molecular Weight has to be a number";
                              },
                            }
                          )}
                        />
                      </td>
                      <td>
                        <input
                          {...register(
                            `carbonReactionRawMaterials.${index}.weightPerBatch`,
                            {
                              validate: (value: string) => {
                                if (value === "") return true;
                                if (!decimalNumberRegex.test(value))
                                  return "Weight per Batch has to be a number";
                              },
                            }
                          )}
                        />
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>

            {
              () => {
                const tempErrors = errors;
                if (tempErrors === undefined) return;
                
                const materials = tempErrors?.carbonReactionRawMaterials;
                if (typeof materials === "undefined") return;


                return (materials as any)?.map((error: any, index: number) => {
                    if (error) {
                      const errorMessage = Object.values(error)
                      .map((el: any) => el.message)
                      .join(", ");

                      return (
                        <p
                        key={`rawMaterials_${index}`}
                        css={{
                          color: theme.error,
                          fontSize: "14px",
                          marginBottom: "0px",
                        }}
                        >
                        Line {index + 1}: {errorMessage}
                        </p>
                      );
                    }
                    return <div></div>;
                  })
              }
            }

            <button
              css={{
                cursor: "pointer",
                marginTop: "18px",
                background: "transparent",
                border: "none",
                display: "flex",
                alignItems: "center",
                padding: 0,
                color: theme.black,
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "17px",
                width: "120px",
                svg: {
                  marginRight: "12px",
                },
              }}
              type="button"
              onClick={appendRawMaterials}
            >
              <AddIcon />
              Add a line
            </button>
          </div>

          <div css={{ display: "flex", marginBottom: "48px" }}>
            <table
              css={css(tableCss, {
                ".header": {
                  backgroundColor: theme.grey,
                  border: `1px solid ${theme.grey}`,
                },
                ".body": {
                  ".first-col": {
                    width: "170px",
                  },
                },
              })}
            >
              <thead>
                <tr className="header">
                  <th colSpan={2}>Type of process</th>
                </tr>
              </thead>

              <tbody className="body">
                <tr>
                  <td className="first-col">Select a process</td>
                  <td>
                    <Controller
                      control={control}
                      name={`processType`}
                      render={({ field: { onChange, value, ref } }) => (
                        <SelectInTable
                          onChange={(data: any) =>
                            onChange(data ? data.value : null)
                          }
                          isClearable={true}
                          options={ProcessTypeOptions}
                          inputRef={ref}
                          getOptionLabel={(option: any) => option.label}
                          value={ProcessTypeOptions.find(
                            (el) => el.value === value
                          )}
                        />
                      )}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div css={{ display: "flex", marginBottom: "32px" }}>
            <table
              css={css(tableCss, {
                ".header": {
                  backgroundColor: "#33BE56",
                  border: `1px solid #33BE56`,
                },
                ".body": {
                  ".first-col": {
                    width: "170px",
                  },
                },
              })}
            >
              <thead>
                <tr className="header">
                  <th colSpan={2}>Purification</th>
                </tr>
              </thead>

              <tbody className="body">
                <tr>
                  <td className="first-col">Main purification</td>
                  <td>
                    <Controller
                      control={control}
                      name={`purificationType`}
                      render={({ field: { onChange, value, ref } }) => (
                        <SelectInTable
                          onChange={(data: any) =>
                            onChange(data ? data.value : null)
                          }
                          isClearable={true}
                          options={PurificationTypeOptions}
                          inputRef={ref}
                          getOptionLabel={(option: any) => option.label}
                          value={PurificationTypeOptions.find(
                            (el) => el.value === value
                          )}
                        />
                      )}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            css={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "32px",
            }}
          >
            <table
              css={css(tableCss, {
                ".header": {
                  backgroundColor: "#33BE56",
                  border: `1px solid #33BE56`,
                },
              })}
            >
              <thead>
                <tr className="header">
                  <th colSpan={4}>Finished products</th>
                </tr>
                <tr className="subheader">
                  <th>Name</th>
                  <th>CAS number</th>
                  <th>MW (g/mol)</th>
                  <th>Weight/batch (kg)</th>
                </tr>
              </thead>
              <tbody className="body">
                <tr>
                  <td>
                    <input
                      disabled={true}
                      {...register("parentSubstance.name")}
                    />
                  </td>
                  <td>
                    <input
                      disabled={true}
                      {...register("parentSubstance.cas")}
                    />
                  </td>
                  <td>
                    <input
                      {...register(`parentSubstance.molecularWeight`, {
                        required: "Molecular Weight is required",
                        pattern: {
                          value: decimalNumberRegex,
                          message: "Molecular Weight has to be a number",
                        },
                      })}
                    />
                  </td>
                  <td>
                    <input
                      {...register(`parentSubstance.weightPerBatch`, {
                        required: "Weight per Batch is required",
                        pattern: {
                          value: decimalNumberRegex,
                          message: "Weight per Batch has to be a number",
                        },
                      })}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input {...register("byProduct.name")} />
                  </td>
                  <td>
                    <input {...register("byProduct.cas")} />
                  </td>
                  <td>
                    <input
                      {...register("byProduct.molecularWeight", {
                        validate: (value: string) => {
                          if (value === "") return true;
                          if (!decimalNumberRegex.test(value))
                            return "Molecular Weight has to be a number";
                        },
                      })}
                    />
                  </td>
                  <td>
                    <input
                      {...register("byProduct.weightPerBatch", {
                        validate: (value: string) => {
                          if (value === "") return true;
                          if (!decimalNumberRegex.test(value))
                            return "Weight per Batch has to be a number";
                        },
                      })}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            {errors?.parentSubstance && (
              <p
                css={{
                  color: theme.error,
                  fontSize: "14px",
                  marginBottom: "0px",
                }}
              >
                Line 1:{" "}
                {Object.values(errors.parentSubstance)
                  .map((el: any) => el.message)
                  .join(", ")}
              </p>
            )}
            {errors?.byProduct && (
              <p
                css={{
                  color: theme.error,
                  fontSize: "14px",
                  marginBottom: "0px",
                }}
              >
                Line 2:{" "}
                {Object.values(errors.byProduct)
                  .map((el: any) => el.message)
                  .join(", ")}
              </p>
            )}
          </div>

          <div
            css={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "48px",
            }}
          >
            <table
              css={css(tableCss, {
                ".header": {
                  backgroundColor: "#33BE56",
                  border: `1px solid #33BE56`,
                },
              })}
            >
              <thead>
                <tr className="header">
                  <th colSpan={4}>Packaging</th>
                </tr>
                <tr className="subheader">
                  <th>Type</th>
                  <th>Material</th>
                  <th>Weight/pack (kg)</th>
                </tr>
              </thead>
              <tbody className="body">
                <tr>
                  <td>
                    <input {...register("packaging.type")} />
                  </td>
                  <td>
                    <input {...register("packaging.material")} />
                  </td>
                  <td>
                    <input
                      {...register("packaging.weightPerPackage", {
                        validate: (value: string) => {
                          if (!value) return true;
                          if (!decimalNumberRegex.test(value))
                            return "Weight per package has to be a number";
                        },
                      })}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            {errors?.packaging && (
              <p
                css={{
                  color: theme.error,
                  fontSize: "14px",
                  marginBottom: "0px",
                }}
              >
                {Object.values(errors.packaging)
                  .map((el: any) => el.message)
                  .join(", ")}
              </p>
            )}
          </div>

          <div
            css={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "34px",
            }}
          >
            <table
              css={css(tableCss, {
                ".header": {
                  backgroundColor: "#D9952F",
                  border: `1px solid #D9952F`,
                },
              })}
            >
              <thead>
                <tr className="header">
                  <th colSpan={3}>Aqueous Wastes</th>
                </tr>
                <tr className="subheader">
                  <th>Rough composition</th>
                  <th>Type of treatment</th>
                  <th>Weight/batch (kg)</th>
                </tr>
              </thead>
              <tbody className="body">
                {aqueousWastesFields.map((field, index) => (
                  <tr key={field.id}>
                    <td>
                      <input
                        {...register(
                          `carbonReactionAqueousWastes.${index}.roughComposition`
                        )}
                      />
                    </td>
                    <td>
                      <Controller
                        control={control}
                        name={`carbonReactionAqueousWastes.${index}.type`}
                        render={({ field: { onChange, value, ref } }) => (
                          <SelectInTable
                            onChange={(data: any) =>
                              onChange(data ? data.value : null)
                            }
                            isClearable={true}
                            options={AqueousTreatmentTypeOptions}
                            inputRef={ref}
                            getOptionLabel={(option: any) => option.label}
                            value={AqueousTreatmentTypeOptions.find(
                              (el) => el.value === value
                            )}
                          />
                        )}
                      />
                    </td>
                    <td>
                      <input
                        {...register(
                          `carbonReactionAqueousWastes.${index}.weightPerBatch`,
                          {
                            validate: (value: string) => {
                              if (value === "") return true;
                              if (!decimalNumberRegex.test(value))
                                return "Weight per batch has to be a number";
                            },
                          }
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {(errors?.carbonReactionAqueousWastes as any)?.map((error: any, index: number) => {
              if (error) {
                const errorMessage = Object.values(error)
                  .map((el: any) => el.message)
                  .join(", ");
                return (
                  <p
                    key={`aqueous_${index}`}
                    css={{
                      color: theme.error,
                      fontSize: "14px",
                      marginBottom: "0px",
                    }}
                  >
                    Line {index + 1}: {errorMessage}
                  </p>
                );
              }
              return null;
            })}

            <button
              css={{
                cursor: "pointer",
                marginTop: "18px",
                background: "transparent",
                border: "none",
                display: "flex",
                alignItems: "center",
                padding: 0,
                color: theme.black,
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "17px",
                width: "120px",
                svg: {
                  marginRight: "12px",
                },
              }}
              type="button"
              onClick={appendAqueousWastes}
            >
              <AddIcon />
              Add a line
            </button>
          </div>

          <div
            css={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "34px",
            }}
          >
            <table
              css={css(tableCss, {
                ".header": {
                  backgroundColor: "#D9952F",
                  border: `1px solid #D9952F`,
                },
              })}
            >
              <thead>
                <tr className="header">
                  <th colSpan={3}>Organic Wastes</th>
                </tr>
                <tr className="subheader">
                  <th>Rough composition</th>
                  <th>Type of treatment</th>
                  <th>Weight/batch (kg)</th>
                </tr>
              </thead>
              <tbody className="body">
                {organicWastesFields.map((field, index) => (
                  <tr key={field.id}>
                    <td>
                      <input
                        {...register(
                          `carbonReactionOrganicWastes.${index}.roughComposition`
                        )}
                      />
                    </td>
                    <td>
                      <Controller
                        control={control}
                        name={`carbonReactionOrganicWastes.${index}.type`}
                        render={({ field: { onChange, value, ref } }) => (
                          <SelectInTable
                            onChange={(data: any) =>
                              onChange(data ? data.value : null)
                            }
                            isClearable={true}
                            options={OrganicTreatmentTypeOptions}
                            inputRef={ref}
                            getOptionLabel={(option: any) => option.label}
                            value={OrganicTreatmentTypeOptions.find(
                              (el) => el.value === value
                            )}
                          />
                        )}
                      />
                    </td>
                    <td>
                      <input
                        {...register(
                          `carbonReactionOrganicWastes.${index}.weightPerBatch`,
                          {
                            validate: (value: string) => {
                              if (value === "") return true;
                              if (!decimalNumberRegex.test(value))
                                return "Weight per batch has to be a number";
                            },
                          }
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {(errors?.carbonReactionOrganicWastes as any)?.map((error: any, index: number) => {
              if (error) {
                const errorMessage = Object.values(error)
                  .map((el: any) => el.message)
                  .join(", ");
                return (
                  <p
                    key={`organic_${index}`}
                    css={{
                      color: theme.error,
                      fontSize: "14px",
                      marginBottom: "0px",
                    }}
                  >
                    Line {index + 1}: {errorMessage}
                  </p>
                );
              }
              return null;
            })}

            <button
              css={{
                cursor: "pointer",
                marginTop: "18px",
                background: "transparent",
                border: "none",
                display: "flex",
                alignItems: "center",
                padding: 0,
                color: theme.black,
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "17px",
                width: "120px",
                svg: {
                  marginRight: "12px",
                },
              }}
              type="button"
              onClick={appendOrganicWastes}
            >
              <AddIcon />
              Add a line
            </button>
          </div>

          <div
            css={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "34px",
            }}
          >
            <table
              css={css(tableCss, {
                ".header": {
                  backgroundColor: "#D9952F",
                  border: `1px solid #D9952F`,
                },
              })}
            >
              <thead>
                <tr className="header">
                  <th colSpan={3}>Gas emitted to air</th>
                </tr>
                <tr className="subheader">
                  <th>Rough composition</th>
                  <th>Type of treatment</th>
                  <th>Weight/batch (kg)</th>
                </tr>
              </thead>
              <tbody className="body">
                {gasWastesFields.map((field, index) => (
                  <tr key={field.id}>
                    <td>
                      <input
                        {...register(
                          `carbonReactionGasWastes.${index}.roughComposition`
                        )}
                      />
                    </td>
                    <td>
                      <Controller
                        control={control}
                        name={`carbonReactionGasWastes.${index}.type`}
                        render={({ field: { onChange, value, ref } }) => (
                          <SelectInTable
                            onChange={(data: any) =>
                              onChange(data ? data.value : null)
                            }
                            isClearable={true}
                            options={GasTreatmentTypeOptions}
                            inputRef={ref}
                            getOptionLabel={(option: any) => option.label}
                            value={GasTreatmentTypeOptions.find(
                              (el) => el.value === value
                            )}
                          />
                        )}
                      />
                    </td>
                    <td>
                      <input
                        {...register(
                          `carbonReactionGasWastes.${index}.weightPerBatch`,
                          {
                            validate: (value: string) => {
                              if (value === "") return true;
                              if (!decimalNumberRegex.test(value))
                                return "Weight per batch has to be a number";
                            },
                          }
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {(errors?.carbonReactionGasWastes as any)?.map((error: any, index: number) => {
              if (error) {
                const errorMessage = Object.values(error)
                  .map((el: any) => el.message)
                  .join(", ");
                return (
                  <p
                    key={`gas_${index}`}
                    css={{
                      color: theme.error,
                      fontSize: "14px",
                      marginBottom: "0px",
                    }}
                  >
                    Line {index + 1}: {errorMessage}
                  </p>
                );
              }
              return null;
            })}

            <button
              css={{
                cursor: "pointer",
                marginTop: "18px",
                background: "transparent",
                border: "none",
                display: "flex",
                alignItems: "center",
                padding: 0,
                color: theme.black,
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "17px",
                width: "120px",
                svg: {
                  marginRight: "12px",
                },
              }}
              type="button"
              onClick={appendGasWastes}
            >
              <AddIcon />
              Add a line
            </button>
          </div>

          <div
            css={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "34px",
            }}
          >
            <table
              css={css(tableCss, {
                ".header": {
                  backgroundColor: "#D9952F",
                  border: `1px solid #D9952F`,
                },
              })}
            >
              <thead>
                <tr className="header">
                  <th colSpan={3}>Solvent recycling</th>
                </tr>
                <tr className="subheader">
                  <th>Name of solvent</th>
                  <th>Place of distillation</th>
                  <th>Recovered weight/batch (kg)</th>
                </tr>
              </thead>
              <tbody className="body">
                {solventFields.map((field, index) => (
                  <tr key={field.id}>
                    <td>
                      <input
                        {...register(
                          `carbonReactionSolventRecyclings.${index}.solventName`
                        )}
                      />
                    </td>
                    <td>
                      <Controller
                        control={control}
                        name={`carbonReactionSolventRecyclings.${index}.distillationSite`}
                        render={({ field: { onChange, value, ref } }) => (
                          <SelectInTable
                            onChange={(data: any) =>
                              onChange(data ? data.value : null)
                            }
                            isClearable={true}
                            options={DistanceOptions}
                            inputRef={ref}
                            getOptionLabel={(option: any) => option.label}
                            value={DistanceOptions.find(
                              (el) => el.value === value
                            )}
                          />
                        )}
                      />
                    </td>
                    <td>
                      <input
                        {...register(
                          `carbonReactionSolventRecyclings.${index}.recoveredWeightPerBatch`,
                          {
                            validate: (value: string) => {
                              if (value === "") return true;
                              if (!decimalNumberRegex.test(value))
                                return "Recovered weight per batch has to be a number";
                            },
                          }
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {(errors?.carbonReactionSolventRecyclings as any)?.map((error: any, index: number) => {
              if (error) {
                const errorMessage = Object.values(error)
                  .map((el: any) => el.message)
                  .join(", ");
                return (
                  <p
                    key={`solvent_${index}`}
                    css={{
                      color: theme.error,
                      fontSize: "14px",
                      marginBottom: "0px",
                    }}
                  >
                    Line {index + 1}: {errorMessage}
                  </p>
                );
              }
              return null;
            })}

            <button
              css={{
                cursor: "pointer",
                marginTop: "18px",
                background: "transparent",
                border: "none",
                display: "flex",
                alignItems: "center",
                padding: 0,
                color: theme.black,
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "17px",
                width: "120px",
                svg: {
                  marginRight: "12px",
                },
              }}
              type="button"
              onClick={appendSolvent}
            >
              <AddIcon />
              Add a line
            </button>
          </div>

          <div
            css={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "34px",
            }}
          >
            <table
              css={css(tableCss, {
                ".header": {
                  backgroundColor: "#D9952F",
                  border: `1px solid #D9952F`,
                },
              })}
            >
              <thead>
                <tr className="header">
                  <th colSpan={5}>Industrial equipements cleaning</th>
                </tr>
                <tr className="subheader">
                  <th className="little-col">Name of solvent</th>
                  <th>Place of production</th>
                  <th className="little-col">CAS number</th>
                  <th className="little-col">Quantity/cleaning (kg)</th>
                  <th>Number of batches between each cleaning</th>
                </tr>
              </thead>
              <tbody className="body">
                {reactionCleaningFields.map((field, index) => (
                  <tr key={field.id}>
                    <td>
                      <input
                        {...register(
                          `carbonReactionCleanings.${index}.solventName`
                        )}
                      />
                    </td>
                    <td>
                      <Controller
                        control={control}
                        name={`carbonReactionCleanings.${index}.productionPlace`}
                        render={({ field: { onChange, value, ref } }) => (
                          <SelectInTable
                            onChange={(data: any) =>
                              onChange(data ? data.value : null)
                            }
                            isClearable={true}
                            options={RawMaterialProductionPlaceOptions}
                            inputRef={ref}
                            getOptionLabel={(option: any) => option.label}
                            value={RawMaterialProductionPlaceOptions.find(
                              (el) => el.value === value
                            )}
                          />
                        )}
                      />
                    </td>
                    <td>
                      <input
                        {...register(`carbonReactionCleanings.${index}.cas`)}
                      />
                    </td>
                    <td>
                      <input
                        {...register(
                          `carbonReactionCleanings.${index}.quantityPerCleaning`,
                          {
                            validate: (value: string) => {
                              if (value === "") return true;
                              if (!decimalNumberRegex.test(value))
                                return "Quantity per cleaning has to be a number";
                            },
                          }
                        )}
                      />
                    </td>
                    <td>
                      <input
                        {...register(
                          `carbonReactionCleanings.${index}.numberOfBatchesBetweenEachCleaning`,
                          {
                            validate: (value: string) => {
                              if (value === "") return true;
                              if (!decimalNumberRegex.test(value))
                                return "Number of batches between each cleaning has to be a number";
                            },
                          }
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {(errors?.carbonReactionCleanings as any)?.map((error: any, index: number) => {
              if (error) {
                const errorMessage = Object.values(error)
                  .map((el: any) => el.message)
                  .join(", ");
                return (
                  <p
                    key={`reaction_cleaning_${index}`}
                    css={{
                      color: theme.error,
                      fontSize: "14px",
                      marginBottom: "0px",
                    }}
                  >
                    Line {index + 1}: {errorMessage}
                  </p>
                );
              }
              return null;
            })}

            <button
              css={{
                cursor: "pointer",
                marginTop: "18px",
                background: "transparent",
                border: "none",
                display: "flex",
                alignItems: "center",
                padding: 0,
                color: theme.black,
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "17px",
                width: "120px",
                svg: {
                  marginRight: "12px",
                },
              }}
              type="button"
              onClick={appendReactionCleaning}
            >
              <AddIcon />
              Add a line
            </button>
          </div>
        </form>
      </div>
      <div>
        <CurrentStepVisualiser step={7} />
        <Button
          variant="dark"
          onClick={() => handleSubmit()}
          css={{
            fontSize: "14px",
            marginBottom: "16px",
          }}
        >{`Validate data for reaction ${currentIndex + 1}/${
          substances.length - 1
        }`}</Button>
        <Button
          variant="light"
          onClick={() => handleNext()}
          css={{
            fontSize: "14px",
          }}
        >{`Unknown data for reaction ${currentIndex + 1}/${
          substances.length - 1
        }`}</Button>
      </div>
    </div>
  );
}

export default StepSubstance;
