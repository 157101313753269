/** @jsxImportSource @emotion/react */
import { CSSObject } from '@emotion/react';
import ReactSelect from 'react-select';

const customStyles = {
    container: (provided: CSSObject) => ({
        ...provided,
    }),
    control: (provided: CSSObject, state: any) => ({
        ...provided,
        padding: '0px 8px',
        height: '50px',
        backgroundColor: 'transparent',
        border: 'none',
        transition: 'none',
        '&:hover, &:focus': {
            borderColor: '#1A085C',
        }
    }),
    placeholder: () => ({
        display: 'none'
    }),
    singleValue: (provided: CSSObject) => ({
        ...provided,
        color: '#040C1E',
        fontSize: '16px',
        lineHeight: '20px',
    }),
    menu: (provided: CSSObject) => ({
        ...provided,
        marginTop: '6px',
        backgroundColor: '#F6F5F6',
        border: 'none',
        boxShadow: 'none',
    }),
    menuList: (provided: CSSObject) => ({
        ...provided,
        marginTop: '6px',
        padding: '8px 16px',
        backgroundColor: '#F6F5F6',
        textAlign: 'left' as any,

    }),
    option: (provided: CSSObject) => ({
        ...provided,
        padding: '8px 0px',
        cursor: 'pointer',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#040C1E',
        backgroundColor: 'transparent',
        '&:hover': {
            color: '#1A085C',
            backgroundColor: 'transparent',
            fontWeight: 600,
        }
    }),
    valueContainer: (provided: CSSObject) => ({
        ...provided,
        padding: '0px',
        marginTop: '-4px',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
}

function SelectInTable({
    value,
    className,
    onChange,
    options,
    getOptionLabel,
    inputRef,
    isClearable = false,
}: any) {
    return (
        <div
            css={{
                display: "flex",
                flexDirection: "column",
                width: '100%',
                height: '50px',
            }}
            className={className}
        >
            <ReactSelect
                onChange={onChange}
                options={options}
                getOptionLabel={getOptionLabel}
                value={value}
                styles={customStyles}
                ref={inputRef}
                isClearable={isClearable}
            />
        </div>
    );
}

export default SelectInTable;