/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from 'component/Button';
import { ControlledCheckbox } from 'component/Checkbox';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

type RegisterStep3FormData = {
    accountType: "STANDARD" | "PREMIUM" | null;
    acceptTerms: boolean;
}

function Step3({ handleStep }: {
    handleStep: (type: "decrement" | "increment") => void;
}) {
    const theme = useTheme();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [step3Data, setStep3Data] = useState<RegisterStep3FormData>({
        accountType: null,
        acceptTerms: false,
    });
    const [dialogState, setDialogState] = useState({
        acceptTermsOfSales: false,
        acceptTermsOfUse: false,
        acceptPrivacyPolicy: false,
        acceptConfirmation: false,
    });

    const { getValues, setValue } = useFormContext<RegisterStep3FormData>();

    const handleClose = () => setDialogOpen(false);
    const handleOpen = () => setDialogOpen(true);
    const handleCheckbox = (ev: { target: HTMLInputElement }) => {
        if (ev.target.checked) handleOpen();
        else {
            setStep3Data({ ...step3Data, acceptTerms: false });
        }
    }

    const handleDialogCheckbox = (ev: { target: HTMLInputElement }) => {
        const { checked, name } = ev.target;
        setDialogState({ ...dialogState, [name]: checked });
    }

    const validateTerms = () => {
        setDialogOpen(false);
        setStep3Data({ ...step3Data, acceptTerms: true });
    }

    const handleChooseAccount = (accountType: "STANDARD" | "PREMIUM" | null) => {
        setValue('accountType', accountType);
        setStep3Data({ ...step3Data, accountType });
    }

    const accountButtonCss = css({
        cursor: 'pointer',
        padding: '32px 12px',
        textAlign: 'center',
        width: '100%',
        border: 'none',
        boxShadow: '0px 0px 10px 0px #040C1E14',
        background: 'transparent',
        marginTop: '24px',
        'h4': {
            color: theme.black,
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '33px',
            marginTop: '0px',
            marginBottom: '12px'
        },
        'p': {
            fontSize: '14px',
            lineHeight: '17px',
            color: theme.grey,
        },
        '&:hover': {
            backgroundColor: theme.lightGrey,
        }
    });

    const activeAccountButtonCss = css({
        backgroundColor: theme.primary,
        'h4': {
            color: theme.white,
        },
        'p': {
            color: theme.white,
        },
        '&:hover': {
            backgroundColor: theme.primary,
        },
    });

    const linkCss = css({
        color: theme.black,
        '&:hover': {
            textDecoration: 'underline',
        },
    });

    useEffect(() => {
        const [acceptTerms = false, accountType = null] = getValues(['acceptTerms', 'accountType']);
        setStep3Data({ acceptTerms, accountType });
    }, [getValues]);

    const accountType = getValues('accountType');
    const disabledButton = (!step3Data.acceptTerms || (accountType !== 'PREMIUM' && accountType !== 'STANDARD'));

    return (
        <>
            <Dialog
                open={dialogOpen}
                onClose={handleClose}
                scroll="body"
            >
                <DialogTitle>{`General Terms & Condition`}</DialogTitle>
                <DialogContent>
                    <DialogContentText css={{
                        fontFamily: "'Montserrat', sans-serif",
                    }}>
                        You have to read and accept the <a css={linkCss} target="_blank" rel="noreferrer" href="https://ecovamed-front-document.s3.eu-west-3.amazonaws.com/terms_of_use">General Terms and Conditions of Use</a>
                        , the <a css={linkCss} target="_blank" rel="noreferrer" href="https://ecovamed-front-document.s3.eu-west-3.amazonaws.com/terms_of_sales">General Terms and Conditions of Sale</a> and the <a css={linkCss} target="_blank" rel="noreferrer" href="https://ecovamed-front-document.s3.eu-west-3.amazonaws.com/privacy_policy">Privacy Policy</a> before finalizing your subscription.
                        Please, download, save and/or print them.
                    </DialogContentText>
                    <ControlledCheckbox
                        name="acceptTermsOfUse"
                        checked={dialogState.acceptTermsOfUse}
                        onChange={handleDialogCheckbox}
                        label={<p css={{
                            color: theme.black,
                            'a': {
                                color: theme.black,
                                fontWeight: 600,
                                textDecoration: 'underline'
                            }
                        }}>I confirm I have read and I accept the <a target="_blank" rel="noreferrer" href="https://ecovamed-front-document.s3.eu-west-3.amazonaws.com/terms_of_use">General Terms and Conditions of Use</a></p>}
                        css={{
                            marginTop: '20px',
                        }}
                    />
                    <ControlledCheckbox
                        name="acceptTermsOfSales"
                        checked={dialogState.acceptTermsOfSales}
                        onChange={handleDialogCheckbox}
                        label={<p css={{
                            color: theme.black,
                            'a': {
                                color: theme.black,
                                fontWeight: 600,
                                textDecoration: 'underline'
                            }
                        }}>I confirm I have read and I accept the <a target="_blank" rel="noreferrer" href="https://ecovamed-front-document.s3.eu-west-3.amazonaws.com/terms_of_sales">General Terms and Conditions of Sale</a></p>}
                        css={{
                            marginTop: '30px',
                        }}
                    />
                    <ControlledCheckbox
                        name="acceptPrivacyPolicy"
                        checked={dialogState.acceptPrivacyPolicy}
                        onChange={handleDialogCheckbox}
                        label={<p css={{
                            color: theme.black,
                            'a': {
                                color: theme.black,
                                fontWeight: 600,
                                textDecoration: 'underline'
                            }
                        }}>I confirm I have read and I agree to the <a target="_blank" rel="noreferrer" href="https://ecovamed-front-document.s3.eu-west-3.amazonaws.com/privacy_policy">Privacy Policy</a></p>}
                        css={{
                            marginTop: '30px',
                        }}
                    />
                    <ControlledCheckbox
                        name="acceptConfirmation"
                        checked={dialogState.acceptConfirmation}
                        onChange={handleDialogCheckbox}
                        label={<p css={{
                            color: theme.black,
                            'a': {
                                color: theme.black,
                                fontWeight: 600,
                                textDecoration: 'underline'
                            }
                        }}>I confirm I have understood I am not allowed to disclose the carbon
                            footprint values of this database in any public document (article, thesis, internet…)</p>}
                        css={{
                            marginTop: '30px',
                            marginBottom: '30px',
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <button
                        type="button"
                        css={{
                            cursor: 'pointer',
                            border: 'none',
                            background: 'transparent',
                            fontWeight: 500,
                            fontSize: '16px'
                        }}
                        onClick={handleClose}
                    >
                        Disagree
                    </button>
                    <button
                        disabled={!(dialogState.acceptPrivacyPolicy && dialogState.acceptTermsOfSales && dialogState.acceptTermsOfUse && dialogState.acceptConfirmation)}
                        type="button"
                        css={{
                            cursor: 'pointer',
                            border: 'none',
                            background: 'transparent',
                            fontWeight: 500,
                            fontSize: '16px'
                        }}
                        onClick={validateTerms}
                    >
                        Agree
                    </button>
                </DialogActions>
            </Dialog>

            <h1 css={{
                fontSize: '24px',
                lineHeight: '28px',
                fontWeight: 600,
                marginTop: '40px',
                marginBottom: '0px',
            }}
            >Create your account</h1>

            <h4 css={{
                fontSize: '14px',
                lineHeight: '18px',
                fontWeight: 'normal',
                color: theme.darkGrey,
                marginTop: '16px',
                marginBottom: '16px',
            }}>Step 3 - Type of account</h4>

            <p css={{
                marginTop: '0px',
                marginBottom: '8px',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '17px',
                color: theme.black,
            }}>A quotation for the subscription fee will be sent after validation of the account creation</p>

            <button
                type="button"
                css={[accountButtonCss, step3Data.accountType === "STANDARD" ? activeAccountButtonCss : null]}
                onClick={() => handleChooseAccount('STANDARD')}
            >
                <h4>Hospitals</h4>
                <p>Free access for hospital pharmacists and doctors, subject to professional proof</p>
            </button>

            <button
                type="button"
                css={[accountButtonCss, step3Data.accountType === "PREMIUM" ? activeAccountButtonCss : null]}
                onClick={() => handleChooseAccount('PREMIUM')}
            >
                <h4>Companies and non medical institutions</h4>
                <p>Paid access, a quotation will be sent in the coming days</p>
            </button>

            <ControlledCheckbox
                name="acceptTerms"
                checked={step3Data.acceptTerms}
                onChange={handleCheckbox}
                label={`I agree to Ecovamed General Terms & Conditions and Privacy Policy`}
                css={{
                    marginTop: '40px'
                }}
            />

            <Button
                type="submit"
                disabled={disabledButton}
                css={{
                    marginTop: '48px',
                    marginBottom: '16px',
                }}
            >Validation</Button>

            <Button onClick={() => handleStep('decrement')}>Back to Step 2</Button>
        </>
    )
}

export default Step3;