import { useQuery } from "react-query";
import { client } from "../client";

export function useCheckIsSubUser() {
  const { data, isLoading, isError, isSuccess } = useQuery(
    "userIsSubUser",
    () => client("auth/is-sub-user")
  );
  return {
    isSubUser: data?.isSubUser ?? false,
    isLoading,
    isError,
    isSuccess,
  };
}
