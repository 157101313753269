/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import Input from "component/Input";
import NumberInput from "component/NumberInput";
import PercentageInput from "component/PercentageInput";
import { InformationPoint } from "component/InformationPoint";
import Select from "component/Select";
import CurrentStepVisualiser from "./CurrentStepVisualiser";
import Button from "component/Button";
import Header from "component/StepHeader";
import {
  useFormContext,
  Controller,
  useFieldArray,
  get,
} from "react-hook-form";
import { DrugOrigin, ProductionCountry } from "utils/drugOrigin";
import { useCountries, Country } from "utils/hooks/countries.hook";
import { ReactComponent as TrashIcon } from "assets/svg/Trash.svg";
import { ReactComponent as AddIcon } from "assets/svg/Add.svg";
import _ from "lodash";
import { intRegex } from "utils/regex";

function Step1({
  handleStep,
  handleSaveBeforeLeave,
}: {
  handleStep: (type: "decrement" | "increment") => void;
  handleSaveBeforeLeave: (path: string) => void;
}) {
  const theme = useTheme();
  const { countries } = useCountries();
  const {
    getValues,
    setError,
    register,
    control,
    trigger,
    formState: { errors },
  } = useFormContext<DrugOrigin>();
  const {
    fields: productionCountriesDrugFormulationFields,
    append: appendDrugFormulationFields,
    remove: removeDrugFormulationFields,
  } = useFieldArray({
    control,
    name: "productionCountriesDrugFormulation",
  });
  const {
    fields: productionCountriesPrimaryPackagingFields,
    append: appendPackagingFields,
    remove: removePackagingFields,
  } = useFieldArray({
    control,
    name: "productionCountriesPrimaryPackaging",
  });

  const handleNext = async () => {
    const isStepValid = await trigger();
    if (isStepValid) {
      const productionCountriesDrugFormulation = getValues(
        "productionCountriesDrugFormulation"
      );
      const drugFormulationPercentageTotal = _.reduce(
        productionCountriesDrugFormulation,
        (sum: number, n: ProductionCountry) => {
          const parsedPercentage = n.percentage ? parseInt(n.percentage) : NaN;
          if (isNaN(parsedPercentage)) return 0;
          else return sum + parsedPercentage;
        },
        0
      );
      const productionCountriesPrimaryPackaging = getValues(
        "productionCountriesPrimaryPackaging"
      );
      const packagingPercentageTotal = _.reduce(
        productionCountriesPrimaryPackaging,
        (sum: number, n: ProductionCountry) => {
          const parsedPercentage = n.percentage ? parseInt(n.percentage) : NaN;
          if (isNaN(parsedPercentage)) return 0;
          else return sum + parsedPercentage;
        },
        0
      );
      if (
        drugFormulationPercentageTotal !== 100 ||
        packagingPercentageTotal !== 100
      ) {
        if (drugFormulationPercentageTotal !== 100)
          setError("productionCountriesDrugFormulation.0.countryId", {
            type: "manual",
            message: "The total percentage has to be equal to 100",
          });
        if (packagingPercentageTotal !== 100)
          setError("productionCountriesPrimaryPackaging.0.countryId", {
            type: "manual",
            message: "The total percentage has to be equal to 100",
          });
      } else {
        handleStep("increment");
      }
    }
  };

  const appendFieldProductionDrugFormulation = () => {
    if (productionCountriesDrugFormulationFields.length < 3)
      appendDrugFormulationFields({ countryId: null, percentage: null });
  };

  const removeFieldProductionDrugFormulation = (index: number) => {
    if (productionCountriesDrugFormulationFields.length > 1)
      removeDrugFormulationFields(index);
  };

  const appendFieldProductionPackaging = () => {
    if (productionCountriesPrimaryPackagingFields.length < 3)
      appendPackagingFields({ countryId: null, percentage: null });
  };

  const removeFieldProductionPackaging = (index: number) => {
    if (productionCountriesPrimaryPackagingFields.length > 1)
      removePackagingFields(index);
  };

  return (
    <div
      css={{
        backgroundColor: theme.white,
        padding: "50px 56px",
        color: theme.black,
        display: "grid",
        gridTemplateColumns: "auto 268px",
        columnGap: "30px",
      }}
    >
      <div>
        <Header
          title="Label of origin for a drug product"
          stepName="Step 1"
          stepTitle="Identify your drug product"
          backName="Back Home"
          backAction={() => handleSaveBeforeLeave("/home")}
        />

        <p
          css={{
            color: theme.darkGrey,
            fontSize: "12px",
            lineHeight: "18px",
            marginTop: "0px",
            marginBottom: "24px",
          }}
        >
          *required fields
        </p>

        <div css={{ display: "flex", marginBottom: "16px" }}>
          <Input
            label="Drug product name*"
            placeholder="Brandname 100mg, capsule"
            register={register}
            registerOption={{ required: `Drug product name can't be empty` }}
            name="drugProductName"
            error={errors.drugProductName && errors.drugProductName.message}
            css={{
              maxWidth: "400px",
            }}
          />
          <InformationPoint
            css={{
              marginTop: "40px",
              marginLeft: "16px",
            }}
            text={`For France, this is the "dénomination de la spécialité pharmaceutique"`}
          />
        </div>

        <div css={{ display: "flex", marginBottom: "16px" }}>
          <Controller
            control={control}
            name="commercializationCountry"
            rules={{ required: `Commercialization country can't be empty` }}
            render={({
              field: { onChange, value, ref },
              fieldState: { error },
            }) => (
              <Select
                onChange={(data: any) => onChange(data ? data.id : null)}
                options={countries}
                inputRef={ref}
                label="Commercialization country*"
                placeholder="Select a country"
                getOptionLabel={(option: any) => option.name}
                value={
                  countries && countries.find((el: Country) => el.id === value)
                }
                error={error?.message}
                css={{
                  maxWidth: "400px",
                }}
              />
            )}
          />
          <InformationPoint
            css={{
              marginTop: "40px",
              marginLeft: "16px",
            }}
            text={`Country where the drug product is sold. The label of origin is done for a single country of commercialization`}
          />
        </div>

        <div css={{ display: "flex", marginBottom: "16px" }}>
          <Input
            label="Drug product code*"
            placeholder="12345678"
            register={register}
            registerOption={{ required: `Drug product code can't be empty` }}
            name="drugProductCode"
            error={errors.drugProductCode && errors.drugProductCode.message}
            css={{
              maxWidth: "400px",
            }}
          />
          <InformationPoint
            css={{
              marginTop: "40px",
              marginLeft: "16px",
            }}
            text={`For France, this is the CIS code`}
          />
        </div>

        {productionCountriesDrugFormulationFields.map((field, index) => (
          <div key={field.id} css={{ display: "flex", marginBottom: "16px" }}>
            <Controller
              control={control}
              name={`productionCountriesDrugFormulation.${index}.countryId`}
              defaultValue={field.countryId}
              rules={{
                required: `Production country for drug formulation can't be empty`,
              }}
              render={({
                field: { onChange, value, ref },
                fieldState: { error },
              }) => (
                <Select
                  onChange={(data: any) => onChange(data.id)}
                  options={countries}
                  inputRef={ref}
                  label="Production country for drug formulation*"
                  placeholder="Select a country"
                  getOptionLabel={(option: any) => option.name}
                  value={
                    countries &&
                    countries.find((el: Country) => el.id === value)
                  }
                  error={
                    error?.message ||
                    get(
                      errors,
                      `productionCountriesDrugFormulation.${index}.percentage`
                    )?.message
                  }
                  css={{
                    maxWidth: "304px",
                  }}
                />
              )}
            />
            <PercentageInput
              css={{
                marginTop: "25px",
                marginLeft: "16px",
                marginRight: "16px",
              }}
              name={`productionCountriesDrugFormulation.${index}.percentage`}
              placeholder="...."
              register={register}
              registerOption={{
                required: `Percentage can't be empty`,
                validate: {
                  isNumber: (value: string) =>
                    !isNaN(parseInt(value)) || `Percentage must be a number`,
                  isBiggerThan100: (value: string) =>
                    parseInt(value) <= 100 || `Percentage can't be above 100`,
                  isLowerThan100: (value: string) =>
                    parseInt(value) > 0 || `Percentage can't be 0 or under`,
                },
              }}
            />
            {index === 0 ? (
              <InformationPoint
                css={{
                  marginTop: "40px",
                  marginBottom: "16px",
                }}
                text={`Country where the drug substance(s) and excipients are formulated, to end up to the pharmaceutical form of the drug product`}
              />
            ) : (
              <button
                onClick={() => removeFieldProductionDrugFormulation(index)}
                type="button"
                css={{
                  marginTop: "36px",
                  border: "none",
                  background: "transparent",
                  cursor: "pointer",
                  padding: 0,
                  width: "20px",
                  height: "24px",
                  svg: {
                    width: "100%",
                  },
                }}
              >
                <TrashIcon />
              </button>
            )}
          </div>
        ))}

        {productionCountriesDrugFormulationFields.length < 3 && (
          <button
            css={{
              cursor: "pointer",
              margin: "20px 0px",
              background: "transparent",
              border: "none",
              display: "flex",
              alignItems: "center",
              padding: 0,
              color: theme.black,
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "17px",
              svg: {
                marginRight: "12px",
              },
            }}
            type="button"
            onClick={appendFieldProductionDrugFormulation}
          >
            <AddIcon />
            Add a country
          </button>
        )}

        <div css={{ marginTop: "30px" }} />

        {/* packaging */}
        {productionCountriesPrimaryPackagingFields.map((field, index) => (
          <div key={field.id} css={{ display: "flex", marginBottom: "16px" }}>
            <Controller
              control={control}
              name={`productionCountriesPrimaryPackaging.${index}.countryId`}
              defaultValue={field.countryId}
              rules={{
                required: `Production country for primary packaging can't be empty`,
              }}
              render={({
                field: { onChange, value, ref },
                fieldState: { error },
              }) => (
                <Select
                  onChange={(data: any) => onChange(data.id)}
                  options={countries}
                  inputRef={ref}
                  label="Production country for primary packaging*"
                  labelStyle={{ fontSize: "13px" }}
                  placeholder="Select a country"
                  getOptionLabel={(option: any) => option.name}
                  value={
                    countries &&
                    countries.find((el: Country) => el.id === value)
                  }
                  error={
                    error?.message ||
                    get(
                      errors,
                      `productionCountriesPrimaryPackaging.${index}.percentage`
                    )?.message
                  }
                  css={{
                    maxWidth: "304px",
                  }}
                />
              )}
            />
            <PercentageInput
              css={{
                marginTop: "25px",
                marginLeft: "16px",
                marginRight: "16px",
              }}
              name={`productionCountriesPrimaryPackaging.${index}.percentage`}
              placeholder="...."
              register={register}
              registerOption={{
                required: `Percentage can't be empty`,
                validate: {
                  isNumber: (value: string) =>
                    !isNaN(parseInt(value)) || `Percentage must be a number`,
                  isBiggerThan100: (value: string) =>
                    parseInt(value) <= 100 || `Percentage can't be above 100`,
                  isLowerThan100: (value: string) =>
                    parseInt(value) > 0 || `Percentage can't be 0 or under`,
                },
              }}
            />
            {index === 0 ? (
              <InformationPoint
                css={{
                  marginTop: "40px",
                  marginBottom: "16px",
                }}
                text={`Country where the primary packaging of the drug product is realized`}
              />
            ) : (
              <button
                onClick={() => removeFieldProductionPackaging(index)}
                type="button"
                css={{
                  marginTop: "36px",
                  border: "none",
                  background: "transparent",
                  cursor: "pointer",
                  padding: 0,
                  width: "20px",
                  height: "24px",
                  svg: {
                    width: "100%",
                  },
                }}
              >
                <TrashIcon />
              </button>
            )}
          </div>
        ))}

        {productionCountriesPrimaryPackagingFields.length < 3 && (
          <button
            css={{
              cursor: "pointer",
              margin: "20px 0px",
              background: "transparent",
              border: "none",
              display: "flex",
              alignItems: "center",
              padding: 0,
              color: theme.black,
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "17px",
              svg: {
                marginRight: "12px",
              },
            }}
            type="button"
            onClick={appendFieldProductionPackaging}
          >
            <AddIcon />
            Add a country
          </button>
        )}

        <div css={{ display: "flex", marginBottom: "16px" }}>
          <Input
            label="HS code*"
            placeholder="30049000"
            register={register}
            name="drugProductCodeHs"
            error={errors.drugProductCodeHs && errors.drugProductCodeHs.message}
            registerOption={{
              required: `HS code can't be empty`,
              minLength: {
                value: 6,
                message: "HS code must be at least 6 digits long",
              },
              validate: {
                number: (value: string) =>
                  intRegex.test(value) || `HS code is number only`,
              },
            }}
            css={{
              maxWidth: "400px",
            }}
          />
          <InformationPoint
            css={{
              marginTop: "40px",
              marginLeft: "16px",
            }}
            text={`At least the 6 first digits have to be mentioned`}
          />
        </div>

        <div
          css={{ display: "flex", marginBottom: "16px", alignItems: "center" }}
        >
          <Controller
            control={control}
            name="apiCount"
            defaultValue={1}
            render={({ field: { onChange, value, name, ref } }) => (
              <NumberInput
                minValue={1}
                title="Number of drug substance*"
                onChange={onChange}
                value={value}
              />
            )}
          />

          <InformationPoint
            css={{
              marginLeft: "16px",
            }}
            text={`Mention how many drug substances (active pharmaceutical ingredients) are included in this drug product. Excipients should not be included`}
          />
        </div>
      </div>

      <div>
        <CurrentStepVisualiser step={1} />
        <Button variant="dark" disabled={false} onClick={handleNext}>
          Next step
        </Button>
      </div>
    </div>
  );
}

export default Step1;
