/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import Input from 'component/Input';
import Button from 'component/Button';
import { Link } from 'react-router-dom';
import Logo from 'assets/Logo.png';
import BackgroundImage from 'assets/background.jpg';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useResetPassword } from 'utils/hooks/auth.hook';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { emailRegex } from 'utils/regex';

export type FormResetPasswordData = {
    code: string
    email: string,
    password: string,
}

function SetPassword() {
    const theme = useTheme();
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState('');
    const { mutate, isLoading, isIdle, isError, isSuccess } = useResetPassword(setErrorMessage);
    const { register, handleSubmit, formState: { isValid, errors } } = useForm<FormResetPasswordData>({
        mode: "onChange"
    });

    const onSubmit = (data: FormResetPasswordData) => {
        mutate(data);
    }

    return (
        <div
            css={{
                width: '100%',
                minHeight: '100vh',
                backgroundColor: theme.white,
                color: theme.black,
                display: 'flex',
            }}
        >
            <form
                css={{
                    width: '504px',
                    padding: '80px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                onSubmit={handleSubmit(onSubmit)}
            >
                <img 
                    src={Logo} 
                    alt="Logo" 
                    onClick={() => {
                        history.push('/login')
                    }}
                    css={{
                        height: '66px',
                        width: '200px',
                        cursor: 'pointer',
                    }} 
                />
                
                <h1 css={{
                    fontSize: '24px',
                    lineHeight: '28px',
                    fontWeight: 600,
                    marginTop: '40px',
                    marginBottom: '0px',
                }}
                >Reset your password</h1>

                <h4 css={{
                    fontSize: '14px',
                    lineHeight: '18px',
                    fontWeight: 'normal',
                    color: theme.darkGrey,
                    marginTop: '24px',
                    marginBottom: '40px',
                }}>Provide the 6 digits code that we send you by email</h4>

                <Input
                    label="Code"
                    placeholder="Code"
                    name="code"
                    autoComplete="one-time-code"
                    register={register}
                    registerOption={{
                        required: true,
                    }}
                    error={errors.code && errors.code.message}
                    css={{
                        marginBottom: '24px'
                    }}
                />

                <Input
                    label="Email"
                    placeholder="Email"
                    register={register}
                    registerOption={{
                        required: `Email can't be empty`,
                        pattern: {
                            value: emailRegex,
                            message: 'Incorrect email',
                        },
                    }}
                    autoComplete="email"
                    name="email"
                    error={errors.email && errors.email.message}
                    css={{
                        marginBottom: '24px'
                    }}
                />

                <Input
                    label="Password"
                    placeholder="Password"
                    name="password"
                    autoComplete="new-password"
                    register={register}
                    registerOption={{
                        required: `Password can't be empty`,
                    }}
                    error={errors.password && errors.password.message}
                    type="password"
                    css={{
                        marginBottom: '24px'
                    }}
                />

                {isSuccess &&
                <p
                    css={{
                        fontSize: '14px',
                        lineHeight: '18px',
                        marginTop: '48px',
                        marginBottom: '40px',
                        textAlign: 'center',
                        color: theme.black,
                        'a': {
                            textDecoration: 'underline',
                            color: theme.black,
                            fontWeight: 600,
                        }
                    }}
                >Your password is changed ! <Link to="login">Log in</Link></p>
                }

                {errorMessage &&
                    <p
                        css={{
                            color: theme.error,
                            fontSize: '14px',
                            marginBottom: '-30px'
                        }}
                    >{errorMessage}</p>
                }


                {(isIdle || isError) &&
                    <Button
                        disabled={!isValid}
                        type="submit"
                        css={{
                            marginTop: '48px',
                            marginBottom: '40px',
                        }}
                    >Reset password</Button>
                }

                {isLoading &&
                    <CircularProgress css={{ 
                        margin: 'auto',
                        marginTop: '48px',
                        marginBottom: '40px',
                        'circle': {
                            stroke: theme.primary
                        }
                    }} />
                }
            </form>
            <div
                css={{
                    flexGrow: 1,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'none',
                    backgroundImage: `url(${BackgroundImage})`,
                }}
            />
        </div>
    );
}

export default SetPassword;