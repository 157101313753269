/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import { Link } from 'react-router-dom';
import { useAdminGetOrigins, useAdminGetCarbons, useAdminGetClients, useAdminGetSubstances, useAdminDeleteOrigin, useAdminDeleteSubstance, useAdminDeleteCarbon } from 'utils/hooks/admin.hook';
import StatusButton from 'component/StatusButton';
import DeleteDialog from 'component/DeleteDialog';
import Select from 'component/Select';
import { useState } from 'react';
import _ from 'lodash';
import Navbar from './Navbar';
import { ReactComponent as TrashIcon } from 'assets/svg/Trash.svg';

const statusOptions = [
    { label: 'Draft', value: 'DRAFT' },
    { label: 'Ongoing', value: 'ONGOING' },
    { label: 'Check', value: 'CHECK' },
    { label: 'Completed', value: 'COMPLETED' },
];

const projectTypeOptions = [
    { label: 'Drug Product', value: 'ORIGIN' },
    { label: 'Drug Substance', value: 'SUBSTANCE' },
    { label: 'Carbon Footprint', value: 'CARBON' },
];

type FilterState = {
    clientId: number | null,
    status: string | null,
    type: string | null,
}

function Admin() {
    const [filterState, setFilterState] = useState<FilterState>({
        clientId: null,
        status: null,
        type: null,
    });
    const theme = useTheme();
    const [deleteAction, setDeleteAction] = useState<null | (() => void)>(null);
    const { origins } = useAdminGetOrigins();
    const { carbons } = useAdminGetCarbons();
    const { substances } = useAdminGetSubstances();
    const { clients } = useAdminGetClients();
    const { mutate: deleteOrigin } = useAdminDeleteOrigin();
    const { mutate: deleteCarbon } = useAdminDeleteCarbon();
    const { mutate: deleteSubstance } = useAdminDeleteSubstance();

    const handleDeleteOrigin = (originId: number) => {
        setDeleteAction(() => () => deleteOrigin(originId));
    }
    
    const handleDeleteSubstance = (substanceId: number) => {
        setDeleteAction(() => () => deleteSubstance(substanceId));
    }
    
    const handleDeleteCarbon = (carbonId: number) => {
        setDeleteAction(() => () => deleteCarbon(carbonId));
    }

    const close = () => setDeleteAction(null);

    const originsFiltered = _.filter(origins, (el) => {
        if (filterState.type !== null && filterState.type !== 'ORIGIN')
            return false;
        let matchClientId = true;
        let matchStatus = true;
        if (filterState.clientId)
            matchClientId = filterState.clientId === el.clientId;
        if (filterState.status)
            matchStatus = filterState.status === el.status;
        return (matchClientId && matchStatus);
    });

    const substancesFiltered = _.filter(substances, (el) => {
        if (filterState.type !== null && filterState.type !== 'SUBSTANCE')
            return false;
        let matchClientId = true;
        let matchStatus = true;
        if (filterState.clientId)
            matchClientId = filterState.clientId === el.clientId;
        if (filterState.status)
            matchStatus = filterState.status === el.status;
        return (matchClientId && matchStatus);
    });

    const carbonsFiltered = _.filter(carbons, (el) => {
        if (filterState.type !== null && filterState.type !== 'CARBON')
            return false;
        let matchClientId = true;
        let matchStatus = true;
        if (filterState.clientId)
            matchClientId = filterState.clientId === el.clientId;
        if (filterState.status) {
            if (filterState.status === 'DRAFT') {
                if (el.status === 'DRAFT' || el.status === 'DRAFT2')
                    matchStatus = true;
                else 
                    matchStatus = false;
            }
            if (filterState.status === 'ONGOING') {
                if (el.status === 'ONGOING' || el.status === 'ONGOING2')
                    matchStatus = true;
                else 
                    matchStatus = false;
            }
            if (filterState.status === 'CHECK') {
                if (el.status === 'CHECK' || el.status === 'CHECK2')
                    matchStatus = true;
                else 
                    matchStatus = false;
            }
        }
        return (matchClientId && matchStatus);
    });

    return (
        <>
        <DeleteDialog 
            close={close}
            isOpen={deleteAction ? true : false}
            action={deleteAction}
        />
        <div
            css={{
                width: '100%',
                minHeight: '100vh',
                backgroundColor: theme.white,
                color: theme.black,
                display: 'flex',
            }}
        >
            <Navbar />
            <div
                css={{
                    flexGrow: 1,
                    padding: '50px 56px',
                    overflowY: 'auto',
                    width: '100%',
                    maxHeight: '100vh',
                }}
            >
                <h2 css={{
                    fontSize: '24px', 
                    lineHeight: '28px', 
                    fontWeight: 600, 
                    marginTop: '0px', 
                    marginBottom: '16px'
                }}
                >Admin Panel</h2>

                <p css={{
                    color: theme.black, 
                    fontSize: '14px',
                    lineHeight: '18px',
                    marginTop: '0px',
                }}
                >Here you will find all the project of Ecovamed's clients</p>

                <div css={{
                    flexGrow: 1,
                    'table': {
                        width: '100%',
                    }
                }}>
                    <div css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '20px 0px'
                    }}>
                        <Select
                            label="Client Company"
                            placeholder="Choose client company"
                            isClearable={true}
                            options={clients}
                            getOptionLabel={(option: any) => option.companyName}
                            onChange={(data: any) => setFilterState({ ...filterState, clientId: data ? data.id : null })}
                            css={{
                                maxWidth: '300px',
                            }}
                        />
                        <Select
                            label="Project Status"
                            placeholder="Choose status"
                            isClearable={true}
                            options={statusOptions}
                            onChange={(data: any) => setFilterState({ ...filterState, status: data ? data.value : null })}
                            css={{
                                maxWidth: '300px',
                            }}
                        />
                        <Select
                            label="Project Type"
                            placeholder="Choose type"
                            isClearable={true}
                            options={projectTypeOptions}
                            onChange={(data: any) => setFilterState({ ...filterState, type: data ? data.value : null })}
                            css={{
                                maxWidth: '300px',
                            }}
                        />
                    </div>
                    <table
                        css={{
                            textAlign: 'center',
                            borderCollapse: 'collapse',
                            'tr, th, td': {
                                fontWeight: 500,
                                fontSize: '14px',
                                lineHeight: '17px',
                            },
                            'tr th, tr td': {
                                border: `1px solid ${theme.primary}`,
                                height: '50px',
                                color: theme.black,
                            },
                            'tr th': {
                                backgroundColor: theme.primary,
                                color: theme.white,
                            },
                            'tr td a': {
                                color: theme.black,
                                fontWeight: 600,
                                textDecoration: 'underline',
                            },
                            '.type': {
                                width: '150px',
                            },
                            '.status, .document, .access': {
                                width: '120px',
                            },
                        }}
                    >
                        <thead>
                            <tr>
                                <th>Project name</th>
                                <th>Client Name</th>
                                <th className="type">Type</th>
                                <th className="status">Status</th>
                                <th className="document">Document</th>
                                <th className="access">Access</th>
                            </tr>
                        </thead>
                        <tbody>
                            {originsFiltered.map((origin: any) => (
                                <tr key={`origin_${origin.id}`}>
                                    <td>{origin.drugProductName}</td>
                                    <td>{origin.client.companyName}</td>
                                    <td>Labeling drug product</td>
                                    <td><StatusButton status={origin.status} /></td>
                                    <td>{origin.reportUrl ?
                                        <a href={origin.reportUrl} rel="noreferrer" target="_blank">report</a> :
                                        '-'}
                                    </td>
                                    <td>
                                        {(origin.status === 'ONGOING' || origin.status === 'CHECK') ?
                                            <Link to={`/admin/drug_origin/${origin.id}`}>edit</Link> :
                                            '-'
                                        }
                                    </td>
                                    {(origin.status === 'ONGOING' || origin.status === 'CHECK') &&
                                    <td 
                                        onClick={() => handleDeleteOrigin(origin.id)}
                                        css={{
                                            cursor: 'pointer',
                                            border: 'none !important',
                                            padding: '0px 4px',
                                        }}
                                    ><TrashIcon /></td>
                                    }
                                </tr>
                            ))}
                            {substancesFiltered.map((substance: any) => (
                                <tr key={`substance_${substance.id}`}>
                                    <td>{substance.inn}</td>
                                    <td>{substance.client.companyName}</td>
                                    <td>Labeling drug substance</td>
                                    <td><StatusButton status={substance.status} /></td>
                                    <td>{substance.reportUrl ?
                                        <a href={substance.reportUrl} rel="noreferrer" target="_blank">report</a> :
                                        '-'}
                                    </td>
                                    <td>
                                        {(substance.status === 'ONGOING' || substance.status === 'CHECK') ?
                                            <Link to={`/admin/drug_substance/${substance.id}`}>edit</Link> :
                                            '-'
                                        }
                                    </td>
                                    {(substance.status === 'ONGOING' || substance.status === 'CHECK') &&
                                    <td 
                                        onClick={() => handleDeleteSubstance(substance.id)}
                                        css={{
                                            cursor: 'pointer',
                                            border: 'none !important',
                                            padding: '0px 4px',
                                        }}
                                    ><TrashIcon /></td>
                                    }
                                </tr>
                            ))}
                            {carbonsFiltered.map((carbon: any) => (
                                <tr key={`carbon_${carbon.id}`}>
                                    <td>{carbon.name}</td>
                                    <td>{carbon.client.companyName}</td>
                                    <td>Carbon Footprint</td>
                                    <td><StatusButton status={carbon.status} /></td>
                                    <td>{carbon.reportUrl ?
                                        <a href={carbon.reportUrl} rel="noreferrer" target="_blank">report</a> :
                                        '-'}
                                    </td>
                                    <td>
                                        {(carbon.status === 'ONGOING' || carbon.status === 'CHECK') ?
                                            <Link to={`/admin/carbon_footprint/first_part/${carbon.id}`}>edit</Link> :
                                                (carbon.status === 'ONGOING2' || carbon.status === 'CHECK2') ?
                                                <Link to={`/admin/carbon_footprint/second_part/${carbon.id}`}>edit</Link> :
                                                '-'
        
                                        }
                                    </td>
                                    {(carbon.status === 'ONGOING' || carbon.status === 'CHECK' || carbon.status === 'ONGOING2' || carbon.status === 'CHECK2') &&
                                    <td 
                                        onClick={() => handleDeleteCarbon(carbon.id)}
                                        css={{
                                            cursor: 'pointer',
                                            border: 'none !important',
                                            padding: '0px 4px',
                                        }}
                                    ><TrashIcon /></td>
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        </>
    );
}

export default Admin;