import { useMutation, useQuery, useQueryClient } from 'react-query';
import { DrugOrigin } from 'utils/drugOrigin';
import { client } from 'utils/client';

export function useGetOrigins() {
    const { data: origins , isLoading, isError, isSuccess } = useQuery(
        'origins',
        () => client(`origin`)
    );
    return {
        origins: origins ?? [],
        isLoading,
        isError,
        isSuccess,
    }
}

export function useGetOriginsCompleted() {
    const { data, isLoading, isError, isSuccess } = useQuery(
        'origins_completed',
        () => client(`origin/completed`)
    );
    return {
        origins: data ?? [],
        isLoading,
        isError,
        isSuccess,
    }
}

export function useGetOrigin(id: string) {
    const { data: origin , isLoading, isError, isSuccess } = useQuery(
        ['origin', id],
        () => id ? client(`origin/${id}`) : null
    );
    return {
        origin: origin ?? null,
        isLoading,
        isError,
        isSuccess,
    }
}

export function useCreateOrValidateOrigin(id: string) {
    const queryClient = useQueryClient();
    const { mutate, ...rest } = useMutation(
        (state: DrugOrigin) => id ? client(`origin/validate/${id}`, state) : client(`origin/create`, state),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('origins');
                if (id) queryClient.invalidateQueries(['origin', id]);
            }
        }
    );
    return { mutate, ...rest };
}

export function useCreateNotCompleteOrigin() {
    const queryClient = useQueryClient();
    const { mutate, ...rest } = useMutation(
        (state: DrugOrigin) => client(`origin/draft/create`, state),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('origins');
            }
        }
    );
    return { mutate, ...rest };
}

export function useUpdateOrigin(id: string) {
    const queryClient = useQueryClient();
    const { mutate, ...rest } = useMutation(
        (state: DrugOrigin) => client(`origin/update/${id}`, state),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('origins');
            }
        }
    );
    return { mutate, ...rest };
}

export function useDeleteOrigin() {
    const queryClient = useQueryClient();
    const { mutate, ...rest } = useMutation(
        (id: number) => client(`origin/${id}`, {}, {
            method: 'DELETE'
        }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('origins');
            }
        }
    );
    return { mutate, ...rest };
}
