/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import Input from 'component/Input';
import { InformationPoint } from 'component/InformationPoint';
import Select from 'component/Select';
import CurrentStepVisualiser from './CurrentStepVisualiser';
import Button from 'component/Button';
import Header from 'component/StepHeader';
import { useFormContext, Controller, get, useFieldArray } from 'react-hook-form';
import { DrugOrigin } from 'utils/drugOrigin';
import { Fragment, useEffect, useState } from 'react';
import { intRegex } from 'utils/regex';
import { TypeOptions } from 'utils/enums';

function Step2({ handleStep }: {
    handleStep: (type: "decrement" | "increment") => void;
}) {
    const theme = useTheme();
    const [apiIndex, setApiIndex] = useState(0);
    const { register, control, trigger, getValues, formState: { errors } } = useFormContext<DrugOrigin>();
    const { fields, append, remove } = useFieldArray({
        control,
        name: "api",
    });

    const apiCount = getValues('apiCount');

    const handlePrevious = () => {
        if (apiIndex === 0) handleStep('decrement');
        else setApiIndex(index => index - 1);
    }

    const handleNext = async () => {
        const isStepValid = await trigger();
        if (isStepValid) {
            if ((apiIndex + 1) === apiCount) {
                const apiValues = getValues('api');
                // remove the surplus of api (if the apiCount has changed)
                for (let i = apiCount; i < apiValues.length; i++) {
                    remove(i);
                }
                handleStep('increment');
            }
            else setApiIndex(index => index + 1);
        }
    };

    useEffect(() => {
        const currentValue = getValues(`api.${apiIndex}`);
        if (!currentValue)
            append({ inn: '', cas: '', codeHs: '', type: null });
    }, [append, getValues, apiIndex])

    return (
        <div
            css={{  
                backgroundColor: theme.white,
                padding: '50px 56px',
                color: theme.black,
                display: 'grid',
                gridTemplateColumns: 'auto 268px',
                columnGap: '30px',
            }}
        >
            <div>
                <Header
                    title="Label of origin for a drug product"
                    stepName="Step 2"
                    stepTitle={`Identify your drug substance #${apiIndex + 1}`}
                    backName="Previous step"
                    backAction={handlePrevious}
                />
                
                <p css={{ color: theme.darkGrey,
                    fontSize: '12px',
                    lineHeight: '18px',
                    marginTop: '0px',
                    marginBottom: '24px'
                }}>*required fields</p>

                {fields.map((field, index) => (index === apiIndex) && (
                    <Fragment key={field.id}>
                    <div css={{ display: 'flex', marginBottom: '16px' }}>
                        <Input
                            label="INN*"
                            placeholder="Ibuprofen"
                            register={register} 
                            defaultValue=""
                            registerOption={{ required: `INN can't be empty` }}
                            name={`api.${apiIndex}.inn`}
                            error={get(errors, `api.${apiIndex}.inn`)?.message}
                            css={{
                                maxWidth: '400px',
                            }}
                        />
                        <InformationPoint
                            css={{
                                marginTop: '40px',
                                marginLeft: '16px',
                            }}
                            text={`International Nonproprietary Name`}
                        />
                    </div>

                    <div css={{ display: 'flex', marginBottom: '16px' }}>
                        <Input
                            label="CAS number*"
                            placeholder="15687-27-1"
                            register={register}
                            defaultValue=""
                            registerOption={{ required: `CAS number can't be empty` }}
                            name={`api.${apiIndex}.cas`}
                            error={get(errors, `api.${apiIndex}.cas`)?.message}
                            css={{
                                maxWidth: '400px',
                            }}
                        />
                        <InformationPoint
                            css={{
                                marginTop: '40px',
                                marginLeft: '16px',
                            }}
                            text={`CAS Registry Number assigned by the Chemical Abstracts Service`}
                        />
                    </div>

                    <div css={{ display: 'flex', marginBottom: '16px' }}>
                        <Controller
                            control={control}
                            name={`api.${apiIndex}.type`}
                            defaultValue={null}
                            rules={{ required: `Type can't be empty` }}
                            render={({
                                field: { onChange, value, ref },
                                fieldState: { error },
                            }) => (
                                <Select
                                    onChange={(data: any) => onChange(data.value)}
                                    options={TypeOptions}
                                    inputRef={ref}
                                    label="Type*"
                                    placeholder="Select a type"
                                    getOptionLabel={(option: any) => option.label}
                                    value={TypeOptions.find((el: any) => el.value === value)}
                                    error={error?.message}
                                    css={{
                                        maxWidth: '400px',
                                    }}
                                />
                            )}
                        />
                        <InformationPoint
                            css={{
                                marginTop: '40px',
                                marginLeft: '16px',
                            }}
                            text={`Select among Chemical (synthetic product), Biologic or Natural substance (vegetal, animal or mineral)`}
                        />
                    </div>

                    <div css={{ display: 'flex', marginBottom: '16px' }}>
                        <Input
                            label="HS code*"
                            placeholder="29420012"
                            register={register}
                            defaultValue=""
                            name={`api.${apiIndex}.codeHs`}
                            error={get(errors, `api.${apiIndex}.codeHs`)?.message}
                            registerOption={{
                                required: `HS code can't be empty`,
                                minLength: { value: 6, message: 'HS code must be at least 6 digits long' },
                                validate: {
                                    number: (value: string) => intRegex.test(value) || `HS code is number only`,
                                }
                            }}
                            css={{
                                maxWidth: '400px',
                            }}
                        />
                        <InformationPoint
                            css={{
                                marginTop: '40px',
                                marginLeft: '16px',
                            }}
                            text={`At least the 6 first digits have to be mentioned`}
                        />
                    </div>
                    </Fragment>
                ))}
            </div>
            
            <div>
                <CurrentStepVisualiser step={2} />
                <Button
                    variant="dark"
                    disabled={false}
                    onClick={handleNext}
                >Next step</Button>
            </div>
        </div>
    );
}

export default Step2;