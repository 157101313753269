import { UseFormSetValue } from "react-hook-form";

type RefrigerantGases = {
  value: string;
  type: string | null;
};

type VolatileOrganicCompounds = {
  name: string;
  quantity: string;
};

export interface Plant {
  companyName: string;
  reportingYear: string | null;
  countryId: number | null;
  city: string;
  coolingWaterUnit: string;
  coolingWaterValue: string;
  capexUnit: string;
  capexValue: string;
  comments: string;
  employees: string;
  energyConsumptionCoalUnit: string;
  energyConsumptionCoalValue: string;
  energyConsumptionElectricityUnit: string;
  energyConsumptionElectricityValue: string;
  energyConsumptionFuelOilUnit: string;
  energyConsumptionFuelOilValue: string;
  energyConsumptionGasolineUnit: string;
  energyConsumptionGasolineValue: string;
  energyConsumptionHeatUnit: string;
  energyConsumptionHeatValue: string;
  energyConsumptionLiquidNitrogenUnit: string;
  energyConsumptionLiquidNitrogenValue: string;
  energyConsumptionNaturalGasUnit: string;
  energyConsumptionNaturalGasValue: string;
  energyConsumptionSteamUnit: string;
  energyConsumptionSteamValue: string;
  energyProductionUnit: string;
  energyProductionValue: string;
  productionFPValue: string;
  productionIntFPValue: string;
  scope1Unit: string;
  scope1Value: string;
  scope2Unit: string;
  scope2Value: string;
  refrigerantGases: RefrigerantGases[];
  volatileOrganicCompounds: VolatileOrganicCompounds[];
}

type RefrigerantGasesParsed = {
  value: number | null;
  type: string | null;
};

type VolatileOrganicCompoundsParsed = {
  name: string | null;
  quantity: number | null;
};

export interface PlantParsed {
  companyName: string;
  reportingYear: string | null;
  countryId: number | null;
  city: string;
  coolingWaterUnit: string;
  coolingWaterValue: number | null;
  capexUnit: string;
  capexValue: number | null;
  comments: string;
  employees: number | null;
  energyConsumptionCoalUnit: string;
  energyConsumptionCoalValue: number | null;
  energyConsumptionElectricityUnit: string;
  energyConsumptionElectricityValue: number | null;
  energyConsumptionFuelOilUnit: string;
  energyConsumptionFuelOilValue: number | null;
  energyConsumptionGasolineUnit: string;
  energyConsumptionGasolineValue: number | null;
  energyConsumptionHeatUnit: string;
  energyConsumptionHeatValue: number | null;
  energyConsumptionLiquidNitrogenUnit: string;
  energyConsumptionLiquidNitrogenValue: number | null;
  energyConsumptionNaturalGasUnit: string;
  energyConsumptionNaturalGasValue: number | null;
  energyConsumptionSteamUnit: string;
  energyConsumptionSteamValue: number | null;
  energyProductionUnit: string;
  energyProductionValue: number | null;
  productionFPValue: number | null;
  productionIntFPValue: number | null;
  scope1Unit: string;
  scope1Value: number | null;
  scope2Unit: string;
  scope2Value: number | null;
  refrigerantGases: RefrigerantGasesParsed[];
  volatileOrganicCompounds: VolatileOrganicCompoundsParsed[];
}

export const parsePlant = (data: Plant): PlantParsed => {
  return {
    ...data,
    capexValue: parseFloat(data.capexValue) || null,
    coolingWaterValue: parseFloat(data.coolingWaterValue) || null,
    employees: parseFloat(data.employees) || null,
    energyConsumptionCoalValue:
      parseFloat(data.energyConsumptionCoalValue) || null,
    energyConsumptionElectricityValue:
      parseFloat(data.energyConsumptionElectricityValue) || null,
    energyConsumptionFuelOilValue:
      parseFloat(data.energyConsumptionFuelOilValue) || null,
    energyConsumptionGasolineValue:
      parseFloat(data.energyConsumptionGasolineValue) || null,
    energyConsumptionHeatValue:
      parseFloat(data.energyConsumptionHeatValue) || null,
    energyConsumptionLiquidNitrogenValue:
      parseFloat(data.energyConsumptionLiquidNitrogenValue) || null,
    energyConsumptionNaturalGasValue:
      parseFloat(data.energyConsumptionNaturalGasValue) || null,
    energyConsumptionSteamValue:
      parseFloat(data.energyConsumptionSteamValue) || null,
    energyProductionValue: parseFloat(data.energyProductionValue) || null,
    productionFPValue: parseFloat(data.productionFPValue) || null,
    productionIntFPValue: parseFloat(data.productionIntFPValue) || null,
    scope1Value: parseFloat(data.scope1Value) || null,
    scope2Value: parseFloat(data.scope2Value) || null,
    refrigerantGases: data.refrigerantGases.map((el) => ({
      value: parseFloat(el.value) || null,
      type: el.type || null,
    })),
    volatileOrganicCompounds: data.volatileOrganicCompounds.map((el) => ({
      quantity: parseFloat(el.quantity) || null,
      name: el.name || null,
    })),
  };
};

export const setPlant = (plant: Plant, setValue: UseFormSetValue<Plant>) => {
  setValue("companyName", plant.companyName);
  setValue("reportingYear", plant.reportingYear || null);
  setValue("countryId", plant.countryId);
  setValue("city", plant.city);
  setValue("capexUnit", plant.capexUnit);
  setValue("capexValue", plant.capexValue || "");
  setValue("coolingWaterUnit", plant.coolingWaterUnit);
  setValue("coolingWaterValue", plant.coolingWaterValue || "");
  setValue("comments", plant.comments);
  setValue("employees", plant.employees || "");
  setValue("energyConsumptionCoalUnit", plant.energyConsumptionCoalUnit);
  setValue(
    "energyConsumptionCoalValue",
    plant.energyConsumptionCoalValue || ""
  );
  setValue(
    "energyConsumptionElectricityUnit",
    plant.energyConsumptionElectricityUnit
  );
  setValue(
    "energyConsumptionElectricityValue",
    plant.energyConsumptionElectricityValue || ""
  );
  setValue("energyConsumptionFuelOilUnit", plant.energyConsumptionFuelOilUnit);
  setValue(
    "energyConsumptionFuelOilValue",
    plant.energyConsumptionFuelOilValue || ""
  );
  setValue(
    "energyConsumptionGasolineUnit",
    plant.energyConsumptionGasolineUnit
  );
  setValue(
    "energyConsumptionGasolineValue",
    plant.energyConsumptionGasolineValue || ""
  );
  setValue("energyConsumptionHeatUnit", plant.energyConsumptionHeatUnit);
  setValue(
    "energyConsumptionHeatValue",
    plant.energyConsumptionHeatValue || ""
  );
  setValue(
    "energyConsumptionLiquidNitrogenUnit",
    plant.energyConsumptionLiquidNitrogenUnit
  );
  setValue(
    "energyConsumptionLiquidNitrogenValue",
    plant.energyConsumptionLiquidNitrogenValue || ""
  );
  setValue(
    "energyConsumptionNaturalGasUnit",
    plant.energyConsumptionNaturalGasUnit
  );
  setValue(
    "energyConsumptionNaturalGasValue",
    plant.energyConsumptionNaturalGasValue || ""
  );
  setValue("energyConsumptionSteamUnit", plant.energyConsumptionSteamUnit);
  setValue(
    "energyConsumptionSteamValue",
    plant.energyConsumptionSteamValue || ""
  );
  setValue("energyProductionUnit", plant.energyProductionUnit);
  setValue("energyProductionValue", plant.energyProductionValue || "");
  setValue("productionFPValue", plant.productionFPValue || "");
  setValue("productionIntFPValue", plant.productionIntFPValue || "");
  setValue("scope1Unit", plant.scope1Unit);
  setValue("scope1Value", plant.scope1Value || "");
  setValue("scope2Unit", plant.scope2Unit);
  setValue("scope2Value", plant.scope2Value || "");
  setValue("scope2Value", plant.scope2Value || "");
  console.log(plant);
  if (plant.refrigerantGases.length > 0) {
    setValue("refrigerantGases", plant.refrigerantGases);
  } else setValue("refrigerantGases", [{ value: "", type: null }]);
  if (plant.volatileOrganicCompounds.length > 0) {
    setValue("volatileOrganicCompounds", plant.volatileOrganicCompounds);
  } else setValue("volatileOrganicCompounds", [{ name: "", quantity: "" }]);
};
