export const TypeOptions = [
  { label: "Chemical", value: "CHEMICAL" },
  { label: "Biologic", value: "BIOLOGIC" },
  { label: "Natural", value: "NATURAL" },
];

export const TreatmentTypeOptions = [
  { label: "Aqueous", value: "AQUEOUS" },
  { label: "Organic", value: "ORGANIC" },
  { label: "Gas", value: "GAS" },
];

export const AqueousTreatmentTypeOptions = [
  { label: "Physico-chemical station", value: "PhysicoChemicalStation" },
  { label: "Bio-station", value: "BioStation" },
  { label: "Incineration", value: "Incineration" },
  { label: "Sewer", value: "Sewer" },
  { label: "Do not know", value: "DoNotKnow" },
];

export const OrganicTreatmentTypeOptions = [
  { label: "Incineration", value: "Incineration" },
  { label: "Do not know", value: "DoNotKnow" },
];

export const GasTreatmentTypeOptions = [
  { label: "Filtration", value: "Filtration" },
  { label: "No treatment", value: "NoTreatment" },
  { label: "Do not know", value: "DoNotKnow" },
];

export const RawMaterialProductionPlaceOptions = [
  { label: "Same country", value: "SameCountry" },
  { label: "Europe", value: "Europe" },
  { label: "Asia", value: "Asia" },
  { label: "Middle-East", value: "MiddleEast" },
  { label: "America", value: "America" },
  { label: "Do not know", value: "DoNotKnow" },
];

export const DistanceOptions = [
  { label: "No distillation", value: "No distillation" },
  { label: "Same location", value: "SameLocation" },
  { label: "<200 km", value: "LessThan200km" },
  { label: "<1000 km", value: "LessThan1000km" },
  { label: ">1000 km", value: "MoreThan1000km" },
];

export const ProcessTypeOptions = [
  { label: "Chemical reaction", value: "ChemicalReaction" },
  { label: "Blending", value: "Blending" },
  { label: "Purification", value: "Purification" },
  { label: "Other", value: "Other" },
];

export const PurificationTypeOptions = [
  { label: "Distillation", value: "Distillation" },
  { label: "Filtration/Drying", value: "FiltrationDrying" },
  { label: "Other", value: "Other" },
  { label: "None", value: "None" },
];

export const EnergyUnitOptions = [
  { label: "kWh", value: "KWh" },
  { label: "MWh", value: "MWh" },
  { label: "GWh", value: "GWh" },
  { label: "MJ", value: "MJ" },
  { label: "GJ", value: "GJ" },
  { label: "BTU", value: "BTU" },
  { label: "MMBTU", value: "MMBTU" },
  { label: "Therm", value: "Therm" },
];

export const SteamUnitOptions = [
  { label: "Tons", value: "Tons" },
  { label: "kWh", value: "KWh" },
  { label: "MWh", value: "MWh" },
  { label: "GWh", value: "GWh" },
  { label: "MJ", value: "MJ" },
  { label: "GJ", value: "GJ" },
  { label: "BTU", value: "BTU" },
  { label: "MMBTU", value: "MMBTU" },
  { label: "Therm", value: "Therm" },
];

export const QuantityUnitOptions = [
  { label: "Litres", value: "Litres" },
  { label: "Tons", value: "Tons" },
  { label: "Gallon (Imperial)", value: "Gallon_Imperial" },
  { label: "Gallon (US)", value: "Gallon_US" },
];

export const CompressedAirUnitOptions = [
  { label: "Nm3", value: "Nm3" },
  { label: "scf", value: "Scf" },
];

export const CoolingWaterUnitOptions = [
  { label: "m3", value: "m3" },
  { label: "Tons", value: "Tons" },
];

export const NitrogenUnitOptions = [
  { label: "Nm3 gas", value: "Nm3Gas" },
  { label: "scf gas", value: "ScfGas" },
  { label: "Kg", value: "Kg" },
  { label: "Tons", value: "Tons" },
  { label: "Pounds", value: "Pounds" },
  { label: "Litres", value: "Litres" },
  { label: "Gallon (Imperial)", value: "Gallon_Imperial" },
  { label: "Gallon (US)", value: "Gallon_US" },
];

export const ScopeUnitOptions = [
  { label: "tonsCO2eq", value: "TonsCO2eq" },
  { label: "kgCO2eq", value: "KgCO2eq" },
];

export const CAPEXOptions = [
  { label: "M €", value: "Million €" },
  { label: "M $", value: "Million $" },
];

export const RefrigerantGaseEnumOptions = [
  { label: "HFC-125 (R-125)", value: "HFC_125_R_125" },
  { label: "HFC-134 (R-134)", value: "HFC_134_R_134" },
  { label: "HFC-134a (R-134a)", value: "HFC_134a_R_134a" },
  { label: "HFC-143 (R-143)", value: "HFC_143_R_143" },
  { label: "HFC-143a (R-143a)", value: "HFC_143a_R_143a" },
  { label: "HFC-152 (R-152)", value: "HFC_152_R_152" },
  { label: "HFC-152a (R-152a)", value: "HFC_152a_R_152a" },
  { label: "HFC-161 (R-161)", value: "HFC_161_R_161" },
  { label: "HFC-227ea (R-227ea)", value: "HFC_227ea_R_227ea" },
  { label: "HFC-23 (R-23)", value: "HFC_23_R_23" },
  { label: "HFC-236cb (R-236cb)", value: "HFC_236cb_R_236cb" },
  { label: "HFC-236ea (R-236ea)", value: "HFC_236ea_R_236ea" },
  { label: "HFC-236fa (R-236fa)", value: "HFC_236fa_R_236fa" },
  { label: "HFC-245ca (R-245ca)", value: "HFC_245ca_R_245ca" },
  { label: "HFC-245fa (R-245fa)", value: "HFC_245fa_R_245fa" },
  { label: "HFC-32 (R-32)", value: "HFC_32_R_32" },
  { label: "HFC-365mfc", value: "HFC_365mfc" },
  { label: "HFC-41 (R-41)", value: "HFC_41_R_41" },
  { label: "HFC-43-10mee (R-4310)", value: "HFC_43_10mee_R_4310" },
  { label: "PFC-116 (Perfluoroethane)", value: "PFC_116_Perfluoroethane" },
  { label: "PFC-14 (Perfluoromethane)", value: "PFC_14_Perfluoromethane" },
  { label: "PFC-218 (Perfluoropropane)", value: "PFC_218_Perfluoropropane" },
  {
    label: "PFC-3-1-10 (Perfluorobutane)",
    value: "PFC_3_1_10_Perfluorobutane",
  },
  {
    label: "PFC-318 (Perfluorocyclobutane)",
    value: "PFC_318_Perfluorocyclobutane",
  },
  {
    label: "PFC-4-1-12 (Perfluoropentane)",
    value: "PFC_4_1_12_Perfluoropentane",
  },
  {
    label: "PFC-5-1-14 (Perfluorohexane)",
    value: "PFC_5_1_14_Perfluorohexane",
  },
  {
    label: "PFC-9-1-18 (Perfluorodecalin)",
    value: "PFC_9_1_18_Perfluorodecalin",
  },
  { label: "R-401A", value: "R_401A" },
  { label: "R-401B", value: "R_401B" },
  { label: "R-401C", value: "R_401C" },
  { label: "R-402A", value: "R_402A" },
  { label: "R-402B", value: "R_402B" },
  { label: "R-403A", value: "R_403A" },
  { label: "R-403B", value: "R_403B" },
  { label: "R-404A", value: "R_404A" },
  { label: "R-407A", value: "R_407A" },
  { label: "R-407B", value: "R_407B" },
  { label: "R-407C", value: "R_407C" },
  { label: "R-407D", value: "R_407D" },
  { label: "R-407E", value: "R_407E" },
  { label: "R-407F", value: "R_407F" },
  { label: "R-408A", value: "R_408A" },
  { label: "R-410A", value: "R_410A" },
  { label: "R-410B", value: "R_410B" },
  { label: "R-411A", value: "R_411A" },
  { label: "R-411B", value: "R_411B" },
  { label: "R-412A", value: "R_412A" },
  { label: "R-415A", value: "R_415A" },
  { label: "R-415B", value: "R_415B" },
  { label: "R-416A", value: "R_416A" },
  { label: "R-417A", value: "R_417A" },
  { label: "R-417B", value: "R_417B" },
  { label: "R-417C", value: "R_417C" },
  { label: "R-418A", value: "R_418A" },
  { label: "R-419A", value: "R_419A" },
  { label: "R-419B", value: "R_419B" },
  { label: "R-420A", value: "R_420A" },
  { label: "R-421A", value: "R_421A" },
  { label: "R-421B", value: "R_421B" },
  { label: "R-422A", value: "R_422A" },
  { label: "R-422B", value: "R_422B" },
  { label: "R-422C", value: "R_422C" },
  { label: "R-422D", value: "R_422D" },
  { label: "R-422E", value: "R_422E" },
  { label: "R-423A", value: "R_423A" },
  { label: "R-424A", value: "R_424A" },
  { label: "R-425A", value: "R_425A" },
  { label: "R-426A", value: "R_426A" },
  { label: "R-427A", value: "R_427A" },
  { label: "R-428A", value: "R_428A" },
  { label: "R-429A", value: "R_429A" },
  { label: "R-430A", value: "R_430A" },
  { label: "R-431A", value: "R_431A" },
  { label: "R-434A", value: "R_434A" },
  { label: "R-435A", value: "R_435A" },
  { label: "R-437A", value: "R_437A" },
  { label: "R-438A", value: "R_438A" },
  { label: "R-439A", value: "R_439A" },
  { label: "R-444A", value: "R_444A" },
  { label: "R-445A", value: "R_445A" },
  { label: "R-500", value: "R_500" },
  { label: "R-503", value: "R_503" },
  { label: "R-504", value: "R_504" },
  { label: "R-507", value: "R_507" },
  { label: "R-507A", value: "R_507A" },
  { label: "R-509", value: "R_509" },
  { label: "R-509A", value: "R_509A" },
  { label: "R-512A", value: "R_512A" },
  { label: "Unknown", value: "Unknown" },
];

export const createYearReportOption = () => {
  const currentYear = new Date().getFullYear();
  let yearsArray = [];
  for (let i = 0; i < 5; i++) {
    const year = currentYear - i;
    yearsArray.push({
      label: year.toString(),
      value: year.toString(),
    });
  }
  return yearsArray;
};

export const yearReportOption = [
  { label: "2021", value: "2021" },
  { label: "2020", value: "2020" },
  { label: "2019", value: "2019" },
  { label: "2018", value: "2018" },
];
