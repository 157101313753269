/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import Input from 'component/Input';
import Button from 'component/Button';
import { useFormContext } from 'react-hook-form';
import { emailRegex } from 'utils/regex';

type RegisterStep2FormData = {
    companylegalName: string;
    billingAddress: string;
    accountingPhone: string;
    accountingEmail: string;
}

function Step2({ handleStep }: {
    handleStep: (type: "decrement" | "increment") => void;
}) {
    const theme = useTheme();
    const { register, trigger, formState: { errors, isValid } } = useFormContext<RegisterStep2FormData>();

    const handleNext = async () => {
        const isStepValid = await trigger();
        if (isStepValid) handleStep('increment');
    };

    return (
        <>
        <h1 css={{
            fontSize: '24px',
            lineHeight: '28px',
            fontWeight: 600,
            marginTop: '40px',
            marginBottom: '0px',
        }}
        >Create your account</h1>

        <h4 css={{
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: 'normal',
            color: theme.darkGrey,
            marginTop: '16px',
            marginBottom: '40px',
        }}>Step 2 - Your invoicing information</h4>

        <Input
            label="Legal entity name"
            placeholder="Name"
            register={register}
            registerOption={{ required: `Company legal name can't be empty` }}
            name="companylegalName"
            error={errors.companylegalName && errors.companylegalName.message}
            css={{
                marginBottom: '16px'
            }}
        />

        <Input
            label="Billing address"
            placeholder="Address"
            register={register}
            registerOption={{ required: `Billing address can't be empty` }}
            name="billingAddress"
            error={errors.billingAddress && errors.billingAddress.message}
            css={{
                marginBottom: '16px'
            }}
        />

        <Input
            label="Accounting Dpt phone number"
            placeholder="Phone number"
            register={register}
            registerOption={{ required: `Accounting phone number can't be empty` }}
            name="accountingPhone"
            error={errors.accountingPhone && errors.accountingPhone.message}
            css={{
                marginBottom: '16px'
            }}
        />

        <Input
            label="Accounting department email"
            placeholder="Email"
            register={register}
            registerOption={{
                required: `Accounting email can't be empty`,
                pattern: {
                    value: emailRegex,
                    message: 'Incorrect email',
                },
            }}
            name="accountingEmail"
            error={errors.accountingEmail && errors.accountingEmail.message}
            css={{
                marginBottom: '16px'
            }}
        />


        <Button
            disabled={!isValid}
            onClick={handleNext}
            css={{
                marginTop: '48px',
                marginBottom: '16px',
            }}
        >Next</Button>

        <Button
            onClick={() => handleStep('decrement')}
            css={{
            }}
        >Back to Step 1</Button>
        </>
    )
}

export default Step2;