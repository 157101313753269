/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useState } from "react";

export default function DeleteDialog({
  close,
  action,
  isOpen,
  title = "Are you sure you want to delete this project ?",
  subtitle = "Please note that your project and all data will be lost once deleted.",
}: {
  close: () => void;
  action: (() => void) | null;
  isOpen: boolean;
  title?: string;
  subtitle?: string;
}) {
  const theme = useTheme();
  const [text, setText] = useState("");

  const handleAction = () => {
    if (action) {
      action();
      close();
    }
  };

  return (
    <Dialog open={isOpen} onClose={close} scroll="body">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{subtitle}</DialogContentText>
        <input
          css={{
            width: "100%",
            border: "2px solid transparent",
            borderRadius: "2px",
            backgroundColor: theme.lightGrey,
            padding: "16px",
            paddingRight: "16px",
            fontSize: "16px",
            height: "52px",
            fontWeight: 400,
            lineHeight: "20px",
            color: theme.black,
            outline: "none",
          }}
          onChange={(ev) => setText(ev.target.value)}
          placeholder={`Type "Delete" to continue`}
        />
      </DialogContent>
      <DialogActions>
        <button
          type="button"
          css={{
            cursor: "pointer",
            border: "none",
            background: "transparent",
            fontWeight: 500,
            fontSize: "16px",
          }}
          onClick={close}
        >
          Cancel
        </button>
        <button
          type="button"
          disabled={text === "Delete" ? false : true}
          css={{
            cursor: "pointer",
            border: "none",
            background: "transparent",
            fontWeight: 500,
            fontSize: "16px",
          }}
          onClick={handleAction}
        >
          Delete
        </button>
      </DialogActions>
    </Dialog>
  );
}
