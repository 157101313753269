/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import Input from "component/Input";
import Button from "component/Button";
import { useHistory } from "react-router-dom";
import Logo from "assets/Logo.png";
import BackgroundImage from "assets/background.jpg";
import { useForm } from "react-hook-form";
import { useState } from "react";
import {
  lowerCaseRegex,
  numberRegex,
  specialCharRegex,
  upperCaseRegex,
} from "utils/regex";
import { useCreatePasswordSubUser } from "utils/hooks/auth.hook";

export type CreatePasswordFormData = {
  email: string;
  password: string;
  challengeSession: string;
};

function CreatePassword({
  email,
  challengeSession,
}: {
  email: string;
  challengeSession: string;
}) {
  const theme = useTheme();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const { mutate: createPassword, isLoading } =
    useCreatePasswordSubUser(setErrorMessage);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreatePasswordFormData>({
    mode: "onChange",
    defaultValues: {
      email,
    },
  });

  const onSubmit = (data: CreatePasswordFormData) => {
    console.log({ ...data, challengeSession });
    createPassword({ ...data, challengeSession });
  };

  return (
    <div
      css={{
        width: "100%",
        height: "100vh",
        backgroundColor: theme.white,
        color: theme.black,
        display: "flex",
      }}
    >
      <form
        css={{
          width: "504px",
          padding: "80px",
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <img
          src={Logo}
          alt="Logo"
          onClick={() => {
            history.push("/CreatePassword");
          }}
          css={{
            height: "66px",
            width: "200px",
            cursor: "pointer",
          }}
        />

        <h1
          css={{
            fontSize: "24px",
            lineHeight: "28px",
            fontWeight: 600,
            marginTop: "40px",
            marginBottom: "0px",
          }}
        >
          Welcome to Ecovamed
        </h1>

        <h4
          css={{
            fontSize: "14px",
            lineHeight: "18px",
            fontWeight: "normal",
            color: theme.darkGrey,
            marginTop: "24px",
            marginBottom: "40px",
          }}
        >
          You need to create a password
        </h4>

        <Input
          label="Email"
          placeholder="Email"
          name="email"
          register={register}
          disabled={true}
          css={{
            marginBottom: "24px",
          }}
        />

        <Input
          label="Create a password"
          placeholder="Password"
          register={register}
          registerOption={{
            required: `Password can't be empty`,
            minLength: {
              value: 9,
              message: `Password must have at least 9 characters`,
            },
            maxLength: {
              value: 50,
              message: `Password can't exceed 50 characters`,
            },
            validate: {
              upperCase: (value: string) =>
                upperCaseRegex.test(value) || `Password must have a upper case`,
              lowerCase: (value: string) =>
                lowerCaseRegex.test(value) || `Password must have a lower case`,
              specialChar: (value: string) =>
                specialCharRegex.test(value) ||
                `Password must have a special character`,
              number: (value: string) =>
                numberRegex.test(value) || `Password must have a number`,
            },
          }}
          name="password"
          error={errors.password && errors.password.message}
          type="password"
          css={{
            marginBottom: "16px",
          }}
        />

        {errorMessage && (
          <p
            css={{
              color: theme.error,
              fontSize: "14px",
              marginBottom: "-30px",
            }}
          >
            {errorMessage}
          </p>
        )}

        <Button
          disabled={isLoading}
          type="submit"
          css={{
            marginTop: "48px",
            marginBottom: "12px",
          }}
        >
          Create password
        </Button>
      </form>
      <div
        css={{
          flexGrow: 1,
          backgroundSize: "cover",
          backgroundRepeat: "none",
          backgroundImage: `url(${BackgroundImage})`,
        }}
      />
    </div>
  );
}

export default CreatePassword;
