/** @jsxImportSource @emotion/react */
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useCheckAdmin } from 'utils/hooks/admin.hook';
import { checkAuth } from "utils/token";
import Login from 'pages/Login';
import Register from 'pages/register';
import ForgotPassword from 'pages/ForgotPassword';
import Home from 'pages/Home';
import Logout from "pages/Logout";
import SetPassword from "pages/SetPassword";
import ConfirmUser from "pages/ConfirmUser";

import MyLabels from "pages/MyLabels";
import MyAccount from "pages/myAccount";
import Administrator from "pages/myAccount/administrator";
import Invoicing from "pages/myAccount/invoicing";
import MyFootprints from "pages/MyFootprints";
import MyProjects from "pages/MyProjects";
import MyPlants from "pages/MyPlants";

import NewCarbonFootprint from "pages/carbonFootprint/firstPart/NewCarbon";
import ExistingCarbonFootprint from "pages/carbonFootprint/firstPart/ExistingCarbon";
import CarbonFootprintSecondPart from "pages/carbonFootprint/secondPart";
import NewDrugSubstance from "pages/drugSubstance/NewSubstance";
import ExistingDrugSubstance from "pages/drugSubstance/ExistingSubstance";
import NewDrugOrigin from "pages/drugOrigin/NewOrigin";
import ExistingDrugOrigin from "pages/drugOrigin/ExistingOrigin";
import NewPlant from "pages/plants/NewPlant";
import ExistingPlant from "pages/plants/ExistingPlant";

import AdminPanel from "admin/Panel";
import AdminDrugOrigin from "admin/drugOrigin";
import AdminCarbonSecondPart from "admin/carbonFootprint/secondPart";
import AdminCarbonFirstPart from "admin/carbonFootprint/firstPart";
import AdminDrugSubstance from "admin/drugSubstance";
import SetupNewUser from "pages/myAccount/administrator/SetupNewUser";
import UserList from "pages/myAccount/administrator/UserList";

import MedicineFootprint from "pages/medicineFootprint/View";
import SelectedProduct from "pages/medicineFootprint/SelectedProduct";


function ProtectedRoute({ children, ...restOfProps }: any) {
  const isAuthenticated = checkAuth();

  return (
    <Route
      {...restOfProps}
      render={() => 
        isAuthenticated ? children : <Redirect to="/login" />
      }
    />
  );
}

function AdminRoute({ children, ...restOfProps }: any) {
  const { isLoading, isError, isSuccess } = useCheckAdmin();
  return (
    <Route
      {...restOfProps}
      render={() => {
        if (isLoading)
          return (
            <div css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100vh',
            }}>
              <CircularProgress />
            </div>
          )
        if (isError)
          return <Redirect to="/logout" />
        if (isSuccess)
          return children;
        }
      }
    />
  );
}

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/forgot_password">
          <ForgotPassword />
        </Route>
        <Route path="/set_password">
          <SetPassword />
        </Route>
        <Route path="/confirm_user/:email/:code">
          <ConfirmUser />
        </Route>
        <Route path="/logout">
          <Logout />
        </Route>

        <ProtectedRoute path="/plant/new">
          <NewPlant />
        </ProtectedRoute>
        <ProtectedRoute path="/plant/:id">
          <ExistingPlant />
        </ProtectedRoute>

        <ProtectedRoute path="/drug_origin/new">
          <NewDrugOrigin />
        </ProtectedRoute>
        <ProtectedRoute path="/drug_origin/:id">
          <ExistingDrugOrigin />
        </ProtectedRoute>

        <ProtectedRoute path="/drug_substance/new">
          <NewDrugSubstance />
        </ProtectedRoute>
        <ProtectedRoute path="/drug_substance/:id">
          <ExistingDrugSubstance />
        </ProtectedRoute>

        <ProtectedRoute path="/carbon_footprint/first_part/new">
          <NewCarbonFootprint />
        </ProtectedRoute>
        <ProtectedRoute path="/carbon_footprint/first_part/:id?">
          <ExistingCarbonFootprint />
        </ProtectedRoute>
        <ProtectedRoute path="/carbon_footprint/second_part/:id">
          <CarbonFootprintSecondPart />
        </ProtectedRoute>

        <ProtectedRoute path="/home">
          <Home />
        </ProtectedRoute>
        <ProtectedRoute path="/my_plants">
          <MyPlants />
        </ProtectedRoute>
        <ProtectedRoute path="/my_projects">
          <MyProjects />
        </ProtectedRoute>
        <ProtectedRoute path="/my_labels">
          <MyLabels />
        </ProtectedRoute>
        <ProtectedRoute path="/my_footprints">
          <MyFootprints />
        </ProtectedRoute>

        <ProtectedRoute path="/my_account/admin">
          <Administrator />
        </ProtectedRoute>
        <ProtectedRoute path={["/my_account/subscription", "/my_account/pricing", "/my_account/invoices"]}>
          <Invoicing />
        </ProtectedRoute>
        <ProtectedRoute path="/my_account/user_list">
          <UserList />
        </ProtectedRoute>
        <ProtectedRoute path="/my_account/setup_new_user">
          <SetupNewUser />
        </ProtectedRoute>
        <ProtectedRoute path="/my_account">
          <MyAccount />
        </ProtectedRoute>

        {/* <ProtectedRoute path="/medicine_footprint"> */}
        <ProtectedRoute path="/medicine_footprint/product">
          <SelectedProduct />
        </ProtectedRoute>
        <ProtectedRoute path="/medicine_footprint">
          <MedicineFootprint />
        </ProtectedRoute>
        {/* </ProtectedRoute> */}

        <AdminRoute path="/admin/drug_origin/:id">
          <AdminDrugOrigin />
        </AdminRoute>
        <AdminRoute path="/admin/drug_substance/:id">
          <AdminDrugSubstance />
        </AdminRoute>
        <AdminRoute path="/admin/carbon_footprint/first_part/:id">
          <AdminCarbonFirstPart />
        </AdminRoute>
        <AdminRoute path="/admin/carbon_footprint/second_part/:id">
          <AdminCarbonSecondPart />
        </AdminRoute>
        <AdminRoute path="/admin/panel">
          <AdminPanel />
        </AdminRoute>
        <AdminRoute path="/admin">
          <Redirect to="/admin/panel" />
        </AdminRoute>
        
        <Route path="/">
          <Redirect to="/home" />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
