/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { Redirect } from "react-router";
import { useEffect } from "react";
import { useGetClientInfo, useUpdateGeneralInformation } from "utils/hooks/client.hook";
import Input from "component/Input";
import { useForm } from "react-hook-form";
import Button from "component/Button";
import { CircularProgress } from "@material-ui/core";
import _ from "lodash";

export type GeneralInformationFormData = {
    companyName: string;
    contactName: string;
    phone: string;
    email: string;
}

function GeneralInformation() {
    const theme = useTheme();
    const { client, isLoading, isError } = useGetClientInfo();
    const { mutate: update } = useUpdateGeneralInformation();
    const { register, setValue, reset, handleSubmit, formState: { dirtyFields, errors } } = useForm<GeneralInformationFormData>({
        mode: "onChange"
    });

    useEffect(() => {
        if (client) {
            setValue('companyName', client.companyName);
            setValue('contactName', client.contactName);
            setValue('phone', client.phone);
            setValue('email', client.email);
        }
    }, [client, setValue]);

    const onSubmit = (data: GeneralInformationFormData) => {
        update(data);
        reset(data);
    }

    const formDisabled = (!_.isEmpty(dirtyFields) && _.isEmpty(errors));

    if (isLoading)
        return (
            <div css={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <CircularProgress />
            </div>
        )

    if (isError)
        return <Redirect to="/my_account" />

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            css={{
                padding: '24px 40px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <h3 css={{
                fontWeight: 600,
                fontSize: '24px',
                lineHeight: '29px',
                color: theme.black,
                marginTop: '0px',
                marginBottom: '48px',
            }}>Edit general information</h3>

            <Input
                label="Company"
                placeholder=""
                name="companyName"
                register={register}
                registerOption={{ required: `Company name can't be empty` }}
                error={errors.companyName && errors.companyName.message}
                css={{
                    marginBottom: '24px',
                    maxWidth: '344px',
                }}
            />

            <Input
                label="Contact name"
                placeholder=""
                name="contactName"
                register={register}
                registerOption={{ required: `Contact name can't be empty` }}
                error={errors.contactName && errors.contactName.message}
                css={{
                    marginBottom: '24px',
                    maxWidth: '344px',
                }}
            />

            <Input
                label="Phone number"
                placeholder=""
                name="phone"
                register={register}
                registerOption={{ required: `Phone can't be empty` }}
                error={errors.phone && errors.phone.message}
                css={{
                    marginBottom: '24px',
                    maxWidth: '344px',
                }}
            />

            <Input
                label="Email"
                placeholder=""
                name="email"
                register={register}
                registerOption={{ required: `Email can't be empty` }}
                error={errors.email && errors.email.message}
                css={{
                    marginBottom: '24px',
                    maxWidth: '344px',
                }}
            />

            <Button
                type="submit"
                disabled={!formDisabled}
                variant="dark"
                css={{
                    maxWidth: '344px',
                }}
            >Save</Button>
        </form>
    );
}

export default GeneralInformation;