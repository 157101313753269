/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import Input from 'component/Input';
import Button from 'component/Button';
import { Link } from 'react-router-dom';
import Logo from 'assets/Logo.png';
import BackgroundImage from 'assets/background.jpg';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useForgotPassword } from 'utils/hooks/auth.hook';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useHistory } from "react-router-dom";

type FormForgotPasswordData = {
    email: string,
}

function ForgotPassword() {
    const theme = useTheme();
    const history = useHistory();
    const [emailPlaceholder, setEmailPlaceholder] = useState('');
    const { mutate, isLoading, isIdle, isError, isSuccess } = useForgotPassword(setEmailPlaceholder);
    const { register, handleSubmit, formState: { isValid, errors } } = useForm<FormForgotPasswordData>({
        mode: "onChange"
    });

    const onSubmit = (data: FormForgotPasswordData) => {
        mutate(data.email);
    }

    return (
        <div
            css={{
                width: '100%',
                minHeight: '100vh',
                backgroundColor: theme.white,
                color: theme.black,
                display: 'flex',
            }}
        >
            <form
                css={{
                    width: '504px',
                    padding: '80px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                onSubmit={handleSubmit(onSubmit)}
            >
                <img 
                    src={Logo} 
                    alt="Logo" 
                    onClick={() => {
                        history.push('/login')
                    }}
                    css={{
                        height: '66px',
                        width: '200px',
                        cursor: 'pointer',
                    }} 
                />
                
                <h1 css={{
                    fontSize: '24px',
                    lineHeight: '28px',
                    fontWeight: 600,
                    marginTop: '40px',
                    marginBottom: '0px',
                }}
                >Forgotten password</h1>

                <h4 css={{
                    fontSize: '14px',
                    lineHeight: '18px',
                    fontWeight: 'normal',
                    color: theme.darkGrey,
                    marginTop: '24px',
                    marginBottom: '40px',
                }}>Provide the email that you entered during your registration</h4>

                <Input
                    label="Email"
                    placeholder="Email"
                    name="email"
                    register={register}
                    autoComplete="email"
                    registerOption={{
                        required: `Email can't be empty`,
                    }}
                    error={errors.email && errors.email.message}
                    css={{
                        marginBottom: '24px'
                    }}
                />

                {isSuccess &&
                <p
                    css={{
                        color: theme.darkGrey,
                        fontSize: '14px',
                        lineHeight: '18px',
                        marginTop: '48px',
                        marginBottom: '40px',
                        'a': {
                            textDecoration: 'underline',
                            color: theme.black,
                            fontWeight: 600,
                        }
                    }}
                >We just send you a code to renew your password at: {emailPlaceholder} <Link to="set_password">Change my password</Link></p>
                }

                {(isIdle || isError) &&
                    <Button
                        disabled={!isValid}
                        type="submit"
                        css={{
                            marginTop: '48px',
                            marginBottom: '40px',
                        }}
                    >Send email</Button>
                }

                {isLoading &&
                    <CircularProgress css={{ 
                        margin: 'auto',
                        marginTop: '48px',
                        marginBottom: '40px',
                        'circle': {
                            stroke: theme.primary
                        }
                    }} />
                }
                <p 
                    css={{
                        textAlign: 'center',
                        fontWeight: 'normal',
                        fontSize: '14px',
                        lineHeight: '17px',
                        marginTop: '0px',
                        'a': {
                            textDecoration: 'underline',
                            color: theme.black,
                            fontWeight: 600,
                        }
                    }}
                >Already have your account ? <Link to="login">Log in</Link></p>

            </form>
            <div
                css={{
                    flexGrow: 1,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'none',
                    backgroundImage: `url(${BackgroundImage})`,
                }}
            />
        </div>
    );
}

export default ForgotPassword;