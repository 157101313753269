import { useQuery } from 'react-query';
import { client } from '../client';

export type Country = {
    id: number;
    name: string;
}

function useCountries() {
    const { data: countries, isLoading, isError, isSuccess } = useQuery(
        'countries',
        () => client('countries'),
    );
    return {
        countries,
        isLoading,
        isError,
        isSuccess,
    }
}


export {
    useCountries,
}