/** @jsxImportSource @emotion/react */
import _ from 'lodash';
import { css, cx } from '@emotion/css';
import { useTheme } from '@emotion/react';
import { InformationPointNonSVG } from '../InformationPoint';
import addIcon from 'assets/Add.png';
import { useBiologicTransformationType, useNaturalTransformationType } from 'utils/hooks/transformationType.hook';

const Node = ({
    nodeDatum,
    foreignObjectProps,
    openDialog,
    type
}: any) => {
    const theme = useTheme();
    const { naturalTypes } = useNaturalTransformationType();
    const { biologicTypes } = useBiologicTransformationType();
    const isRootNode = (nodeDatum.__rd3t.depth === 0);
    const rootNodeIsNotFilled = (isRootNode && (!nodeDatum.manufacturers || nodeDatum.manufacturers === null || nodeDatum.manufacturers?.length === 0));
    
    const zeroChildButtonCss = css({
        top: '100px',
        left: (type === 'BIOLOGIC' || type === 'NATURAL') ? '130px' : '75px',
        '.link': {
            height: '65px',
            borderLeft: '2px dashed',
            borderColor: 'inherit',
            position: 'absolute',
            top: '-62px',
            left: (type === 'BIOLOGIC' || type === 'NATURAL') ? '94px' : '74px',
            zIndex: -1,
        }
    });
    
    const oneChildButtonCss = css({
        top: '75px',
        left: (type === 'BIOLOGIC' || type === 'NATURAL') ? '315px' : '240px',
        '.link': {
            width: '80px',
            borderBottom: '2px solid',
            borderColor: 'inherit',
            position: 'absolute',
            top: '19px',
            left: '-90px',
            zIndex: -1,
        }
    });
    
    const multiplesChildrenButton = css({
        top: '43px',
        left: (type === 'BIOLOGIC' || type === 'NATURAL') ? '315px' : '240px',
        '.link': {
            width: '80px',
            borderBottom: '2px solid',
            borderColor: 'inherit',
            position: 'absolute',
            top: '19px',
            left: '-90px',
            zIndex: -1,
        }
    });

    const commonButtonCss = css({
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        border: 'none',
        background: 'transparent',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '15px',
        position: 'absolute',
        width: (type === 'BIOLOGIC' || type === 'NATURAL') ? '220px' : '150px',
        padding: '0px',
        borderColor: theme.secondary,
        '&:hover': {
            opacity: 0.8,
        },
        'img': {
            marginRight: '10px',
        },
    });

    let nodeName = nodeDatum.name;
    if (nodeDatum.transformationType) {
        if (nodeDatum.transformationType === -1) {
            nodeName = nodeDatum.otherStep;
        } else {
            const name = type === 'NATURAL' ? _.find(naturalTypes, { 'id': nodeDatum.transformationType }) : _.find(biologicTypes, { 'id': nodeDatum.transformationType });
            if (name) nodeName = name.name;
        }
    }

    return (
        <g>
            <foreignObject {...foreignObjectProps}
                css={{
                    position: 'relative',
                    overflow: 'visible',
                }}
            >
                <div>
                    <div 
                        onClick={() => {
                            isRootNode ? 
                            openDialog(nodeDatum, 'updateRoot') :
                            openDialog(nodeDatum, 'update');
                        }}
                        css={{
                            textAlign: 'center',
                            border: "none",
                            minHeight: '41px',
                            padding: '12px 20px',
                            backgroundColor: rootNodeIsNotFilled ? theme.notCompleted : theme.secondary,
                            width: 'fit-content',
                            minWidth: '200px',
                            maxWidth: '95%',
                            margin: '0px auto',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            '&:hover': {
                                backgroundColor: rootNodeIsNotFilled ? "#e6c347" : "#56dc77",
                            },
                            'p': {
                                margin: '0px',
                                fontWeight: 500,
                                fontSize: '14px',
                                lineHeight: '17px',
                            }
                        }}
                    >
                       {nodeName}
                    </div>
                    <button
                        type="button"
                        onClick={() => openDialog(nodeDatum, 'create')}
                        className={cx(
                            commonButtonCss,
                            { [zeroChildButtonCss]: nodeDatum.children.length === 0 },
                            { [oneChildButtonCss]: nodeDatum.children.length === 1 },
                            { [multiplesChildrenButton]: nodeDatum.children.length > 1 }
                        )}
                    >
                        <span className="link"></span>
                        <img src={addIcon} alt="+" />
                        <p>{type === 'CHEMICAL' ? "Add a precursor" : "Add a manufacturing step"}</p>
                        {type === 'CHEMICAL' &&
                            <InformationPointNonSVG
                                text="A precursor is an intermediate chemical having several atoms which will be included in the product above. Reagents and solvents are not considered as precursors."
                                css={{
                                    marginLeft: '8px'
                                }}
                            />
                        }
                    </button>
                </div>
            </foreignObject>
        </g>
    );
}

export default Node;