/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import Button from 'component/Button';
import { useAdminValidateCarbonSecondPart } from 'utils/hooks/admin.hook';
import { Redirect, useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Header from 'component/StepHeader';
import { useEffect } from 'react';

function FinalStep({
    handleStep,
    carbon,
    setValuesChanged,
}: {
    handleStep: (type: "decrement" | "increment") => void;
    carbon: any;
    setValuesChanged: React.Dispatch<React.SetStateAction<boolean>>,
}) {
    const theme = useTheme();
    const params = useParams<{ id: string }>();
    const { isLoading, isSuccess, isIdle, mutate: validateCarbon } = useAdminValidateCarbonSecondPart(params.id);
    
    const handleValidateCheck = () => {
        const data = { ...carbon, status: 'CHECK2' }
        validateCarbon(data);
    }

    const handleValidateFirstPartCompleted = () => {
        const data = { ...carbon, status: 'COMPLETED' }
        validateCarbon(data);
    }

    useEffect(() => {
        setValuesChanged(false);
    }, [setValuesChanged]);

    if (isSuccess) {
        return <Redirect to="/admin/panel" />;
    }

    if (isLoading) {
        return (
            <div css={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100vh',
            }}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <div
            css={{  
                backgroundColor: theme.white,
                padding: '50px 56px',
                color: theme.black,
                display: 'grid',
                gridTemplateColumns: 'auto 268px',
                columnGap: '30px',
            }}
        >
            <div>
                <Header
                    title="Carbon footprint"
                    stepName=""
                    stepTitle="Validation Step"
                    backName="Previous step"
                    backAction={() => handleStep('decrement')}
                />
                <div css={{
                    marginTop: '80px'
                }}>
                    <p>If no modifications (or no important modification for the client) you can save this a completed</p>
                    {isIdle &&
                    <Button variant="dark" onClick={handleValidateFirstPartCompleted}>Save as completed</Button>
                    }
                </div>
                <div css={{
                    marginTop: '40px'
                }}>
                    <p>If the client has to check the ecovamed's modification</p>
                    {isIdle &&
                    <Button variant="dark" onClick={handleValidateCheck}>Save as Check</Button>
                    }
                </div>
            </div>
        </div>
    );
}

export default FinalStep;