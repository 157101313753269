/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import CurrentStepVisualiser from '../CurrentStepVisualiser';
import Button from 'component/Button';
import Header from 'component/StepHeader'
import PurificationScreenShot from 'assets/carbonScreenShots/purification2.png';
import RawMaterialsScreenShot from 'assets/carbonScreenShots/rawMaterials2.png';
import TypeOfProcessScreenShot from 'assets/carbonScreenShots/typeOfProcess2.png';
import WastesScreenShot from 'assets/carbonScreenShots/wastes2.png';
import { ReactComponent as ArrowSideIcon } from 'assets/svg/ArrowSide.svg';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

function Step1({ handleStep, handleSaveBeforeLeave }: {
    handleStep: (type: "decrement" | "increment") => void;
    handleSaveBeforeLeave: (path: string) => void;
}) {
    const theme = useTheme();

    return (
        <div
            css={{
                backgroundColor: theme.white,
                padding: '50px 56px',
                color: theme.black,
                display: 'grid',
                gridTemplateColumns: 'auto 268px',
                columnGap: '30px',
            }}
        >
            <div>
                <Header
                    title="Carbon footprint"
                    stepName="Before step 4"
                    stepTitle="Explanation of the methodology"
                    backName="Back Home"
                    backAction={() => handleSaveBeforeLeave('/home')}
                />
                <p
                    css={{
                        marginTop: '32px',
                        marginBottom: '40px',
                        maxWidth: '636px',
                        color: theme.black,
                        fontSize: '14px',
                        lineHeight: '20px',
                        fontWeight: 400,
                        'span': {
                            fontWeight: 600,
                        },
                        'a': {
                            color: theme.black,
                            fontWeight: 600,
                            textDecoration: 'underline'
                        }
                    }}
                >
                    The next step is to fill the charts here below, with all input chemicals (raw materials + solvents + water used in the process, e.g. for hydrolysis or washings) and all output chemicals (finished product and co-products, wastes), and light information on process, purification, packaging and eventually recycling, if any.
                </p>
                <div
                    css={{
                        width: '100%',
                        height: '400px',
                        overflowX: 'auto',
                        position: 'relative',
                        'div': {
                            position: 'absolute',
                            top: 0,
                        },
                        '.first_arrow': {
                            position: 'absolute',
                            left: '205px',
                            top: '2px',
                        },
                        '.second_arrow': {
                            position: 'absolute',
                            left: '429px',
                            top: '2px',
                        },
                        '.reverse_arrow': {
                            position: 'absolute',
                            left: '315px',
                            top: '40px',
                            transform: 'rotate(90deg)',
                        },
                    }}
                >
                    <Zoom wrapStyle={{
                        left: 0,
                    }}>
                        <img css={{
                            width: '200px'
                        }} src={RawMaterialsScreenShot} alt="raw_materials" />
                    </Zoom>

                    <ArrowSideIcon className="first_arrow" />

                    <Zoom wrapStyle={{
                        left: 227,
                    }}>
                        <img css={{
                            width: '195px'
                        }} src={TypeOfProcessScreenShot} alt="type_of_process" />
                    </Zoom>

                    <ArrowSideIcon className="reverse_arrow" />

                    <Zoom wrapStyle={{
                        top: 63,
                        left: 227,
                    }}>
                        <img css={{
                            width: '195px'
                        }} src={WastesScreenShot} alt="wastes" />
                    </Zoom>

                    <ArrowSideIcon className="second_arrow" />

                    <Zoom wrapStyle={{
                        left: 450,
                    }}>
                        <img css={{
                            width: '195px'
                        }} src={PurificationScreenShot} alt="purification" />
                    </Zoom>
                </div>
            </div>
            <div>
                <CurrentStepVisualiser step={6} />
                <Button
                    variant="dark"
                    disabled={false}
                    onClick={() => handleStep('increment')}
                >Got it</Button>
            </div>
        </div>
    );
}

export default Step1;